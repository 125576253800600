import * as React from "react";

import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "modules";
import { useArrivalInfo, useUpdateArrivalInfo } from "modules/triage/api";

import { EmrCar } from "@medstonetech/slate-icons";
import { FormProvider } from "react-hook-form";
import { Icon } from "@chakra-ui/react";
import { Loading } from "shared";
import { useSharedChartForm } from "modules/charts-shared";

type ArrivalInfoSectionProps = {
  chartCode: string;
  hasParent?: boolean;
  showLastDoseOptions?: boolean;
  isReadOnly?: boolean;
};

const sectionCode = "SARI";

function N07ArrivalInfo(props: ArrivalInfoSectionProps) {
  const { hasParent, showLastDoseOptions, chartCode, isReadOnly } = props;
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    onSaveAndNext,
    updateIsLoading,
    useFormContext,
  } = useSharedChartForm({
    sectionLabel: "Arrival Info",
    useGetSharedChartForm: useArrivalInfo,
    useUpdateSharedChartForm: useUpdateArrivalInfo,
    sectionCode,
  });

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartSection onSubmit={onSubmit} disabled={isReadOnly}>
        <SharedChartSectionHeader
          icon={<Icon as={EmrCar} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Arrival Info {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionCode}
              subsectionCode="Q001"
              subsectionLabel="Historian"
              showNotesBtn={false}
            >
              Historian
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                {hasParent && (
                  <ChartRowElement
                    type="cross-option"
                    name="Q001.N007xSARIQ001A005"
                    label="parent"
                  />
                )}
                <ChartRowElement
                  type="cross-option"
                  name="Q001.N007xSARIQ001A001"
                  label="patient"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.N007xSARIQ001A002"
                  label="paramedics"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q001.N007xSARIQ001A003"
                  label="family"
                />
                <ChartRowElement type="input" name="Q001.N007xSARIQ001A004" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionCode}
              subsectionCode="Q002"
              subsectionLabel="Barriers"
              showNotesBtn={false}
            >
              Barriers
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="none"
                  name="Q002.N007xSARIQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="learning"
                  name="Q002.N007xSARIQ002A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="communication"
                  name="Q002.N007xSARIQ002A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="interpreter"
                  name="Q002.N007xSARIQ002A004"
                />
                <ChartRowElement type="input" name="Q002.N007xSARIQ002A005" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionCode}
              subsectionCode="Q003"
              subsectionLabel="Arrival Mode"
              showNotesBtn={false}
            >
              Arrival Mode
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="car"
                  name="Q003.N007xSARIQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="EMP"
                  name="Q003.N007xSARIQ003A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="Police"
                  name="Q003.N007xSARIQ003A003"
                />
                <ChartRowElement type="input" name="Q003.N007xSARIQ003A004" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <SharedChartSubsectionHeader
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={sectionCode}
              subsectionCode="Q006"
              subsectionLabel="Treatment PTA"
              showNotesBtn={false}
            >
              Treatment PTA
            </SharedChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="see EMS report"
                  name="Q006.N007xSARIQ006A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="IV"
                  name="Q006.N007xSARIQ006A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="O2"
                  name="Q006.N007xSARIQ006A003"
                />
                {!showLastDoseOptions && (
                  <ChartRowElement
                    type="cross-option"
                    label="c-collar"
                    name="Q006.N007xSARIQ006A004"
                  />
                )}
                {!showLastDoseOptions && (
                  <ChartRowElement
                    type="cross-option"
                    label="backboard"
                    name="Q006.N007xSARIQ006A005"
                  />
                )}
                {showLastDoseOptions && (
                  <ChartRowElement
                    type="cross-option"
                    label="tylenol"
                    name="Q006.N007xSARIQ006A006"
                  />
                )}
                {showLastDoseOptions && (
                  <ChartRowElement
                    type="cross-option"
                    label="ibuprofen"
                    name="Q006.N007xSARIQ006A007"
                  />
                )}
                <ChartRowElement type="input" name="Q006.N007xSARIQ006A008" />
              </ChartRow>
              {showLastDoseOptions && (
                <ChartRow isEvenlySpaced>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="last blood glucose"
                      name="Q006.N007xSARIQ006A009"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q006.N007xSARIQ006A010"
                    />
                  </ChartRowGroup>
                  <ChartRowGroup>
                    <ChartRowElement
                      type="cross-option"
                      label="mg/dL"
                      name="Q006.N007xSARIQ006A011"
                    />
                    <ChartRowElement
                      type="input"
                      name="Q006.N007xSARIQ006A012"
                    />
                  </ChartRowGroup>
                </ChartRow>
              )}
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="last blood glucose"
                  name="Q006.N007xSARIQ006A013"
                />
                <ChartRowElement
                  type="input"
                  name="Q006.N007xSARIQ006A014"
                  size="lg"
                  inputType="number"
                />
                <ChartRowElement
                  type="cross-option"
                  name="Q006.N007xSARIQ006A015"
                  label="mg/dL"
                />
                <ChartRowElement type="input" name="Q006.N007xSARIQ006A016" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  label="pneumovax"
                  name="Q006.N007xSARIQ006A009"
                />
                <ChartRowElement type="input" name="Q006.N007xSARIQ006A0017" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="option"
                  label="other"
                  name="Q006.N007xSARIQ006A018"
                />
                <ChartRowElement type="input" name="Q006.N007xSARIQ006A0019" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N07ArrivalInfo };
