import { axiosOrderInstance } from "config";
import { useAxiosQuery } from "hooks";
import { IvStart } from "modules/charts-shared";

function useIvStarts(encounterId: string) {
  return useAxiosQuery(["ivStarts", { encounterId }], () =>
    axiosOrderInstance.get<IvStart[]>(`v1/iv-starts/${encounterId}`)
  );
}

export { useIvStarts };
