import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type CreateUserPayload = FormData;

function useCreateUser() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createUser"],
    (input: CreateUserPayload) => axiosIdentityInstance.post("/v1/user", input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("searchEnabledUsers");
        queryClient.invalidateQueries("permissionsCounters");
      },
    }
  );
}

export { useCreateUser };
