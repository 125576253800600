import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Icon,
  Switch,
  Text,
  chakra,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { EmrNotes, EmrTakeVitals } from "@medstonetech/slate-icons";
import { useSyncScroll, useToast } from "hooks";
import {
  ActiveTimer,
  AmbulatoryNotesModal,
  SharedChartSectionHeader,
  WeightUnit,
  calculateBmi,
  getEditCellDisplay,
  useSaveAndNext,
  useVitalsTimer,
  weightConversion,
} from "modules";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loading, SingleActionDialog } from "shared";
import { ChartType } from "types";
import { extractApiErrorMessage, formatTimer } from "utils";
import { VitalPayload } from "../types";

import { SquareInfo } from "icons";
import { formMessages } from "messages";
import {
  useEncounterIntegrations,
  useUpdateEncounterIntegrationAYVABMI,
} from "modules/reception/api";
import { EncounterIntegrationButton } from "modules/reception/components/encounter-integration";
import { EncounterIntegration } from "modules/reception/types/encounter-integration";
import { usePatchVitalBMI, useUpdateAmbulatory } from "../api";
import { useAmbulatoryNotes } from "modules/charts-shared/api";
import { VitalsProvider, useVitalsContext } from "../contexts";
import { VitalsFixedSection } from "./VitalsFixedSection";
import { VitalsInfiniteGrid } from "./VitalsInfiniteGrid";
import {
  HeightVitalsInputModal,
  TimerVitalsInputModal,
  WeightVitalsInputModal,
} from "./modals";
import { useChartTrackingContext } from "contexts";

type TimerCardProps = {
  timer: string;
  isReadOnly?: boolean;
};

type CountdownCardProps = {
  timer: string;
  timerMs: number;
  latestRecordTakenAt?: Date | string;
  isReadOnly?: boolean;
  onPause: () => void;
  onReset: () => void;
  onResume: () => void;
  isPaused: boolean;
  isResumingOrPausing: boolean;
  isResetting: boolean;
};

type WeightCardProps = {
  onSave: (value: number, unit: WeightUnit) => void;
  isReadOnly?: boolean;
};

type HeightCardProps = {
  onSave: (value: string) => void;
  isReadOnly?: boolean;
};

type VitalsTopSectionProps = BoxProps & {
  encounterId: string;
  isReadOnly?: boolean;
  onUpdateRecord?: () => void;
};

function TimerCard(props: TimerCardProps) {
  const { timer, isReadOnly = false } = props;
  const [hours, minutes] = timer.split(":");
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="20px"
      bg="white"
      width="100%"
      borderRadius="10px"
      marginBottom="16px"
    >
      <Box fontWeight="600">Vitals Timer</Box>
      <Box fontSize="3rem" color="black">
        <chakra.span paddingRight="0.825rem">{hours}</chakra.span>
        <chakra.span paddingRight="2rem" fontSize="1.125rem">
          hours
        </chakra.span>
        <chakra.span paddingRight="0.825rem">{minutes}</chakra.span>
        <chakra.span fontSize="1.125rem">minutes</chakra.span>
      </Box>
      <Button
        variant="action"
        onClick={onOpen}
        width="100px"
        minWidth="unset"
        bgColor="gray.50"
        height="35px"
        color="blue"
        borderRadius="20px"
        border="none"
        disabled={isReadOnly}
      >
        Edit
      </Button>
      {isOpen && (
        <TimerVitalsInputModal
          isOpen={isOpen}
          onClose={onClose}
          initialValue={timer}
        />
      )}
    </Box>
  );
}

function CountdownCard(props: CountdownCardProps) {
  const {
    timerMs,
    latestRecordTakenAt,
    isReadOnly = false,
    onPause,
    onReset,
    onResume,
    isResumingOrPausing,
    isResetting,
    isPaused,
  } = props;
  const totalMinutes = timerMs / 1000 / 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes - 60 * hours);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 20px"
      bg="white"
      width="100%"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.1)"
      marginBottom="16px"
    >
      <Box fontWeight="600">Countdown</Box>
      <Box fontSize="3rem" color="orange">
        {!isPaused ? (
          <ActiveTimer
            latestRecordTakenAt={latestRecordTakenAt}
            timer={timerMs}
            vitalsTimerMode
          />
        ) : (
          <>
            <chakra.span paddingRight="0.825rem">{hours}</chakra.span>
            <chakra.span paddingRight="2rem" fontSize="1.125rem">
              hours
            </chakra.span>
            <chakra.span paddingRight="0.825rem">{minutes}</chakra.span>
            <chakra.span fontSize="1.125rem">minutes</chakra.span>
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Button
          variant="action"
          bgColor="gray.50"
          width="100px"
          minWidth="unset"
          height="35px"
          color="blue"
          borderRadius="20px"
          border="none"
          marginBottom="15px"
          onClick={onReset}
          disabled={isReadOnly}
          isLoading={isResetting}
        >
          Reset
        </Button>
        <Button
          variant="action"
          bgColor={!isPaused ? "gray.50" : "green"}
          color={!isPaused ? "blue" : "white"}
          width="100px"
          minWidth="unset"
          height="35px"
          borderRadius="20px"
          border="none"
          disabled={isReadOnly}
          onClick={isPaused ? onResume : onPause}
          isLoading={isResumingOrPausing}
        >
          {isPaused ? "Resume" : "Pause"}
        </Button>
      </Box>
    </Box>
  );
}

const VitalsButtonSection = React.forwardRef<
  HTMLDivElement,
  VitalsTopSectionProps
>((props) => {
  const { encounterId, onScroll, isReadOnly = false, ...boxProps } = props;
  const { vitalsData, isEditMode, isAddMode, setEditing, setAdding } =
    useVitalsContext();

  const records = vitalsData?.vitalChecks;
  const recordsLength = records?.length || 0;

  return (
    <Box
      display="grid"
      gridTemplateColumns="365px 1fr"
      gridColumnGap="10px"
      {...boxProps}
    >
      <Box display="flex" flexDir="row" gap="1rem" marginBottom="12px">
        <Button
          size="sm"
          borderRadius="40px"
          color={isEditMode ? "white" : "black"}
          bgColor={isEditMode ? "green" : "white"}
          variant={isEditMode ? "solid" : "outline"}
          height="45px"
          colorScheme={isEditMode ? "green" : "white"}
          onClick={() => setEditing(!isEditMode)}
          isDisabled={isAddMode || isReadOnly}
          minW="120px"
          borderColor="gray.700"
          fontWeight="normal"
        >
          {isEditMode ? "Done" : "Edit"}
        </Button>
        <Button
          size="sm"
          height="45px"
          borderRadius="40px"
          colorScheme={isAddMode ? "red" : "blue"}
          margin="0px 10px"
          onClick={() => {
            setAdding(!isAddMode);
          }}
          isDisabled={isEditMode || isReadOnly}
          _disabled={{
            backgroundColor: "transparent",
            color: "gray.450",
            borderColor: "gray.450",
            border: "1px solid",
            cursor: "not-allowed",
            _hover: { backgroundColor: "transparent" },
          }}
          minW="220px"
          fontWeight="normal"
        >
          {isAddMode ? "Cancel" : "+ Add New Vitals"}
        </Button>
        {isEditMode && recordsLength === 0 && (
          <Button
            size="sm"
            borderRadius="40px"
            color="white"
            bgColor="green"
            variant="solid"
            height="45px"
            colorScheme="green"
            onClick={() => setEditing(!isEditMode)}
            isDisabled={isAddMode || isReadOnly}
            minW="220px"
            fontWeight="normal"
          >
            Done
          </Button>
        )}
      </Box>
    </Box>
  );
});

function WeightCard({ onSave, isReadOnly }: WeightCardProps) {
  const { vitalsData } = useVitalsContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const weightDisplay = React.useMemo(
    () => getEditCellDisplay("weight", vitalsData?.vitalsBMI?.weight),
    [vitalsData]
  );
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 20px"
      bg="white"
      width="100%"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.1)"
      marginBottom="16px"
    >
      <Box>
        <Text fontWeight="500">Weight (kg)</Text>
      </Box>
      <Box sx={{ fontSize: "2.813rem", color: "blue" }}>{weightDisplay}</Box>
      <Box>
        <Button
          variant="action"
          bgColor="gray.50"
          width="100px"
          minWidth="unset"
          height="35px"
          color="blue"
          borderRadius="20px"
          border="none"
          disabled={isReadOnly}
          onClick={onOpen}
        >
          Edit
        </Button>
      </Box>
      <WeightVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={weightDisplay}
      />
    </Box>
  );
}

function HeightCard({ onSave, isReadOnly }: HeightCardProps) {
  const { vitalsData } = useVitalsContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 20px"
      bg="white"
      width="100%"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.1)"
      marginBottom="16px"
    >
      <Box>
        <Text fontWeight="500">Height</Text>
      </Box>
      <Box sx={{ fontSize: "2.813rem", color: "blue" }}>
        {getEditCellDisplay("height", vitalsData?.vitalsBMI?.height)}
      </Box>
      <Box>
        <Button
          variant="action"
          bgColor="gray.50"
          width="100px"
          minWidth="unset"
          height="35px"
          color="blue"
          borderRadius="20px"
          border="none"
          disabled={isReadOnly}
          onClick={onOpen}
        >
          Edit
        </Button>
      </Box>
      <HeightVitalsInputModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        initialValue={vitalsData?.vitalsBMI?.height?.toString()}
      />
    </Box>
  );
}

function BMICard() {
  const { vitalsData } = useVitalsContext();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 20px"
      bg="white"
      width="100%"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.1)"
      marginBottom="16px"
    >
      <Box flex={1}>
        <Text fontWeight="500">BMI</Text>
      </Box>
      <Box sx={{ fontSize: "2.813rem", color: "blue" }}>
        {getEditCellDisplay("bmi", vitalsData?.vitalsBMI?.bmi)}
      </Box>
      <Box flex={1} />
    </Box>
  );
}

function AmbulatoryCard({
  encounterId,
  isReadOnly,
}: {
  encounterId: string;
  isReadOnly: boolean;
}) {
  const { vitalsData } = useVitalsContext();

  const { data: ambulatoryNotesData, isLoading: isNotesLoading } =
    useAmbulatoryNotes({
      encounterId,
    });

  const { mutate: updateAmbulatory, isLoading } = useUpdateAmbulatory({
    encounterId,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();
  const ambulatoryNotesCount = ambulatoryNotesData?.data?.length || 0;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 20px"
      bg="white"
      width="100%"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.1)"
      marginBottom="16px"
    >
      <Flex alignItems="center">
        <Text fontWeight="500">Ambulatory</Text>{" "}
        <IconButton
          justifyContent="flex-start"
          paddingLeft="1rem"
          aria-label="ambulatory-notes"
          icon={
            <Icon
              as={EmrNotes}
              color={ambulatoryNotesCount > 0 ? "blue" : "gray.500"}
              fontSize="1.25rem"
            />
          }
          onClick={onOpen}
          variant="ghost"
        />
      </Flex>
      <Flex columnGap="1rem" alignItems="center">
        <Text fontWeight="500">{vitalsData?.ambulatory ? "Yes" : "No"}</Text>
        <Switch
          disabled={isLoading || isReadOnly}
          size="lg"
          isChecked={Boolean(vitalsData?.ambulatory)}
          onChange={(e) => updateAmbulatory({ ambulatory: e.target.checked })}
          colorScheme="green"
        />
      </Flex>
      <AmbulatoryNotesModal
        onClose={onClose}
        isOpen={isOpen}
        encounterId={encounterId}
        isDisabled={isReadOnly}
        data={ambulatoryNotesData}
        isLoading={isNotesLoading}
      />
    </Box>
  );
}

function VitalsTopSection(props: VitalsTopSectionProps) {
  const { encounterId, isReadOnly = false, onUpdateRecord, ...rest } = props;
  const { onPause, onReset, onResume, isResumingOrPausing, isResetting } =
    useVitalsContext();
  const { data, isLoading, error } = useVitalsTimer(encounterId);
  const toast = useToast();
  const timer = data?.data.timer ? formatTimer(data.data.timer) : "0:00";
  const { vitalsData } = useVitalsContext();
  const latestRecordTakenAt = data?.data.lastCheck
    ? new Date(data.data.lastCheck)
    : undefined;

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  const { mutateAsync: updateVitalBMI } = usePatchVitalBMI({
    encounterId,
  });

  const updateBMI = async (payload: VitalPayload) => {
    try {
      await updateVitalBMI(payload);
      toast({ description: formMessages.updateSuccess("Vital bmi") });

      // Only initiate the AYVA process if the bmi was defined (height/weight were defined in the payload)
      const bmiVal = payload.find((el) => el.path === "bmi");
      if (bmiVal?.value) {
        return onUpdateRecord && onUpdateRecord();
      }
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Box overflow="auto" minHeight="236px" {...rest}>
      {isLoading && <Loading />}
      <Flex columnGap="19px">
        <HeightCard
          isReadOnly={isReadOnly}
          onSave={(value) =>
            updateBMI([
              { path: "height", value },
              { path: "weight", value: vitalsData?.vitalsBMI?.weight },
              {
                path: "bmi",
                value: calculateBmi(vitalsData?.vitalsBMI?.weight, value),
              },
            ])
          }
        />
        <WeightCard
          isReadOnly={isReadOnly}
          onSave={(value, weightUnit) => {
            const weight = weightConversion(value, weightUnit);
            updateBMI([
              { path: "weight", value: weight },
              { path: "height", value: vitalsData?.vitalsBMI?.height },
              {
                path: "bmi",
                value: calculateBmi(weight, vitalsData?.vitalsBMI?.height),
              },
            ]);
          }}
        />
        <BMICard />
        <AmbulatoryCard encounterId={encounterId} isReadOnly={isReadOnly} />
      </Flex>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, minmax(400px, 1fr))"
        gridColumnGap="16px"
      >
        <TimerCard timer={timer} isReadOnly={isReadOnly} />
        <CountdownCard
          timer={timer}
          timerMs={data?.data.millisecondRemaining || 0}
          isPaused={
            data?.data.status === "Paused" || data?.data.status === "NotStarted"
          }
          onPause={onPause}
          onResume={onResume}
          onReset={onReset}
          latestRecordTakenAt={latestRecordTakenAt}
          isReadOnly={isReadOnly}
          isResumingOrPausing={isResumingOrPausing}
          isResetting={isResetting}
        />
      </Box>
    </Box>
  );
}

type AYVAVitalsProps = {
  encounterIntegrations?: EncounterIntegration;
  isLoadingEncounterIntegrations: boolean;
  isLoadingUpdateIntegrationAYVA: boolean;
};

function AYVAVitals(props: AYVAVitalsProps) {
  const {
    encounterIntegrations,
    isLoadingEncounterIntegrations,
    isLoadingUpdateIntegrationAYVA,
  } = props;

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap="15px"
    >
      <HStack spacing="15px">
        <Box display="flex" justifyContent="space-between">
          <EncounterIntegrationButton
            type={"ayva-height"}
            label={"Height"}
            sx={{
              width: "220px",
              padding: "8px 16px 8px 16px",
              position: "relative",
              bottom: "15px",
            }}
            icon={SquareInfo}
            status={
              encounterIntegrations?.ayvaHeightConnectionStatus ??
              "Disconnected"
            }
            isLoading={
              isLoadingEncounterIntegrations || isLoadingUpdateIntegrationAYVA
            }
          />
        </Box>
      </HStack>

      <HStack spacing="15px">
        <Box display="flex" justifyContent="space-between">
          <EncounterIntegrationButton
            type={"ayva-weight"}
            label={"Weight"}
            sx={{
              width: "220px",
              padding: "8px 16px 8px 16px",
              position: "relative",
              bottom: "15px",
            }}
            icon={SquareInfo}
            status={
              encounterIntegrations?.ayvaWeightConnectionStatus ??
              "Disconnected"
            }
            isLoading={
              isLoadingEncounterIntegrations || isLoadingUpdateIntegrationAYVA
            }
          />
        </Box>
      </HStack>
    </Box>
  );
}

const VITALS_SCROLLBAR = {
  "&::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },
  "&::-webkit-scrollbar-track": {
    background: "rgba(0, 0, 0, 0.2)",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E3E3E8",
  },
};

function Vitals(props: { chartType?: ChartType; isReadOnly?: boolean }) {
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const { pathname } = useLocation();
  const leftRef = React.useRef<HTMLDivElement>(null);
  const rightRef = React.useRef<HTMLDivElement>(null);
  const buttonsRef = React.useRef<HTMLDivElement>(null);
  const { onScrollLeft, onScrollRight } = useSyncScroll(leftRef, rightRef);
  const { onScrollLeft: onScrollButtons, onScrollRight: onScrollSecondRight } =
    useSyncScroll(buttonsRef, rightRef);
  const { chartType, isReadOnly } = props;

  const { isPastEncounter } = useChartTrackingContext();
  const isReadOnlyOrPastEncounter = isReadOnly || isPastEncounter;

  const toast = useToast();

  const {
    data: encounterIntegrations,
    isLoading: isLoadingEncounterIntegrations,
  } = useEncounterIntegrations(encounterId);

  const {
    isOpen: isOpenAyvaModal,
    onClose: onCloseAyvaModal,
    onOpen: onOpenAyvaModal,
  } = useDisclosure();

  const [ayvaModalLabel, setAyvaModalLabel] = React.useState("");
  const [ayvaModalTitle, setAyvaModalTitle] = React.useState("");
  const [ayvaModalContent, setAyvaModalContent] = React.useState<string[]>([]);

  const {
    mutateAsync: updateIntegrationAYVA,
    isLoading: isLoadingUpdateIntegrationAYVA,
  } = useUpdateEncounterIntegrationAYVABMI(encounterId, "BMI");

  const onUpdateRecord = async () => {
    try {
      // Only attempt to update AYVA information if info was already sent
      if (encounterIntegrations?.data.ayvaConnectionStatus === "Success") {
        const ayvaRequest = await updateIntegrationAYVA({
          encounterId,
          protocol: "BMI",
        });

        setAyvaModalTitle(ayvaRequest.data?.messageInfoTitle ?? "");
        setAyvaModalLabel(ayvaRequest.data?.messageInfoAction ?? "");
        setAyvaModalContent(ayvaRequest.data?.messageInfoMessages ?? []);
        onOpenAyvaModal();
      }
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  };

  const saveAndNext = useSaveAndNext(
    onUpdateRecord,
    "vitals",
    pathname.includes("triage") ? "nurse" : "provider",
    false
  );

  return (
    <VitalsProvider encounterId={encounterId}>
      <Box
        display="flex"
        flexDirection="column"
        padding="1rem"
        height="100%"
        overflow="hidden"
      >
        <AYVAVitals
          encounterIntegrations={encounterIntegrations?.data}
          isLoadingEncounterIntegrations={isLoadingEncounterIntegrations}
          isLoadingUpdateIntegrationAYVA={isLoadingUpdateIntegrationAYVA}
        />

        <SharedChartSectionHeader
          onSave={onUpdateRecord}
          icon={<EmrTakeVitals />}
          marginBottom="16px"
          encounterId={encounterId}
          sectionId="SVIT"
          showNotesBtn={chartType === "Triage" ? false : undefined}
          showActionsBtns
          onSaveAndNext={saveAndNext}
        >
          Vitals {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <VitalsTopSection
          marginBottom="16px"
          encounterId={encounterId}
          isReadOnly={isReadOnlyOrPastEncounter}
          onUpdateRecord={onUpdateRecord}
        />
        <VitalsButtonSection
          encounterId={encounterId}
          ref={buttonsRef}
          onScroll={onScrollButtons}
          isReadOnly={isReadOnlyOrPastEncounter}
        />
        <Box
          display="grid"
          gridTemplateColumns="365px 1fr"
          gridColumnGap="10px"
          overflow="auto"
        >
          <VitalsFixedSection
            ref={leftRef}
            onScroll={onScrollLeft}
            css={VITALS_SCROLLBAR}
          />
          <VitalsInfiniteGrid
            ref={rightRef}
            onScroll={(event) => {
              onScrollSecondRight(event);
              onScrollRight(event);
            }}
            css={VITALS_SCROLLBAR}
          />
        </Box>

        {isOpenAyvaModal && (
          <SingleActionDialog
            title={ayvaModalTitle}
            actionLabel={ayvaModalLabel}
            actionStyles={{ color: "#007AFF", textTransform: "capitalize" }}
            content={ayvaModalContent}
            onClose={onCloseAyvaModal}
            isOpen={isOpenAyvaModal}
          />
        )}
      </Box>
    </VitalsProvider>
  );
}

export { Vitals };
