import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type UsePatientInfoResponse = {
  contactInfo: {
    phone?: string;
    street?: string;
    email?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    dob?: string;
  };
  employment: {
    name?: string;
    employerPhone?: string;
    ext?: string;
    occupation?: string;
  };
  insurance: {
    name?: string;
    observationEligible?: boolean;
    isCourtesyBenefit?: boolean;
    isMSEBenefit?: boolean;
    isHherReferralBenefit?: boolean;
  };
  preferredPharmacy: {
    id?: string;
    name?: string;
    phone?: string;
    fax?: string;
    ncpdpId?: string;
  };
  primaryCarePhysician: {
    name?: string;
    phone?: string;
    fax?: string;
    id?: string;
    address?: string;
    city?: string;
    state?: string;
    suite?: string;
    zip?: string;
  };
  emergencyContact: {
    name?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    relationship?: string;
    leaveMessage?: boolean;
  };
};

function usePatientInfo(encounterId: string) {
  return useAxiosQuery(["patientInfo", { encounterId }], () =>
    axiosPatientInstance.get<UsePatientInfoResponse>(
      `v1/InProcess/${encounterId}/patient-info`
    )
  );
}

export type { UsePatientInfoResponse };
export { usePatientInfo };
