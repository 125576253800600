import * as React from "react";
import { EmrHeart, EmrLiquid } from "@medstonetech/slate-icons";
import { MedicalStar, RFilled, Restricted } from "icons";
import { DietType } from "enums";
import { Icon } from "@chakra-ui/react";

const DIET_TYPE_ICON: Record<DietType, JSX.Element> = {
  Regular: <Icon as={RFilled} color="cyan" />,
  Clear_liquid: <Icon as={EmrLiquid} color="orange" />,
  NPO: <Icon as={Restricted} color="orange" />,
  Healthy: <Icon as={EmrHeart} color="cyan" />,
  Diabetic: <Icon as={MedicalStar} color="orange" />,
};

export { DIET_TYPE_ICON };
