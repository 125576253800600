import {
  Box,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { EmrDocument } from "@medstonetech/slate-icons";
import { useOrderFormFavorites } from "modules/charts-shared/api/queries/useOrderFormFavorites";
import {
  ChartRouteBaseParams,
  OrderItem,
  SharedChartBaseProps,
} from "modules/charts-shared/types";
import { OrderElementResponse } from "modules/order-list/types";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { AddOrderItemModal } from "../AddOrderItemModal";
import { ChartSectionHeader } from "../chart";
import { OrderFormCart } from "../order-form-cart/OrderFormCart";
import { OrderFormList } from "./OrderFormList";
import { Button } from "shared";
import { MdChevronLeft } from "react-icons/md";
import { AddMedicationOrderModal } from "../add-medication-order-modal";
import {
  OrderQueueRequestDto,
  useActiveCharts,
  useAddMultipleOrdersToQueue,
  useAddOrderToQueue,
  useDeleteOrderQueue,
  useOrderQueue,
} from "modules/charts-shared/api";
import { useToast } from "hooks";
import { extractApiErrorMessage } from "utils";
import { formMessages, genericErrors } from "messages";
import { useAuth0 } from "@auth0/auth0-react";
import { USERID_CLAIMS } from "system-constants";
import { OrderFormElementRoute } from "enums";
import { format } from "date-fns";
import { OrderPrompt } from "./OrderPrompt";

const sectionId = "SPOF";

type OrderFormProps = SharedChartBaseProps & {
  onBack?: () => void;
};

const getTabIndex = (paramsTab: string | null): number => {
  if (!paramsTab) {
    return 0;
  }

  const tabs: { [key: string]: number } = {
    labs: 0,
    radiology: 1,
    medications: 2,
    treatments: 3,
    ekg: 4,
  };

  return tabs[paramsTab] ?? 0;
};

function OrderForm(props: OrderFormProps) {
  const { chartCode, chartType, onBack, isReadOnly = false } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const { user: currentUser } = useAuth0();
  const userId = currentUser ? currentUser[USERID_CLAIMS] : "";
  const toast = useToast();

  const { data } = useOrderFormFavorites();
  const favorites = React.useMemo(() => (data ? data.data : []), [data]);
  const [addingItem, setAddingItem] =
    React.useState<OrderElementResponse | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const paramsTab = queryParams.get("tab");

  const { mutateAsync: addOrderToQueue, isLoading: isAddingSingleLoading } =
    useAddOrderToQueue({
      encounterId,
      chartCode,
    });

  const {
    mutateAsync: addMultipleOrdersToQueue,
    isLoading: isAddingMultipleLoading,
  } = useAddMultipleOrdersToQueue({
    encounterId,
    chartCode,
  });

  const {
    mutateAsync: deleteOrderQueue,
    isLoading: isDeleteOrderQueueLoading,
  } = useDeleteOrderQueue({
    encounterId,
  });

  const isAdding = React.useMemo(
    () => isAddingSingleLoading || isAddingMultipleLoading,
    [isAddingSingleLoading, isAddingMultipleLoading]
  );

  const { data: orderQueueData, isLoading: isOrderQueueLoading } =
    useOrderQueue({ encounterId });

  const { data: activeCharts, isLoading: isLoadingActiveCharts } =
    useActiveCharts(encounterId);

  const [showQueueWarning, setShowQueueWarning] = React.useState(false);
  React.useEffect(() => {
    if (orderQueueData && orderQueueData?.data?.length > 0) {
      setShowQueueWarning(true);
    }
  }, [orderQueueData]);

  const handleAddItem = async (item: OrderItem) => {
    try {
      if (item.isScheduled) {
        const orders: OrderQueueRequestDto[] = [];
        for (let i = 0; i < (item.repetitions || 1); i++) {
          const payload: OrderQueueRequestDto = {
            orderBy: userId,
            description: item.description,
            notes: item.notes,
            elementType: item.orderElementType,
            elementCategory: item.orderElementCategory ?? undefined,
            location: item.location,
            route: item.route as OrderFormElementRoute,
            administrationTime: item.administrationTime,
            cptCode: item.cptCode,
            studyTypeId: item.studyTypeId,
            instructions: item.instructions,
            isObservation: item.isObservation,
            isScheduled: item.isScheduled,
            isPRN: item.isPRN,
            prnReason: item.prnReason,
            scheduledTime: format(
              new Date(
                new Date(item.scheduledTime || "").getTime() +
                  (item.repetitionHours || 0) * 60 * 60 * 1000 * i +
                  (item.repetitionMinutes || 0) * 60 * 1000 * i
              ),
              "yyyy-MM-dd'T'HH:mm:ss"
            ),
          };
          orders.push(payload);
        }

        await addMultipleOrdersToQueue(orders);
      } else {
        const payload: OrderQueueRequestDto = {
          orderBy: userId,
          description: item.description,
          notes: item.notes,
          elementType: item.orderElementType,
          elementCategory: item.orderElementCategory ?? undefined,
          location: item.location,
          route: item.route as OrderFormElementRoute,
          administrationTime: item.administrationTime,
          cptCode: item.cptCode,
          studyTypeId: item.studyTypeId,
          instructions: item.instructions,
          repetitions: item.repetitions,
          scheduledTime: item.scheduledTime,
          isScheduled: item.isScheduled,
          isObservation: item.isObservation,
        };
        await addOrderToQueue(payload);
      }

      toast({
        status: "success",
        description: formMessages.createSuccess("Order Queue"),
      });
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const handleAddingItem = (item: OrderElementResponse) => {
    setAddingItem(item);
  };

  const handleDeleteCartItem = async (id: string) => {
    try {
      await deleteOrderQueue([id]);
      toast({
        status: "success",
        description: formMessages.deleteSuccess("Order"),
      });
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const handleClear = async () => {
    try {
      const orderIds = orderQueueData?.data.map((order) => order.id) ?? [];
      await deleteOrderQueue(orderIds);
      toast({
        status: "success",
        description: formMessages.deleteSuccess("Order"),
      });
      onBack?.();
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const orderQueue: OrderItem[] =
    orderQueueData?.data.map((order) => {
      return {
        id: order.id,
        description: order.description,
        notes: order.notes,
        date: order.createdAt,
        orderElementCategory: order.elementCategory ?? null,
        location: order.location,
        orderElementType: order.elementType,
        orderElementRoute: order.route,
        administrationTime: order.administrationTime,
        scheduledTime: order.scheduledTime,
        isScheduled: order.isScheduled,
        isObservation: order.isObservation,
        isPRN: order.isPRN,
        prnReason: order.prnReason,
      };
    }) ?? [];

  return (
    <Box height="100%" w="100%">
      {Boolean(onBack) && (
        <Button
          variant="label"
          ml="20px"
          color="blue"
          mt="20px"
          onClick={onBack}
        >
          <MdChevronLeft fontSize="2rem" />
          Back
        </Button>
      )}
      <Flex height="100%" w="100%">
        <Box
          flex={2}
          overflow="auto"
          overflowX="hidden"
          p="20px"
          borderRight="1px solid"
          borderColor="gray.200"
        >
          <ChartSectionHeader
            icon={<Icon as={EmrDocument} />}
            chartId={chartCode}
            encounterId={encounterId}
            sectionId={sectionId}
            showNotesBtn={chartType === "Chart" || chartType === "Triage"}
            isDisabled={isReadOnly}
          >
            Orders {isReadOnly ? "(Read Only)" : ""}
          </ChartSectionHeader>
          <Tabs isFitted margin="0.15rem" defaultIndex={getTabIndex(paramsTab)}>
            <TabList>
              <Tab>Labs</Tab>
              <Tab>Radiology</Tab>
              <Tab>Medications</Tab>
              <Tab>Treatments</Tab>
              <Tab>EKG</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Lab"
                  favorites={favorites}
                  isDisabled={isReadOnly}
                />
              </TabPanel>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Radiology"
                  favorites={favorites}
                  isDisabled={isReadOnly}
                />
              </TabPanel>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Medication"
                  favorites={favorites}
                  isDisabled={isReadOnly}
                />
              </TabPanel>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="Treatment"
                  favorites={favorites}
                  isDisabled={isReadOnly}
                />
              </TabPanel>
              <TabPanel>
                <OrderFormList
                  onAddItem={handleAddingItem}
                  type="EKG"
                  favorites={favorites}
                  isDisabled={isReadOnly}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box
          flex={1}
          pt="35px"
          px="20px"
          display="flex"
          flexDirection="column"
          overflow="auto"
          backgroundColor="gray.200"
        >
          <OrderFormCart
            items={orderQueue}
            onDelete={handleDeleteCartItem}
            onClear={handleClear}
            chartCode={chartCode}
            activeCharts={activeCharts?.data}
            isOrderQueueLoading={
              isLoadingActiveCharts || isOrderQueueLoading || isAdding
            }
            isDeleteOrderQueueLoading={isDeleteOrderQueueLoading}
            isDisabled={isReadOnly}
          />
        </Box>
        <AddOrderItemModal
          isOpen={
            Boolean(addingItem) &&
            addingItem?.orderFormElementType !== "Medication"
          }
          onClose={() => setAddingItem(null)}
          onAddItem={handleAddItem}
          item={addingItem}
          favorite={favorites.find(
            (fav) => fav.orderFormElementId === addingItem?.id
          )}
          isAdding={isAdding}
        />
        {addingItem && (
          <AddMedicationOrderModal
            isOpen={
              Boolean(addingItem) &&
              addingItem?.orderFormElementType === "Medication"
            }
            onAddItem={handleAddItem}
            onClose={() => setAddingItem(null)}
            item={addingItem}
            encounterId={encounterId}
            isAdding={isAdding}
          />
        )}

        <OrderPrompt
          when={!isReadOnly && showQueueWarning}
          onContinue={() => setShowQueueWarning(false)}
          isSaveLoading={
            isLoadingActiveCharts ||
            isOrderQueueLoading ||
            isDeleteOrderQueueLoading
          }
        />
      </Flex>
    </Box>
  );
}

export { OrderForm };
