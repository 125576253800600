import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  chakra,
} from "@chakra-ui/react";
import { CloseCircle, SystemRequirements } from "icons";
import { Button, Card } from "shared";

type SystemRequirementsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const REQUIREMENTS = [
  {
    component: "CPU",
    minimum: "Intel Core i3 (10th Gen) or AMD Reyzen 3",
    better: "Intel Core i5/i7 (12th Gen) or AMD Reyzen 5/7",
  },
  {
    component: "RAM",
    minimum: "8 GB",
    better: "16GB (for multitasking)",
    bold: true,
  },
  {
    component: "Storage",
    minimum: "128GB SSD",
    better: "256GB+ NVMe SSD (for fast loading)",
    bold: true,
  },
  {
    component: "Graphics",
    minimum: "Integrated Graphics (Intel UHD or AMD Radeon Vega)",
    better: "Dedicated GPU not needed unless using AI-heavy features",
  },
  {
    component: "Display",
    minimum: "1080p (Full HD)",
    better: "Dual monitors (better for workflow)",
  },
  {
    component: "Network",
    minimum: "Wired Ethernet or Wi-Fi 5 (802.11ac)",
    better: "Gigabit Ethernet or Wi-Fi 6",
  },
  {
    component: "OS",
    minimum: "Windows 10 Pro / mac OS 11+",
    better: "Windows 11 Pro / mac OS 13+",
  },
  {
    component: "Browser",
    minimum: "Chrome Only",
    better: "Chrome Only",
    bold: true,
  },
];

type RequirementRowProps = {
  component: string;
  minimum: string;
  better: string;
  bold?: boolean;
  isLast?: boolean;
};

function RequirementRow({
  component,
  minimum,
  better,
  bold,
  isLast,
}: RequirementRowProps) {
  return (
    <>
      <Flex
        border="1px solid"
        borderColor="gray.450"
        borderBottomLeftRadius={isLast ? "1.125rem" : "0"}
        px="1.25rem"
        align="center"
      >
        <chakra.a fontWeight="700">{component}</chakra.a>
      </Flex>
      <Flex
        border="1px solid"
        borderColor="gray.450"
        px="1.25rem"
        align="center"
      >
        <chakra.a fontWeight={bold ? "700" : "500"}>{minimum}</chakra.a>
      </Flex>
      <Flex
        border="1px solid"
        borderColor="gray.450"
        borderBottomRightRadius={isLast ? "1.125rem" : "0"}
        px="1.25rem"
        align="center"
      >
        <chakra.a fontWeight={bold ? "700" : "500"}>{better}</chakra.a>
      </Flex>
    </>
  );
}

function SystemRequirementsModal({
  isOpen,
  onClose,
}: SystemRequirementsModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "1.125rem",
        }}
      >
        <Card borderRadius="1.125rem">
          <Flex
            height="50px"
            bgColor="gray.250"
            align="center"
            borderTopRadius="1.125rem"
          >
            <Box />
            <Flex flex="1" justify="center" align="center" columnGap="0.5rem">
              <Icon as={SystemRequirements} />
              <chakra.a>System Requirements</chakra.a>
            </Flex>
            <Button variant="icon" onClick={onClose}>
              <Icon as={CloseCircle} color="gray.700" />
            </Button>
          </Flex>
          <Box
            display="grid"
            gridTemplateColumns="180px 340px 340px"
            height="50px"
            bgColor="gray.350"
          >
            <Flex
              border="1px solid"
              borderColor="gray.450"
              px="1.25rem"
              align="center"
            >
              <chakra.a color="gray.650">Component</chakra.a>
            </Flex>
            <Flex
              border="1px solid"
              borderColor="gray.450"
              px="1.25rem"
              align="center"
            >
              <chakra.a color="gray.650">Minimum</chakra.a>
            </Flex>
            <Flex
              border="1px solid"
              borderColor="gray.450"
              px="1.25rem"
              align="center"
            >
              <chakra.a color="gray.650">Better (50+ Users)</chakra.a>
            </Flex>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="180px 340px 340px"
            gridTemplateRows="repeat(8, 70px)"
          >
            {REQUIREMENTS.map((requirement, index) => (
              <RequirementRow
                key={requirement.component}
                {...requirement}
                isLast={index === REQUIREMENTS.length - 1}
              />
            ))}
          </Box>
        </Card>
      </ModalContent>
    </Modal>
  );
}

export { SystemRequirementsModal };
