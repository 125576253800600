import * as React from "react";
import { AvatarProps, Avatar, Box, Icon } from "@chakra-ui/react";
import { useEncounterProfilePicture } from "modules/reception/api";
import { useFileUrl } from "hooks";
import { EmrBed } from "@medstonetech/slate-icons";

type UserAvatarProps = {
  encounterId: string;
  icon?: React.ReactElement;
  showObservation?: boolean;
  indicatorColor?: string;
  pictureIndicatorColor?: string;
  stp?: boolean;
} & AvatarProps;

function EncounterAvatar(props: UserAvatarProps) {
  const {
    encounterId,
    icon,
    indicatorColor,
    pictureIndicatorColor,
    stp,
    size,
    showObservation = false,
    ...avatarProps
  } = props;
  const { data: file } = useEncounterProfilePicture(encounterId);

  const fileUrl = useFileUrl(file?.data);

  return (
    <Avatar
      position="relative"
      src={fileUrl}
      size={size}
      {...avatarProps}
      {...(!!pictureIndicatorColor && {
        borderWidth: size === "lg" ? "5px" : "3px",
        borderStyle: "solid",
        borderColor: `${pictureIndicatorColor}`,
      })}
    >
      {icon && (
        <Box
          position="absolute"
          borderRadius="5px"
          width={size === "lg" ? "1.75rem" : "1.35rem"}
          height={size === "lg" ? "1.75rem" : "1.35rem"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          top={0}
          right={size === "lg" ? 2 : -1}
          {...(!!indicatorColor && { bgColor: indicatorColor })}
        >
          {React.cloneElement(icon, {
            fontSize: size === "lg" ? "1.25rem" : "1rem",
            color: "white",
          })}
        </Box>
      )}
      {showObservation && (
        <Box
          position="absolute"
          borderRadius="5px"
          width={size === "lg" ? "1.75rem" : "1.25rem"}
          height={size === "lg" ? "1.75rem" : "1.25rem"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bottom={0}
          left={size === "lg" ? 2 : -1}
          bgColor="purple"
        >
          <Icon as={EmrBed} color="white" width="1.15em" height="1.15em" />
        </Box>
      )}
    </Avatar>
  );
}

export { EncounterAvatar };
