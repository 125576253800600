import { axiosOrderInstance } from "config/axios";
import { useAxiosQuery } from "hooks";

function usePrnOrdersPending(encounterId: string) {
  return useAxiosQuery(["prnOrdersPending", { encounterId }], () =>
    axiosOrderInstance.get<string[]>(`v1/${encounterId}/prn-pending`)
  );
}

export { usePrnOrdersPending };
