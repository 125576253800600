import { Gender, MedicalRecordStatus } from "enums";
import { PaginationPayload, PaginationResponse } from "types";

import { axiosPatientInstance } from "config";
import { useQuery } from "react-query";
import { AuditStatus } from "modules/audits/types";

type MedicalRecordUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  pictureUrl: string;
};

type MedicalRecord = {
  encounterId: string;
  encounterStatus: MedicalRecordStatus;
  pictureUrl?: string;
  fullName: string;
  gender: Gender;
  dob: string;
  ssn: string;
  mrn: string;
  encounterNumber: string;
  encounterDate: string;
  dischargeDate?: string;
  chartCode?: string;
  hasViewerLog: boolean;
  nursingChartCompleted: boolean;
  nursingUser?: MedicalRecordUser;
  providerChartCompleted: boolean;
  providerUser?: MedicalRecordUser;
  demographicAuditStatus?: AuditStatus;
  demographicAuditor?: MedicalRecordUser;
  nurseAuditStatus?: AuditStatus;
  nurseChartAuditor?: MedicalRecordUser;
  providerAuditStatus?: AuditStatus;
  providerChartAuditor?: MedicalRecordUser;
};

type SearchMedicalRecordsResponse = PaginationResponse<MedicalRecord>;

type MedicalRecordsFilter = Partial<{
  firstName: string;
  lastName: string;
  dob: string | Date;
  gender: Gender | null;
  cellphone: string;
  ssn: string;
  mrn: string;
  wristband: string;
  encounterNumber?: string;
}>;

type UseSearchMedicalRecordsProps = MedicalRecordsFilter & PaginationPayload;

function useSearchMedicalRecords(
  props: UseSearchMedicalRecordsProps,
  enabled = true
) {
  const { sort, descending, page = 1, size = 10, ...filters } = props;
  const validFilters = Object.entries(filters).reduce((curr, [k, v]) => {
    if (v) {
      return { ...curr, [k]: v };
    }
    return curr;
  }, {});

  return useQuery(
    ["searchAllEncounters", props],
    () =>
      axiosPatientInstance.get<SearchMedicalRecordsResponse>(
        "v1/medical-records",
        {
          params: {
            page,
            pageSize: size,
            sortDesc: descending || false,
            orderBy: sort,
            ...validFilters,
          },
        }
      ),
    { keepPreviousData: true, staleTime: 0, enabled }
  );
}

export { useSearchMedicalRecords };
export type {
  UseSearchMedicalRecordsProps,
  MedicalRecordsFilter,
  MedicalRecord,
};
