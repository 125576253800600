import * as React from "react";

import { Button, Card, Loading, StaffAvatar, WarningDialog } from "shared";
import {
  ChartRouteBaseParams,
  SharedChartBaseProps,
} from "modules/charts-shared/types";
import {
  ChartRow,
  ChartRowElement,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
  SidebarHardStops,
} from "modules";
import { EmrProfileComplete, EmrSignature } from "@medstonetech/slate-icons";
import {
  Box,
  Flex,
  Icon,
  chakra,
  useDisclosure,
  useToast,
  Input,
  InputProps,
  Modal,
  UseDisclosureReturn,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { ChartForm } from "modules/charts-shared-v2/components";
import { FormPromptWithSaveProps } from "shared/prompt";
import { FormProvider, useFieldArray } from "react-hook-form";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useChartDispositionForm } from "modules/charts-shared/hooks/userChartDispositionForm";
import {
  useEncounterSideInfo,
  useUpdateEncounterDisposition,
} from "modules/reception/api";
import { usePermissions } from "contexts";
import { DispositionNurseSignature } from "modules/charts-shared/components/disposition/DispositionNurseSignature";
import { useVitals } from "modules/vitals/api";
import { format, isValid } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { USERID_CLAIMS } from "system-constants";
import { useGetUserDetails } from "api";
import { useUserRoles } from "contexts/UserRoles";
import { useDeleteOrderRange, usePrnOrdersPending } from "modules/orders/api";

const SECTION_CODE = "SDIS";

const DischargeVitalsInput = ({ value }: { value: InputProps["value"] }) => (
  <Input
    bg="white"
    borderColor={value ? "blue" : "gray.700"}
    color="blue"
    border="1px solid"
    type="text"
    autoComplete="off"
    value={value || ""}
    isDisabled
    textAlign="center"
  />
);

const DischargeVitalsContent = ({ encounterId }: { encounterId: string }) => {
  const { data: vitalsData, isLoading: isVitalsLoading } =
    useVitals(encounterId);

  const takenAt = React.useMemo(() => {
    const date = vitalsData?.data.currentVitalCheck?.takenAt;
    if (!!date && isValid(new Date(date)))
      return format(new Date(date), "LL/dd/yyyy HH:mm");
    return "";
  }, [vitalsData]);

  return (
    <>
      <ChartRow>
        <Card
          display="grid"
          gridTemplateColumns="160px 1.2fr .8fr .8fr 1.2fr 1.2fr .8fr .8fr .8fr 35px"
          bg="gray.250"
          boxShadow="none"
          height="2.5rem"
          alignItems="center"
          borderRadius="5px"
          padding="8px 16px"
        >
          <chakra.span color="gray.650" justifySelf="center">
            Date / Time
          </chakra.span>
          <chakra.span color="gray.650" justifySelf="center">
            BP
          </chakra.span>
          <chakra.span color="gray.650" justifySelf="center">
            Pulse
          </chakra.span>
          <chakra.span color="gray.650" justifySelf="center">
            RR
          </chakra.span>
          <chakra.span color="gray.650" justifySelf="center">
            Temp
          </chakra.span>
          <chakra.span color="gray.650" justifySelf="center">
            SpO2
          </chakra.span>
          <chakra.span color="gray.650" justifySelf="center">
            GCS
          </chakra.span>
          <chakra.span color="gray.650" justifySelf="center">
            Rythm
          </chakra.span>
          <chakra.span color="gray.650" justifySelf="center">
            Pain
          </chakra.span>
          <Icon
            as={EmrProfileComplete}
            fontSize="1.25rem"
            color="gray.650"
            justifySelf="center"
          />
        </Card>
      </ChartRow>
      {isVitalsLoading ? (
        <Loading />
      ) : (
        <ChartRow templateColumns="160px 1.2fr .8fr .8fr 1.2fr 1.2fr .8fr .8fr .8fr 40px">
          <DischargeVitalsInput value={takenAt} />
          <DischargeVitalsInput
            value={vitalsData?.data.currentVitalCheck?.bp}
          />
          <DischargeVitalsInput
            value={vitalsData?.data.currentVitalCheck?.pulse}
          />
          <DischargeVitalsInput
            value={vitalsData?.data.currentVitalCheck?.rr}
          />
          <DischargeVitalsInput
            value={vitalsData?.data.currentVitalCheck?.temp}
          />
          <DischargeVitalsInput
            value={vitalsData?.data.currentVitalCheck?.spO2}
          />
          <DischargeVitalsInput
            value={vitalsData?.data.currentVitalCheck?.gcs}
          />
          <DischargeVitalsInput value="" />
          <DischargeVitalsInput
            value={vitalsData?.data.currentVitalCheck?.painLevel}
          />
          <StaffAvatar
            size="xs"
            w="32px"
            h="32px"
            fontSize=".25rem"
            userName={
              vitalsData?.data.currentVitalCheck?.takenBy?.fullName ?? ""
            }
            profileUrl={
              vitalsData?.data.currentVitalCheck?.takenBy?.pictureUrl ?? ""
            }
            justifySelf="center"
          />
        </ChartRow>
      )}
    </>
  );
};

type PrnOrdersWarningProps = Pick<UseDisclosureReturn, "isOpen" | "onClose"> & {
  prnOrdersPending: string[];
  encounterId: string;
  next: () => void;
};

const PrnOrdersWarning = ({
  onClose,
  isOpen,
  prnOrdersPending,
  encounterId,
  next,
}: PrnOrdersWarningProps) => {
  const toast = useToast();

  const { mutateAsync: deleteOrder, isLoading } =
    useDeleteOrderRange(encounterId);

  const handleDelete = async () => {
    try {
      await deleteOrder({ orders: prnOrdersPending });
      toast({ description: formMessages.deleteSuccess("Orders") });
      next();
      onClose();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
      onClose();
    }
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="360px"
        width="360px"
        borderRadius="1rem"
        bg="#E5E5E5"
      >
        <ModalBody>
          <Box display="flex" flexDir="column" gap={4} py={4}>
            <Text fontWeight="600" fontSize="21px" textAlign="center">
              Warning!
            </Text>
            <Text fontWeight="400" fontSize="15px" textAlign="center">
              There are PRN Medications in Ordered Status that have not been
              Prepared.
            </Text>
            <Text fontWeight="400" fontSize="15px" textAlign="center">
              These PRN Medications will be deleted from the chart if you press
              Continue!
            </Text>
            <Button
              variant="outlineSquared"
              borderRadius="25px"
              width="320px"
              sx={{
                color: "gray.650",
                bgColor: "gray.250",
                borderColor: "gray.650",
              }}
              onClick={onClose}
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              borderRadius="25px"
              width="320px"
              isLoading={isLoading}
              onClick={handleDelete}
            >
              Continue
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function N07Disposition(props: SharedChartBaseProps) {
  const { chartCode, chartType, isReadOnly = false } = props;
  const toast = useToast();
  const { scope } = usePermissions();
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const mainRoute = window.location.hash;

  const { roles } = useUserRoles();

  const { user: currentUser } = useAuth0();
  const currentUserId = currentUser ? currentUser[USERID_CLAIMS] : "";

  const { data: userData } = useGetUserDetails(currentUserId);
  const { data: encounter } = useEncounterSideInfo(encounterId);

  const [warningDialogMessage, setWarningDialogMessage] = React.useState("");
  const [warningDialogSubMessage, setWarningDialogSubMessage] =
    React.useState("");
  const [warningDialogAction, setWarningDialogAction] =
    React.useState<() => void>();
  const [warningDialogCancelAction, setWarningDialogCancelAction] =
    React.useState<() => void>();

  const [warningDialogCancelLabel, setWarningDialogCancelLabel] =
    React.useState("Cancel");
  const [warningDialogActionLabel, setWarningDialogActionLabel] =
    React.useState("Ok");

  const { getIsLoading, onSubmit, updateIsLoading, useFormContext } =
    useChartDispositionForm({
      sectionId: SECTION_CODE,
      sectionLabel: "Disposition",
      chartCode,
    });

  const {
    getValues,
    formState: { isDirty },
    control,
    setValue,
  } = useFormContext;

  const signatures = getValues("signatures");
  const nurseSignature = signatures?.find((el) => el.type === "Nurse");

  const { insert: add } = useFieldArray({
    control,
    name: "signatures",
  });

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      await onSubmit();
      toast({ description: formMessages.updateSuccess("Disposition") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const isEmpty = () => {
    const values = getValues();
    return Object.keys(values).length === 0;
  };

  const {
    mutateAsync: updateEncounterDisposition,
    isLoading: isLoadingEncounterDispositionUpdate,
  } = useUpdateEncounterDisposition(encounterId, chartType);

  const { data: prnOrdersPendingData, isLoading: isPrnOrdersPendingLoading } =
    usePrnOrdersPending(encounterId);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const prnOrderWarningDisclosure = useDisclosure();

  const handleAddDispositionStatus = async () => {
    try {
      add(0, {
        type: "Nurse",
        userId: currentUserId,
        user: userData?.data,
        createdBy: currentUserId,
        signed: true,
      });

      onCloseWarningDialog();
      if (!encounter?.data?.nurseDisposition) {
        setValue(
          "subsections.Q005.T001xSDISQ005A064",
          new Date().toISOString()
        );
        await onSubmit();
      }

      await updateEncounterDisposition({ encounterId });
      toast({ description: formMessages.updateSuccess("Status") });
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const handleChangeDispositionStatus = async () => {
    try {
      onCloseWarningDialog();
      if (!encounter?.data?.nurseDisposition) {
        setValue(
          "subsections.Q005.T001xSDISQ005A064",
          new Date().toISOString()
        );
        await onSubmit();
      }

      await updateEncounterDisposition({ encounterId });
      toast({ description: formMessages.updateSuccess("Status") });
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };

  const onDispositionPatientAction = () => {
    setWarningDialogActionLabel("Ok");
    setWarningDialogCancelLabel("Cancel");
    setWarningDialogMessage(
      `This patient will be marked as ${
        !encounter?.data?.nurseDisposition
          ? "dispositioned"
          : "not dispositioned"
      } in the nurse charts`
    );
    setWarningDialogSubMessage("Are you sure you want to update this Patient?");

    setWarningDialogAction(() => handleChangeDispositionStatus);
    setWarningDialogCancelAction(() => onCloseWarningDialog);
    onOpen();
  };

  const onDispositionNoSignaturePatientAction = () => {
    setWarningDialogActionLabel("Add Signature");
    setWarningDialogCancelLabel("Ignore");
    setWarningDialogMessage(`Please add your signature`);
    setWarningDialogSubMessage(
      "This chart will go to the open charts until the signatures are added"
    );
    setWarningDialogAction(() => handleAddDispositionStatus);
    setWarningDialogCancelAction(() => handleChangeDispositionStatus);

    onOpen();
  };

  const onCloseWarningDialog = () => {
    setWarningDialogMessage("");
    setWarningDialogAction(undefined);
    setWarningDialogCancelAction(undefined);
    onClose();
  };

  const onRequestDisposition = (checkPrnOrders = true) => {
    if (checkPrnOrders && prnOrdersPendingData?.data.length) {
      return prnOrderWarningDisclosure.onOpen();
    }

    if (
      (!nurseSignature || !nurseSignature?.signed) &&
      !encounter?.data?.nurseDisposition &&
      roles.some((role) => ["Nurse", "ChargeNurse"].includes(role))
    ) {
      onDispositionNoSignaturePatientAction();
    } else {
      onDispositionPatientAction();
    }
  };

  if (getIsLoading || isEmpty()) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <Box display="flex" flexDirection="row" width="100%">
        <Box width="60vw" padding="4">
          <ChartForm
            onSubmit={onSubmit}
            onRouteChangeSave={onRouteChangeSave}
            isDirty={isDirty}
            isPromptSaveLoading={updateIsLoading}
            disabled={isReadOnly}
          >
            <SharedChartSectionHeader
              icon={<Icon as={EmrSignature} />}
              showActionsBtns
              isLoading={updateIsLoading}
              showNotesBtn={chartType === "Triage" ? false : undefined}
              encounterId={encounterId}
              sectionId={SECTION_CODE}
            >
              Disposition {isReadOnly ? "(Read Only)" : ""}
            </SharedChartSectionHeader>
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q001"
                  subsectionLabel="Intake / Output"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                />
              }
              content={
                <ChartSubsectionContent>
                  <ChartRow>
                    <ChartRowElement
                      type="option"
                      label="Intake:"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                      name="subsections.Q001.T001xSDISQ001A001"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A002"
                      width="12.4rem"
                    />
                    <ChartRowElement
                      type="option"
                      label="Output:"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                      name="subsections.Q001.T001xSDISQ001A003"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A004"
                      width="12.4rem"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A005"
                    />
                  </ChartRow>
                  <ChartRow>
                    <ChartRowElement
                      type="option"
                      label="IV discotinued"
                      name="subsections.Q001.T001xSDISQ001A006"
                    />
                    <ChartRowElement
                      type="option"
                      label="Lock discontinued"
                      name="subsections.Q001.T001xSDISQ001A007"
                    />
                    <ChartRowElement
                      type="option"
                      label="cath intact"
                      name="subsections.Q001.T001xSDISQ001A008"
                    />
                    <ChartRowElement
                      type="option"
                      label="no swelling"
                      name="subsections.Q001.T001xSDISQ001A009"
                    />
                    <ChartRowElement
                      type="option"
                      label="no redness"
                      name="subsections.Q001.T001xSDISQ001A010"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A011"
                    />
                  </ChartRow>
                  <ChartRow>
                    <ChartRowElement
                      type="option"
                      label="Time:"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                      name="subsections.Q001.T001xSDISQ001A012"
                    />
                    <ChartRowElement
                      type="date"
                      name="subsections.Q001.T001xSDISQ001A013"
                      width="12.4rem"
                    />
                    <ChartRowElement
                      type="option"
                      label="Initials:"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                      name="subsections.Q001.T001xSDISQ001A014"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A015"
                      width="6.4rem"
                    />
                    <ChartRowElement
                      type="option"
                      label="IV "
                      name="subsections.Q001.T001xSDISQ001A016"
                    />
                    <ChartRowElement
                      type="option"
                      label="lock to floor"
                      name="subsections.Q001.T001xSDISQ001A017"
                    />
                    <ChartRowElement
                      type="option"
                      label="Amount Remaining:"
                      name="subsections.Q001.T001xSDISQ001A018"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A019"
                    />
                  </ChartRow>
                  <ChartRow>
                    <ChartRowElement
                      type="input"
                      name="subsections.Q001.T001xSDISQ001A020"
                    />
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q002"
                  subsectionLabel="Property"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                >
                  Property
                </SharedChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent>
                  <ChartRow>
                    <ChartRowElement
                      type="option"
                      label="Patient property given to:"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                      name="subsections.Q002.T001xSDISQ002A001"
                    />
                    <ChartRowElement
                      type="option"
                      label="patient"
                      name="subsections.Q002.T001xSDISQ002A002"
                    />
                    <ChartRowElement
                      type="option"
                      label="family"
                      name="subsections.Q002.T001xSDISQ002A003"
                    />
                    <ChartRowElement
                      type="option"
                      label="security"
                      name="subsections.Q002.T001xSDISQ002A004"
                    />
                    <ChartRowElement
                      type="option"
                      label="see patient belongings list"
                      name="subsections.Q002.T001xSDISQ002A005"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q002.T001xSDISQ002A006"
                    />
                  </ChartRow>
                  <ChartRow>
                    <ChartRowElement
                      type="input"
                      name="subsections.Q002.T001xSDISQ002A007"
                    />
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q003"
                  subsectionLabel="Discharge Vitals"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                >
                  Discharge Vitals
                </SharedChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent>
                  <DischargeVitalsContent encounterId={encounterId} />
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q004"
                  subsectionLabel="Condition"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                >
                  Condition
                </SharedChartSubsectionHeader>
              }
              content={
                <ChartSubsectionContent>
                  <ChartRow>
                    <ChartRowElement
                      type="option"
                      label="unchanged"
                      name="subsections.Q004.T001xSDISQ004A001"
                    />
                    <ChartRowElement
                      type="option"
                      label="improved"
                      name="subsections.Q004.T001xSDISQ004A002"
                    />
                    <ChartRowElement
                      type="option"
                      label="stable"
                      name="subsections.Q004.T001xSDISQ004A003"
                    />
                    <ChartRowElement
                      type="option"
                      label="other"
                      name="subsections.Q004.T001xSDISQ004A004"
                    />
                    <ChartRowElement
                      type="input"
                      name="subsections.Q004.T001xSDISQ004A005"
                    />
                  </ChartRow>
                </ChartSubsectionContent>
              }
            />
            <ChartSubsection
              header={
                <SharedChartSubsectionHeader
                  chartCode={chartCode}
                  encounterId={encounterId}
                  sectionCode={SECTION_CODE}
                  subsectionCode="Q005"
                  subsectionLabel="Disposition"
                  showNotesBtn={chartType === "Triage" ? false : undefined}
                  showAuditsBtn={
                    chartType === "Chart" || chartType === "Triage"
                  }
                >
                  Disposition
                </SharedChartSubsectionHeader>
              }
              content={
                <>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="DISCHARGED:"
                        name="subsections.Q005.T001xSDISQ005A001"
                      />
                      <ChartRowElement
                        type="option"
                        label="home"
                        name="subsections.Q005.T001xSDISQ005A002"
                      />
                      <ChartRowElement
                        type="option"
                        label="nursing home"
                        name="subsections.Q005.T001xSDISQ005A003"
                      />
                      <ChartRowElement
                        type="option"
                        label="police"
                        name="subsections.Q005.T001xSDISQ005A004"
                      />
                      <ChartRowElement
                        type="option"
                        label="medical examiner"
                        name="subsections.Q005.T001xSDISQ005A005"
                      />
                      <ChartRowElement
                        type="option"
                        label="funeral home"
                        name="subsections.Q005.T001xSDISQ005A006"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A007"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="instructions:"
                        fontStyle="italic"
                        name="subsections.Q005.T001xSDISQ005A008"
                      />
                      <ChartRowElement
                        type="option"
                        label="verbal"
                        name="subsections.Q005.T001xSDISQ005A009"
                      />
                      <ChartRowElement
                        type="option"
                        label="written"
                        name="subsections.Q005.T001xSDISQ005A010"
                      />

                      <ChartRowElement
                        type="option"
                        label="to:"
                        fontStyle="italic"
                        name="subsections.Q005.T001xSDISQ005A011"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A012"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="Rx given:"
                        fontStyle="italic"
                        name="subsections.Q005.T001xSDISQ005A013"
                      />
                      <ChartRowElement
                        type="option"
                        label="one"
                        name="subsections.Q005.T001xSDISQ005A014"
                      />
                      <ChartRowElement
                        type="option"
                        label="multi"
                        name="subsections.Q005.T001xSDISQ005A015"
                      />
                      <ChartRowElement
                        type="option"
                        label="to:"
                        fontStyle="italic"
                        name="subsections.Q005.T001xSDISQ005A016"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A017"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="verbalized understanding"
                        name="subsections.Q005.T001xSDISQ005A018"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A019"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="accompanied by:"
                        fontStyle="italic"
                        name="subsections.Q005.T001xSDISQ005A020"
                      />
                      <ChartRowElement
                        type="option"
                        label="driver"
                        name="subsections.Q005.T001xSDISQ005A021"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A022"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="notified:"
                        fontStyle="italic"
                        name="subsections.Q005.T001xSDISQ005A023"
                      />
                      <ChartRowElement
                        type="option"
                        label="family"
                        name="subsections.Q005.T001xSDISQ005A024"
                      />
                      <ChartRowElement
                        type="option"
                        label="police"
                        name="subsections.Q005.T001xSDISQ005A025"
                      />
                      <ChartRowElement
                        type="option"
                        label="medical examiner"
                        name="subsections.Q005.T001xSDISQ005A026"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A027"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A028"
                      />
                    </ChartRow>
                  </ChartSubsectionContent>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="ADMITTED TO:"
                        name="subsections.Q005.T001xSDISQ005A029"
                        fontStyle="italic"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A030"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="TRANSFERRED TO:"
                        name="subsections.Q005.T001xSDISQ005A031"
                        fontStyle="italic"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A032"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="report to:"
                        name="subsections.Q005.T001xSDISQ005A033"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A034"
                        width="36.4rem"
                      />
                      <ChartRowElement
                        type="option"
                        label="date & time:"
                        fontStyle="italic"
                        textDecor="underline"
                        name="subsections.Q005.T001xSDISQ005A035"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A036"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="advance directive"
                        fontStyle="italic"
                        textDecor="underline"
                        name="subsections.Q005.T001xSDISQ005A037"
                      />
                      <ChartRowElement
                        type="option"
                        label="unavailable"
                        name="subsections.Q005.T001xSDISQ005A038"
                      />
                      <ChartRowElement
                        type="option"
                        label="added to chart"
                        name="subsections.Q005.T001xSDISQ005A039"
                      />
                      <ChartRowElement
                        type="option"
                        label="discussed w/ pt family"
                        name="subsections.Q005.T001xSDISQ005A040"
                      />
                      <ChartRowElement
                        type="option"
                        label="instructed to bring copy"
                        name="subsections.Q005.T001xSDISQ005A041"
                      />
                      <ChartRowElement
                        type="option"
                        label="information provided"
                        name="subsections.Q005.T001xSDISQ005A042"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="transfer documentation completed"
                        name="subsections.Q005.T001xSDISQ005A043"
                      />
                      <ChartRowElement
                        type="option"
                        label="see transfer of care template"
                        name="subsections.Q005.T001xSDISQ005A044"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A045"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A046"
                      />
                    </ChartRow>
                  </ChartSubsectionContent>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="left AMA"
                        name="subsections.Q005.T001xSDISQ005A047"
                      />
                      <ChartRowElement
                        type="option"
                        label="LWBS"
                        name="subsections.Q005.T001xSDISQ005A048"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A049"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="signed AMA sheet"
                        name="subsections.Q005.T001xSDISQ005A050"
                      />
                      <ChartRowElement
                        type="option"
                        label="refused"
                        name="subsections.Q005.T001xSDISQ005A051"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A052"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="physician notified:"
                        name="subsections.Q005.T001xSDISQ005A053"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A054"
                      />
                    </ChartRow>
                    <ChartRow>
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A055"
                      />
                    </ChartRow>
                  </ChartSubsectionContent>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="mode:"
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                        name="subsections.Q005.T001xSDISQ005A056"
                      />
                      <ChartRowElement
                        type="option"
                        label="walk"
                        name="subsections.Q005.T001xSDISQ005A057"
                      />
                      <ChartRowElement
                        type="option"
                        label="crutches"
                        name="subsections.Q005.T001xSDISQ005A058"
                      />
                      <ChartRowElement
                        type="option"
                        label="W/C"
                        name="subsections.Q005.T001xSDISQ005A059"
                      />
                      <ChartRowElement
                        type="option"
                        label="stretcher"
                        name="subsections.Q005.T001xSDISQ005A060"
                      />
                      <ChartRowElement
                        type="option"
                        label="ambulance"
                        name="subsections.Q005.T001xSDISQ005A061"
                      />
                      <ChartRowElement
                        type="input"
                        name="subsections.Q005.T001xSDISQ005A062"
                      />
                    </ChartRow>
                  </ChartSubsectionContent>
                  <ChartSubsectionContent>
                    <ChartRow>
                      <ChartRowElement
                        type="option"
                        label="Depart date & time:"
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                        name="subsections.Q005.T001xSDISQ005A063"
                      />
                      <ChartRowElement
                        type="date"
                        name="subsections.Q005.T001xSDISQ005A064"
                        size="xl"
                      />
                      <ChartRowElement type="empty" />
                    </ChartRow>
                  </ChartSubsectionContent>
                </>
              }
            />

            <DispositionNurseSignature
              encounterId={encounterId}
              chartCode={chartCode}
              sectionCode={SECTION_CODE}
              chartType={chartType}
              control={control}
              signatures={signatures}
            />

            <Flex justifyContent="center" width="100%" pt={8} pb={16} gap={30}>
              <Button
                variant="outlineSquared"
                borderRadius="25px"
                width="320px"
                isDisabled={
                  isLoadingEncounterDispositionUpdate ||
                  (!scope("nurse:reqdisp").isAccessible &&
                    !encounter?.data?.nurseDisposition) ||
                  isPrnOrdersPendingLoading
                }
                onClick={() => onRequestDisposition(true)}
                isLoading={
                  isLoadingEncounterDispositionUpdate ||
                  isPrnOrdersPendingLoading
                }
              >
                {!encounter?.data?.nurseDisposition
                  ? "Request Disposition"
                  : "Undo Disposition"}
              </Button>

              <PrnOrdersWarning
                encounterId={encounterId}
                {...prnOrderWarningDisclosure}
                isOpen={prnOrderWarningDisclosure.isOpen}
                prnOrdersPending={prnOrdersPendingData?.data ?? []}
                next={() => onRequestDisposition(false)}
              />

              <WarningDialog
                isOpen={isOpen}
                onClose={onCloseWarningDialog}
                title="Warning"
                mainText={warningDialogMessage}
                secondaryText={warningDialogSubMessage}
                onCancel={() =>
                  warningDialogCancelAction && warningDialogCancelAction()
                }
                onAction={() => warningDialogAction && warningDialogAction()}
                cancelLabel={warningDialogCancelLabel}
                actionLabel={warningDialogActionLabel}
                blockScrollOnMount={false}
                cancelButtonProps={{ color: "red" }}
                actionButtonProps={{ color: "blue" }}
                isActionLoading={
                  isLoadingEncounterDispositionUpdate || updateIsLoading
                }
              />
            </Flex>
          </ChartForm>
        </Box>

        {!mainRoute.includes("summary") && (
          <SidebarHardStops
            encounterId={encounterId}
            chartCode={chartCode}
            chartType={chartType}
            patientDispositioned={!!encounter?.data?.nurseDisposition}
          />
        )}
      </Box>
    </FormProvider>
  );
}

export { N07Disposition };
