import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { SignedSection } from "./useCreateSignedSection";

type DeleteSignedSectionPayload = {
  type: SignedSection;
};

function useDeleteSignedSection(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteSignedSection", { encounterId }],
    ({ type }: DeleteSignedSectionPayload) =>
      axiosChartInstance.delete(
        `v1/sectionSummary/${encounterId}/signed-section/${type}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["signedSectionsFiles", { encounterId }]);
        queryClient.invalidateQueries(["getSignedSection", { encounterId }]);
      },
    }
  );
}

export { useDeleteSignedSection };
