import {
  Badge,
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { OrderFormElementRoute, OrderStatus } from "enums";
import { TriageIVMedicationSection } from "modules/charts-shared";
import { ORDER_STATUS_COLOR } from "modules/orders";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Card, WarningDialog } from "shared";
import { MedsAndIvModal } from "./MedsAndIvModals";
import { toDisplay } from "utils/enum-mapper";
import { SiPlus } from "@medstonetech/slate-icons";
import { useFormContext } from "react-hook-form";

type TriageIVMedicationSectionIndexed = TriageIVMedicationSection & {
  index: number;
  type: string;
};

type IvAndMedicationsCardProps = {
  item: TriageIVMedicationSectionIndexed;
  onSave: () => void;
  isReadOnly?: boolean;
};

type IvAndMedicationsTimerProps = {
  type: string;
  item: TriageIVMedicationSectionIndexed;
  onSave: () => void;
};

function IvAndMedicationsTimer(props: IvAndMedicationsTimerProps) {
  const { item, type, onSave } = props;
  const { setValue } = useFormContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [timerMs, setTimerMs] = useState<number>(0);
  const [timerDate, setTimerDate] = useState<Date | null>(null);
  const [timeDifference, setTimeDifference] = useState<number>(0);

  useEffect(() => {
    if (!item.order?.scheduledTime) {
      return;
    }

    const interval = setInterval(() => {
      const now = new Date();
      const date = new Date(item.order?.scheduledTime as Date);
      const difference = date.getTime() - now.getTime();

      setTimerDate(date);
      setTimeDifference(difference);
      setTimerMs(difference > 0 ? difference : 0);
    }, 1000);

    return () => clearInterval(interval);
  }, [item.order]);

  const timerVal = useMemo(
    () => new Date(timerMs).toISOString().substr(11, 5),
    [timerMs]
  );

  return (
    <Box
      sx={{
        gap: 4,
        display: "flex",
      }}
    >
      <Badge
        bg={timeDifference > 0 ? "red" : "green"}
        color="white"
        borderRadius="20px"
        padding="0.125rem 0.5rem"
      >
        {timerDate && timerMs > 0 && <Text fontSize="xs">{timerVal}</Text>}
      </Badge>

      <IconButton
        aria-label="Add IV/Medication"
        icon={
          <Icon
            borderRadius="1rem"
            color="white"
            backgroundColor="green"
            as={SiPlus}
          />
        }
        color="blue"
        variant="icon"
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
        isDisabled={timeDifference > 0}
      />

      <WarningDialog
        isOpen={isOpen}
        mainText="Are you sure you want to mark this order as ready?"
        title="Warning!"
        actionLabel="Ready"
        onCancel={onClose}
        onAction={() => {
          setValue(`${type}[${item.index}].status`, "Ordered");
          setValue(`${type}[${item.index}].scheduled`, "Ordered");

          onClose();
          onSave();
        }}
        onClose={onClose}
      />
    </Box>
  );
}

function IvAndMedicationsCard({
  item,
  onSave,
  isReadOnly,
}: IvAndMedicationsCardProps) {
  const disclosure = useDisclosure();
  const borderColor = item.status
    ? ORDER_STATUS_COLOR[item.status as OrderStatus]
    : "gray.700";

  const title =
    item.route === "IV"
      ? "IV/Medication Infusion"
      : item.route === "IVP" || item.route === "IVPB"
      ? "IV Push"
      : "Medication";

  return (
    <Card
      w="100%"
      p="10px 5px"
      variant="basic"
      borderRight="8px solid"
      borderColor={borderColor}
      onClick={() => disclosure.onOpen()}
      cursor="pointer"
    >
      <Flex justify="space-between" mb={2}>
        <Text color="gray">
          {`${toDisplay(
            "orderFormElementRoute",
            (item.route as OrderFormElementRoute) ?? "PO"
          )} ${item.order?.isPRN ? " - PRN" : ""}`}
        </Text>
        <Text color="gray"></Text>

        {(item.status === "Ordered" || item.status === "Prepared") && (
          <>
            {item.scheduled === "Ordered" && (
              <Badge
                textTransform="capitalize"
                py=".15rem"
                px=".5rem"
                bg={item.status === "Ordered" ? "blue" : "cyan"}
                color="white"
                borderRadius="20px"
              >
                {item.status === "Ordered" ? "Needs Prep" : "Preparing"}
              </Badge>
            )}
            {item.scheduled === "Scheduled" && (
              <IvAndMedicationsTimer
                item={item}
                type={item.type}
                onSave={onSave}
              />
            )}
          </>
        )}
        {item.status === "PRN" && (
          <IvAndMedicationsTimer item={item} type={item.type} onSave={onSave} />
        )}
      </Flex>
      <Text fontWeight="bold">{`${(item.medication as string) ?? ""} ${(
        (item.solution as string) ?? ""
      ).replace(item.route as string, "")}`}</Text>

      {disclosure.isOpen && (
        <MedsAndIvModal
          {...disclosure}
          type={item.type}
          item={item}
          title={title}
          onSave={onSave}
          isReadOnly={isReadOnly}
        />
      )}
    </Card>
  );
}

type IvAndMedicationsColumnProps = {
  header: ReactNode;
  status: OrderStatus;
  items: TriageIVMedicationSectionIndexed[];
  onSave: () => void;
  isReadOnly?: boolean;
};

type IvAndMedicationsContentProps = Omit<IvAndMedicationsColumnProps, "header">;

type TriageIVMedicationSectionGrouped = {
  ordered: TriageIVMedicationSectionIndexed[];
  preparing: TriageIVMedicationSectionIndexed[];
  scheduled: TriageIVMedicationSectionIndexed[];
  prn: TriageIVMedicationSectionIndexed[];
};

function IvAndMedicationsContent({
  status,
  items,
  onSave,
  isReadOnly,
}: IvAndMedicationsContentProps) {
  const groupedItems: TriageIVMedicationSectionGrouped =
    items.reduce<TriageIVMedicationSectionGrouped>(
      (acc, item) => {
        if (item.status === "PRN") {
          acc.prn.push(item);
        } else if (
          item.scheduled === "Scheduled" &&
          item.order?.scheduledTime
        ) {
          acc.scheduled.push(item);
        } else if (item.status === "Ordered") {
          acc.ordered.push(item);
        } else {
          acc.preparing.push(item);
        }
        return acc;
      },
      {
        ordered: [],
        preparing: [],
        scheduled: [],
        prn: [],
      }
    );

  return (
    <>
      {status === "Ordered"
        ? Object.keys(groupedItems).map((key) => {
            const itemList =
              groupedItems[key as keyof TriageIVMedicationSectionGrouped];

            return itemList.length > 0 ? (
              <Box
                key={key}
                sx={{
                  gap: 4,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    gap: 2,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text>
                    {
                      {
                        ordered: "Needs Prep",
                        preparing: "Preparing",
                        scheduled: "Scheduled",
                        prn: "PRN",
                      }[key]
                    }
                  </Text>
                  <Divider />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  {itemList.map((item) => (
                    <IvAndMedicationsCard
                      key={item.index}
                      item={item}
                      onSave={onSave}
                      isReadOnly={isReadOnly}
                    />
                  ))}
                </Box>
              </Box>
            ) : null;
          })
        : items.map((item) => (
            <IvAndMedicationsCard
              key={item.index}
              item={item}
              onSave={onSave}
              isReadOnly={isReadOnly}
            />
          ))}
    </>
  );
}

function IvAndMedicationsColumn({
  status,
  header,
  items,
  onSave,
  isReadOnly,
}: IvAndMedicationsColumnProps) {
  return (
    <Box minW="24%" display="flex" flexDir="column" gap={4} px={2}>
      {header}

      <IvAndMedicationsContent
        status={status}
        items={items}
        onSave={onSave}
        isReadOnly={isReadOnly}
      />
    </Box>
  );
}

export { IvAndMedicationsColumn };
