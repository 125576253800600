import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartType } from "types";
import { ChartSectionIndicatorDto } from "./useChartSidebarSectionIndicator";

type ChartSidebarIndicatorsProps = {
  encounterId: string;
  chartType: ChartType;
  sectionCode: string;
  enabled: boolean;
};

function useChartSidebarSharedSectionIndicator({
  encounterId,
  chartType,
  sectionCode,
  enabled,
}: ChartSidebarIndicatorsProps) {
  return useAxiosQuery(
    ["chartSharedSectionIndicator", { encounterId, chartType, sectionCode }],
    () =>
      axiosChartInstance.get<ChartSectionIndicatorDto>(
        `v1/chart-application/${encounterId}/shared/${chartType}/${sectionCode}/indicator`
      ),
    { enabled }
  );
}

export { useChartSidebarSharedSectionIndicator };
