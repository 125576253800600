import { AxiosError } from "axios";
import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { useHandleUnauthorizedError } from "./useHandleUnauthorizedError";

type ResetTimerFunction = () => void;

let globalResetTimer: ResetTimerFunction | null = null;

export const setGlobalResetTimer = (resetFn: ResetTimerFunction) => {
  globalResetTimer = resetFn;
};

function useAxiosQuery<Response>(
  queryKey: QueryKey,
  queryFn: QueryFunction<Response, QueryKey>,
  options?: UseQueryOptions<Response, AxiosError, Response, QueryKey>
) {
  const queryResult = useQuery(queryKey, queryFn, {
    ...options,
    onSettled: (...args) => {
      if (globalResetTimer) {
        globalResetTimer();
      }
      options?.onSettled?.(...args);
    },
  });

  useHandleUnauthorizedError(queryResult.error?.response?.status);

  return queryResult;
}

export { useAxiosQuery };
