import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type useChangeOrderObservationPayload = {
  isObservation: boolean;
};

function useChangeOrderObservation(orderId: string, encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["updateOrderObservation", { orderId, encounterId }],
    (payload: useChangeOrderObservationPayload) =>
      axiosOrderInstance.put(`v1/${orderId}/observation`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries(["orderHistory", { encounterId }]);
        queryClient.invalidateQueries([
          "orderHistoryPagination",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "orderHistoryInfinite",
          { encounterId },
        ]);
      },
    }
  );
}

export { useChangeOrderObservation };
