import { PaginationPayload, PaginationResponse } from "types";
import { useQuery } from "react-query";
import { axiosIdentityInstance } from "config";

type SearchTeamMembersElement = {
  id: string;
  name: string;
  username: string;
  imageUrl: string;
  title: string;
  email: string;
  phone: string;
  teams: number;
  lastLogin: Date;
};

type SearchTeamMembersResponse = PaginationResponse<SearchTeamMembersElement>;

type TeamMembersFilter = Partial<{
  team: string;
  role: string;
  search: string;
  firstName: string;
  lastName: string;
  username: string;
}>;

type UseSearchTeamMembersProps = { teamId: string } & TeamMembersFilter &
  PaginationPayload;

function useSearchTeamMembers(
  props: UseSearchTeamMembersProps,
  enabled = true
) {
  const { teamId, sort, descending, page = 1, size = 10, ...filters } = props;
  const validFilters = Object.entries(filters).reduce((curr, [k, v]) => {
    if (v) {
      return { ...curr, [k]: v };
    }
    return curr;
  }, {});
  const { search } = filters;
  return useQuery(
    ["searchTeamMembers", { page, ...filters, teamId }],
    () =>
      axiosIdentityInstance.get<SearchTeamMembersResponse>(
        `/v1/team/${teamId}/members`,
        {
          params: {
            page,
            pageSize: size,
            sortDesc: descending || false,
            orderBy: sort,
            search: filters.search || "",
            ...validFilters,
          },
        }
      ),
    { keepPreviousData: true, staleTime: search ? 0 : 1 * 60 * 1000, enabled }
  );
}

export { useSearchTeamMembers };
export type {
  UseSearchTeamMembersProps,
  TeamMembersFilter,
  SearchTeamMembersElement,
};
