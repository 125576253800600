import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFormProcedurePayload } from "modules";
import { useQueryClient } from "react-query";

type UseChartApplicationProcedureParams = {
  encounterId: string;
  chartCode: string;
};

function useChartApplicationProcedure(
  params: UseChartApplicationProcedureParams
) {
  const { encounterId, chartCode } = params;
  const queryClient = useQueryClient();
  return useAxiosQuery(
    ["chartApplicationProcedure", { encounterId, chartCode }],
    () =>
      axiosChartInstance.get<ChartFormProcedurePayload>(
        `v1/chart-application/${encounterId}/${chartCode}/procedure-group`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "chartSidebarIndicators",
          { encounterId, chartCode },
        ]);
        queryClient.invalidateQueries([
          "chartSharedSectionIndicator",
          { encounterId, chartCode, sectionCode: "SPRO2" },
        ]);
      },
    }
  );
}

export type { UseChartApplicationProcedureParams };
export { useChartApplicationProcedure };
