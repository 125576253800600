import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseGetScopesResponse = {
  id: string;
  name: string;
};

function useGetScopes() {
  return useAxiosQuery(
    ["getScopes"],
    () => axiosIdentityInstance.get<UseGetScopesResponse[]>("/v1/scope/all"),
    { staleTime: 1000 * 60 * 10 }
  );
}

export { useGetScopes };
