import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  BoxProps,
  HStack,
  Icon,
  Text,
  chakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Divider,
  Flex,
  Image,
} from "@chakra-ui/react";
import { EmrForm, EmrProfileComplete } from "@medstonetech/slate-icons";
import { useGetUser } from "api";
import { format } from "date-fns";
import { ChartRowElement } from "modules/charts-shared";
import { useFormContext } from "react-hook-form";
import { Button, Card, Input, StaffAvatar, UserAvatar } from "shared";
import { USERID_CLAIMS } from "system-constants";
import { ToggleMedicationStatus } from "./ToggleMedicationStatus";
import { AddRounded } from "icons";
import { useGetUserDetails } from "api";

type TreatmentInstructionsModalProps = {
  title: string;
  content: string;
  acknowledge: string;
  date: string;
  isOpen: boolean;
  onClose: () => void;
  setAcknowledge: (userId: string) => void;
};

function TreatmentInstructionsModal({
  isOpen,
  onClose,
  title,
  content,
  acknowledge,
  date,
  setAcknowledge,
}: TreatmentInstructionsModalProps) {
  const { user: currentUser } = useAuth0();
  const userId = currentUser ? currentUser[USERID_CLAIMS] : "";

  const { data: userData } = useGetUser(acknowledge, {
    enabled: Boolean(acknowledge),
  });

  const boldText = (text: string) => {
    const parts = text.split(/(\*\*.*?\*\*)/);
    return (
      <>
        {parts.map((part, index) => {
          if (part.startsWith("**") && part.endsWith("**")) {
            return <b key={index}>{part.slice(2, -2)}</b>;
          }
          return part;
        })}
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        minH="400px"
        bgColor="gray.200"
        w="540px"
        maxWidth="540px"
        maxH="90%"
      >
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          fontSize="17px"
          fontWeight="600"
        >
          <Box w="41px" />
          {title}
          <Button variant="label" sx={{ color: "blue" }} onClick={onClose}>
            Close
          </Button>
        </ModalHeader>
        <Divider />
        <ModalBody maxH="90%" overflow="auto">
          <chakra.span sx={{ whiteSpace: "pre-wrap" }}>
            {boldText(content)}
          </chakra.span>
        </ModalBody>

        <ModalFooter display="flex" justifyContent="space-between">
          {acknowledge ? (
            <Box
              p={4}
              bgColor="white"
              border="1px solid"
              borderColor="gray.450"
              w="100%"
              borderRadius="10px"
              h="104px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={2}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <chakra.span color="gray.700">Acknowledged by</chakra.span>
                <Flex alignItems="center">
                  {userData?.data && (
                    <>
                      <UserAvatar
                        mr="11px"
                        w="32px"
                        h="32px"
                        picture={userData.data.pictureUrl}
                        name={userData.data.firstName}
                      />
                      <Box>
                        <Text
                          fontWeight="bold"
                          fontSize="1.063rem"
                          lineHeight="1.063rem"
                        >
                          {`${userData.data.firstName} ${userData.data.lastName}`}
                        </Text>
                        <Text
                          fontSize="0.875rem"
                          lineHeight="0.875rem"
                          color="gray"
                          mt="2px"
                        >
                          {userData.data.teams
                            .map((team) => team.name)
                            .join(", ")}
                        </Text>
                      </Box>
                    </>
                  )}
                </Flex>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <chakra.span color="gray.700">Time Acknowledged</chakra.span>
                <chakra.span>
                  {format(new Date(date), "MM/dd/yyyy hh:mm")}
                </chakra.span>
              </Box>
            </Box>
          ) : (
            <>
              <Button
                variant="outlineSquared"
                borderRadius="25px"
                w="235px"
                sx={{ color: "red", borderColor: "red" }}
                onClick={onClose}
              >
                Ignore
              </Button>
              <Button
                variant="outlineSquared"
                borderRadius="25px"
                w="235px"
                sx={{ color: "blue", borderColor: "blue" }}
                onClick={() => {
                  setAcknowledge(userId);
                  onClose();
                }}
              >
                Acknowledge
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

type TreatmentsCardProps = {
  pictureUrl: string;
  index: number;
  isReadOnly?: boolean;
};

const GRID_PROPS: BoxProps = {
  padding: "1rem 1.5rem",
  display: "grid",
  gridColumnGap: "1rem",
  alignItems: "center",
};

function TreatmentsCardHeader() {
  return (
    <Card
      marginBottom="1rem"
      bg="gray.250"
      boxShadow="none"
      textAlign="center"
      {...GRID_PROPS}
      gridTemplateColumns="5fr 15fr 15fr 15fr 25fr 10fr 12fr"
    >
      <chakra.span>
        <Icon
          as={EmrProfileComplete}
          w="1.25rem"
          h="1.25rem"
          color="gray.650"
        />
      </chakra.span>
      <chakra.span>Date / Time</chakra.span>
      <chakra.span>Description</chakra.span>
      <chakra.span>Order Notes</chakra.span>
      <chakra.span />
      <chakra.span>Instructions</chakra.span>
      <chakra.span>Status</chakra.span>
    </Card>
  );
}

function TreatmentsCard({ pictureUrl, index }: TreatmentsCardProps) {
  const disclosure = useDisclosure();
  const { user: currentUser } = useAuth0();

  const { watch, setValue } = useFormContext();

  const treatment = watch(`treatments[${index}].treatment`);
  const notes = watch(`treatments[${index}].notes`);
  const instructions = watch(`treatments[${index}].instructions`);
  const acknowledge = watch(`treatments[${index}].acknowledge`);
  const acknowledgeDate = watch(`treatments[${index}].acknowledgeDate`);
  const userId = watch(`treatments[${index}].userId`);

  const { data: userDetails } = useGetUserDetails(userId);

  const setAcknowledge = React.useCallback(
    (acknowledgeUserId: string) => {
      setValue(`treatments[${index}].acknowledge`, acknowledgeUserId, {
        shouldDirty: true,
      });
      setValue(`treatments[${index}].acknowledgeDate`, new Date(), {
        shouldDirty: true,
      });
    },
    [setValue, index]
  );

  const handleChangeUpdatedBy = React.useCallback(() => {
    setValue(`treatments[${index}].userId`, currentUser?.[USERID_CLAIMS], {
      shouldDirty: true,
    });
  }, [currentUser, index, setValue]);

  return (
    <Card boxShadow="none">
      <Box
        {...GRID_PROPS}
        gridTemplateColumns="5fr 15fr 15fr 15fr 25fr 10fr 12fr"
      >
        <StaffAvatar
          profileUrl={pictureUrl || userDetails?.data?.pictureUrl || ""}
          size="xs"
          justifySelf="center"
          userName={userDetails?.data?.fullName || ""}
        />
        <chakra.span>
          <ChartRowElement
            type="date"
            name={`treatments[${index}].T001xSIVMQ001A001`}
            onClick={handleChangeUpdatedBy}
          />
        </chakra.span>
        <chakra.span textAlign="center">
          <Text>{treatment}</Text>
        </chakra.span>
        <chakra.span textAlign="center">
          <Text>{notes}</Text>
        </chakra.span>
        <chakra.span />
        <chakra.span textAlign="center">
          {instructions && (
            <Icon
              as={EmrForm}
              fontSize="1.5rem"
              color={Boolean(acknowledge) ? "green" : "red"}
              cursor="pointer"
              onClick={disclosure.onOpen}
            />
          )}
        </chakra.span>
        <ToggleMedicationStatus name={`treatments[${index}].status`} />
      </Box>
      <Box
        {...GRID_PROPS}
        gridTemplateColumns="3fr 15fr 15fr 15fr 15fr 15fr 10fr"
      >
        <chakra.span>Comments:</chakra.span>
        <chakra.span gridColumn="span 6">
          <HStack>
            <ChartRowElement
              type="input"
              name={`treatments[${index}].T001xSIVMQ001A002`}
            />
          </HStack>
        </chakra.span>
      </Box>
      {disclosure.isOpen && (
        <TreatmentInstructionsModal
          title={treatment}
          content={instructions}
          acknowledge={acknowledge}
          date={acknowledgeDate}
          setAcknowledge={setAcknowledge}
          {...disclosure}
        />
      )}
    </Card>
  );
}

function TreatmentsIvStartsCardHeader() {
  return (
    <Card
      marginBottom="1rem"
      bg="gray.250"
      boxShadow="none"
      textAlign="center"
      {...GRID_PROPS}
      gridTemplateColumns="5fr 15fr 15fr 5fr 5fr 5fr 5fr 20fr 12fr"
    >
      <chakra.span>
        <Icon
          as={EmrProfileComplete}
          w="1.25rem"
          h="1.25rem"
          color="gray.650"
        />
      </chakra.span>
      <chakra.span>Date / Time</chakra.span>
      <chakra.span>Description</chakra.span>
      <chakra.span>#</chakra.span>
      <chakra.span>Site</chakra.span>
      <chakra.span>Ga</chakra.span>
      <chakra.span>Attempts</chakra.span>
      <chakra.span>Inserted by</chakra.span>
      <chakra.span>Status</chakra.span>
    </Card>
  );
}

function TreatmentsIvStartsCard({
  pictureUrl,
  index,
  isReadOnly,
}: TreatmentsCardProps) {
  const { user: currentUser } = useAuth0();
  const signatureUserId = currentUser ? currentUser[USERID_CLAIMS] : "";

  const { register, watch, setValue } = useFormContext();

  const treatment = watch(`treatmentsIvStarts[${index}].treatment`);
  const signedBy = watch(`treatmentsIvStarts[${index}].signedBy`);
  const userId = watch(`treatmentsIvStarts[${index}].userId`);

  const { data: userDetails } = useGetUserDetails(userId);

  const setSignature = React.useCallback(() => {
    setValue(`treatmentsIvStarts[${index}].signedBy`, signatureUserId, {
      shouldDirty: true,
    });
  }, [setValue, index, signatureUserId]);

  const handleChangeUpdatedBy = React.useCallback(() => {
    setValue(
      `treatmentsIvStarts[${index}].userId`,
      currentUser?.[USERID_CLAIMS],
      {
        shouldDirty: true,
      }
    );
  }, [currentUser, index, setValue]);

  const { data: userData } = useGetUser(signedBy, {
    enabled: Boolean(signedBy),
  });

  return (
    <Card boxShadow="none">
      <Box
        {...GRID_PROPS}
        gridTemplateColumns="5fr 15fr 15fr 5fr 5fr 5fr 5fr 20fr 12fr"
      >
        <StaffAvatar
          profileUrl={pictureUrl || userDetails?.data?.pictureUrl || ""}
          size="xs"
          justifySelf="center"
          userName={userDetails?.data?.fullName}
        />
        <chakra.span>
          <ChartRowElement
            type="date"
            name={`treatmentsIvStarts[${index}].T001xSIVMQ001A001`}
            onClick={handleChangeUpdatedBy}
          />
        </chakra.span>
        <chakra.span textAlign="center">
          <Text>{treatment}</Text>
        </chakra.span>
        <chakra.span textAlign="center">
          <Input {...register(`treatmentsIvStarts[${index}].number`)} />
        </chakra.span>
        <chakra.span textAlign="center">
          <Input {...register(`treatmentsIvStarts[${index}].site`)} />
        </chakra.span>
        <chakra.span textAlign="center">
          <Input {...register(`treatmentsIvStarts[${index}].ga`)} />
        </chakra.span>
        <chakra.span textAlign="center">
          <Input {...register(`treatmentsIvStarts[${index}].attempts`)} />
        </chakra.span>
        <chakra.span textAlign="center">
          {signedBy && userData?.data ? (
            <>
              <Flex alignItems="center" justifyContent="center">
                <UserAvatar
                  mr="11px"
                  w="32px"
                  h="32px"
                  picture={userData.data.pictureUrl}
                  name={userData.data.firstName}
                />
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize="1.063rem"
                    lineHeight="1.063rem"
                  >
                    {`${userData.data.firstName} ${userData.data.lastName}`}
                  </Text>
                  <Text
                    fontSize="0.875rem"
                    lineHeight="0.875rem"
                    color="gray"
                    mt="2px"
                  >
                    {userData.data.teams.map((team) => team.name).join(", ")}
                  </Text>
                </Box>
                <Image
                  src={userData.data.signature}
                  height="50px"
                  width="200px"
                />
              </Flex>
            </>
          ) : (
            <Button
              variant="label"
              color="blue"
              disabled={isReadOnly}
              onClick={setSignature}
            >
              <Icon as={AddRounded} fontSize="1.5rem" color="blue" m={2} />
              Add E-Signature
            </Button>
          )}
        </chakra.span>
        <ToggleMedicationStatus name={`treatmentsIvStarts[${index}].status`} />
      </Box>
      <Box
        {...GRID_PROPS}
        gridTemplateColumns="5fr 15fr 15fr 5fr 5fr 5fr 5fr 20fr 12fr"
      >
        <chakra.span>Comments:</chakra.span>
        <chakra.span gridColumn="span 8">
          <HStack>
            <Input
              {...register(`treatmentsIvStarts[${index}].T001xSIVMQ001A002`)}
            />
          </HStack>
        </chakra.span>
      </Box>
    </Card>
  );
}

export {
  TreatmentsCard,
  TreatmentsCardHeader,
  TreatmentsIvStartsCardHeader,
  TreatmentsIvStartsCard,
};
export type { TreatmentsCardProps };
