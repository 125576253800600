import { Icon } from "@chakra-ui/react";
import {
  EmrCar,
  EmrClinicalImpression,
  EmrCrossCase,
  EmrForm,
  EmrHeartBolt,
  EmrHpi,
  EmrLabs,
  EmrMdm,
  EmrMedicalHX,
  EmrPerson,
  EmrProcedures,
  EmrRadiology,
  EmrRos,
  EmrSignature,
  EmrTakeVitals,
} from "@medstonetech/slate-icons";
import { ProviderChartCode } from "enums";
import {
  Chart,
  Inbox,
  InfoRoundOutline,
  MedicalCross,
  PillsBottle,
} from "icons";
import {
  ChartNavigationButton,
  ChartNavigationButtonProps,
  ChartsSidebar,
} from "modules/charts-shared";
import * as React from "react";
import { useParams } from "react-router-dom";
import { SidebarNavigation } from "shared";
import { ProviderRouteBaseParams } from "../../types/provider-routes";
import { usePermissions } from "contexts";

type ProviderSidebarProps = {
  providerChartCode: ProviderChartCode;
  hiddentSections?: string[] | undefined;
};

type GetNavigationItemsParams = {
  baseUrl: string;
  encounterId: string;
  chartCode: string;
};

function useGetNavigationItems(
  params: GetNavigationItemsParams
): ChartNavigationButtonProps[] {
  const { baseUrl, ...restParams } = params;
  const { scope } = usePermissions();

  return [
    {
      label: "Patient Info",
      sectionId: "SPIF",
      labelIcon: <Icon as={InfoRoundOutline} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/patient-info`,
      notesType: "shared",
      isAccessible:
        scope("provider:patientinfo").isAccessible ||
        scope("audits").isAccessible,
      isSaved: true,
      ...restParams,
    },
    {
      label: "Arrival Info",
      sectionId: "SARI",
      labelIcon: <Icon as={EmrCar} color="blue" fontSize="1.25rem" />,
      hasAudits: true,
      isShared: true,
      to: `${baseUrl}/arrival-info`,
      notesType: "shared",
      isAccessible:
        scope("nurse:ai").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Medical HX",
      sectionId: "SMHX",
      hasAudits: true,
      labelIcon: <Icon as={EmrMedicalHX} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/medical-hx`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:mh").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Vitals",
      sectionId: "Vitals",
      labelIcon: <Icon as={EmrTakeVitals} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/vitals`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:vitals").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Order History",
      sectionId: "SPOF",
      labelIcon: <Icon as={Inbox} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/order-history`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:odh").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "HPI",
      sectionId: "SHPI",
      hasAudits: true,
      labelIcon: <Icon as={EmrHpi} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/hpi`,
      notesType: "general",
      isAccessible:
        scope("provider:hpi").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "ROS",
      sectionId: "SROS",
      hasAudits: true,
      labelIcon: <Icon as={EmrRos} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/ros`,
      notesType: "general",
      isAccessible:
        scope("provider:ros").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Physical Exam",
      sectionId: "SPEX",
      hasAudits: true,
      labelIcon: <Icon as={EmrPerson} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/physical-exam`,
      notesType: "general",
      hasDiagrams: true,
      isAccessible:
        scope("provider:pex").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Orders",
      sectionId: "SPOF",
      labelIcon: <Icon as={EmrForm} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/order-form`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:odf").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Meds & IV",
      sectionId: "SIVM",
      labelIcon: <Icon as={PillsBottle} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/iv-and-medications`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:ivm").isAccessible || scope("audits").isAccessible,
      showIvMedicationCounters: true,
      ...restParams,
    },
    {
      label: "Treatments",
      sectionId: "Treatments",
      labelIcon: <Icon as={MedicalCross} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/treatments`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:treatments").isAccessible ||
        scope("audits").isAccessible,
      showTreatmentsCounters: true,
      ...restParams,
    },
    {
      label: "Radiology",
      sectionId: "SRAD",
      hasAudits: true,
      notesType: "order",
      orderType: "Radiology",
      labelIcon: <Icon as={EmrRadiology} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/radiology`,
      isShared: true,
      isAccessible:
        scope("provider:rad").isAccessible || scope("audits").isAccessible,
      showOrderCounters: "Radiology",
      ...restParams,
    },
    {
      label: "Labs",
      sectionId: "SLAB",
      hasAudits: true,
      notesType: "order",
      orderType: "Labs",
      labelIcon: <Icon as={EmrLabs} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/labs`,
      isShared: true,
      isAccessible:
        scope("provider:labs").isAccessible || scope("audits").isAccessible,
      showOrderCounters: "Lab",
      ...restParams,
    },
    {
      label: "EKG",
      sectionId: "SEKG",
      hasAudits: true,
      labelIcon: <Icon as={EmrHeartBolt} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/ekg`,
      notesType: "general",
      isShared: true,
      isAccessible:
        scope("provider:ekg").isAccessible || scope("audits").isAccessible,
      showEKGCounters: true,
      ...restParams,
    },
    {
      label: "Procedures",
      sectionId: "SPRO2",
      hasAudits: true,
      labelIcon: <Icon as={EmrProcedures} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/procedures`,
      notesType: "general",
      isAccessible:
        scope("provider:proc").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Progress",
      sectionId: "SPRG",
      labelIcon: <Icon as={Chart} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/progress`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:prog").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Procedural Sedation Note",
      sectionId: "SPSN",
      hasAudits: true,
      labelIcon: <Icon as={EmrProcedures} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/procedural-sedation-note`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:notes").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Supplies",
      sectionId: "SSUP",
      labelIcon: <Icon as={EmrCrossCase} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/supplies`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:sup").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "MDM",
      sectionId: "SMDM",
      hasAudits: true,
      labelIcon: <Icon as={EmrMdm} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/mdm`,
      notesType: "shared",
      isShared: true,
      isAccessible:
        scope("provider:mdm").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Clinical Impression",
      sectionId: "SCLI",
      hasAudits: true,
      labelIcon: (
        <Icon as={EmrClinicalImpression} color="blue" fontSize="1.25rem" />
      ),
      to: `${baseUrl}/clinical-impression`,
      notesType: "general",
      isAccessible:
        scope("provider:ci").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Disposition",
      sectionId: "SDIS",
      hasAudits: true,
      labelIcon: <Icon as={EmrSignature} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/disposition`,
      notesType: "order",
      isAccessible:
        scope("provider:disp").isAccessible || scope("audits").isAccessible,
      showSignedSections: true,
      ...restParams,
    },
  ];
}

function ProviderSidebar(props: ProviderSidebarProps) {
  const { providerChartCode, hiddentSections = ["SARI", "SSUP", "SPSN"] } =
    props;
  const { encounterId = "" } = useParams<ProviderRouteBaseParams>();
  const mainRoute = window.location.hash.split("/")[1];
  const NAVIGATION_ITEMS = useGetNavigationItems({
    baseUrl: `/${mainRoute}/charts/${encounterId}/chart/${providerChartCode}`,
    chartCode: providerChartCode,
    encounterId,
  });

  return (
    <ChartsSidebar>
      <SidebarNavigation>
        {NAVIGATION_ITEMS.filter(
          (item) =>
            item.isAccessible && !hiddentSections.includes(item.sectionId)
        ).map((navItem) => (
          <ChartNavigationButton key={navItem.sectionId} {...navItem} />
        ))}
      </SidebarNavigation>
    </ChartsSidebar>
  );
}

export { ProviderSidebar };
