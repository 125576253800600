import { axiosPatientInstance } from "config";
import { useQuery } from "react-query";

function useEncounterConsentFile(encounterId: string) {
  return useQuery(
    ["encounterConsentFile", { encounterId }],
    () =>
      axiosPatientInstance.get<Blob>(
        `v1/encounter/${encounterId}/form/files/consent`,
        { responseType: "blob" }
      ),
    { staleTime: 5 * 60 * 1000 }
  );
}

export { useEncounterConsentFile };
