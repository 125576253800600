import { createIcon } from "@chakra-ui/react";

const Birthday = createIcon({
  path: (
    <>
      <path d="M3.74999 12.7504C3.74999 9.02731 6.20666 5.93807 9.41183 5.35701C8.45141 3.92733 6.94546 3 5.24999 3C2.35509 3 0 5.69165 0 8.99999C0 11.8182 1.71342 14.1768 4.00931 14.8172C3.8414 14.1587 3.74999 13.4656 3.74999 12.7504Z" />
      <path d="M3.82878 15.4148C3.71267 15.6473 3.72514 15.9235 3.86173 16.1446C3.99833 16.3655 4.23969 16.5002 4.49965 16.5002C4.49965 17.404 4.41579 18.3287 3.41384 18.8297C3.04325 19.015 2.89311 19.4654 3.07841 19.836C3.20989 20.0989 3.47464 20.2506 3.75003 20.2506C3.86244 20.2506 3.97709 20.2253 4.08476 20.1715C5.11001 19.6587 5.58378 18.8757 5.80507 18.1339C4.99568 17.2651 4.38781 16.1824 4.05275 14.9668L3.82878 15.4148Z" />
      <path d="M21 5.99999C21 2.69165 18.6449 0 15.75 0C12.8551 0 10.5 2.69165 10.5 5.99999C10.5 8.86044 12.2619 11.2561 14.6098 11.8533L14.3291 12.4145C14.213 12.6471 14.2255 12.9232 14.3621 13.1444C14.4986 13.3651 14.7399 13.4998 14.9997 13.4999V13.5C14.9997 14.3118 14.9997 16.2136 16.9146 17.1709C17.0223 17.2247 17.1369 17.25 17.2493 17.25C17.5247 17.25 17.7895 17.098 17.921 16.8355C18.1062 16.4649 17.9557 16.0144 17.5855 15.8291C16.5835 15.3285 16.4996 14.3906 16.4996 13.5H16.5C16.76 13.5 17.0013 13.3652 17.1379 13.1444C17.2745 12.9232 17.287 12.6471 17.1709 12.4145L16.8902 11.8533C19.238 11.2561 21 8.86044 21 5.99999Z" />
      <path d="M13.0858 13.9325C12.8556 13.5598 12.7424 13.1403 12.7483 12.72C10.7415 11.6237 9.35455 9.48524 9.06234 6.98438C6.86503 7.70123 5.25 10.0119 5.25 12.75C5.25 15.6104 7.01193 18.0061 9.35976 18.6033L9.07912 19.1645C8.96301 19.3971 8.97548 19.6732 9.11207 19.8944C9.24866 20.1152 9.48998 20.25 9.74999 20.25C9.74999 21.1538 9.66613 22.0785 8.66418 22.5791C8.29396 22.7644 8.14345 23.2149 8.32874 23.5854C8.46023 23.8484 8.72498 24 9.00037 24C9.11277 24 9.22743 23.9747 9.3351 23.9209C11.25 22.9636 11.25 21.0618 11.25 20.25C11.51 20.25 11.7513 20.1152 11.8879 19.8944C12.0245 19.6732 12.037 19.3971 11.9209 19.1645L11.6402 18.6033C12.6961 18.3347 13.621 17.6888 14.3329 16.814C13.8175 16.0053 13.6134 15.1447 13.542 14.4655C13.3662 14.3154 13.212 14.1365 13.0858 13.9325Z" />
    </>
  ),
  viewBox: "0 0 21 24",
});

export { Birthday };
