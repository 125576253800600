import { axiosOrderInstance } from "config";
import { useAxiosMutation } from "hooks";
import { IvStart } from "modules/charts-shared";
import { useQueryClient } from "react-query";

function useUpdateIvStarts(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateIvStart", { encounterId }],
    (input: IvStart[]) =>
      axiosOrderInstance.put(`v1/iv-starts/${encounterId}`, input),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["ivStarts", { encounterId }]),
    }
  );
}

export { useUpdateIvStarts };
