import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const Brain = createIcon({
  path: (
    <path
      d="M4.10601 12.5557C1.65733 12.5557 0 10.6391 0 8.0435C0 6.88149 0.403135 5.75721 1.09742 4.91212C1.57521 5.81758 2.52333 6.39103 3.79246 6.39103C4.16573 6.39103 4.48675 6.07412 4.48675 5.6893C4.48675 5.31203 4.16573 4.99512 3.79246 4.99512C2.85181 4.99512 2.19485 4.25566 2.19485 3.36529C2.19485 1.97692 3.55356 0.83755 5.36767 0.83755C5.70362 0.83755 6.03957 0.882823 6.36805 0.973369C6.17395 1.43364 6.06943 1.93919 6.04703 2.49001C6.0321 2.94274 6.33819 3.24456 6.73386 3.24456C7.11459 3.24456 7.40575 2.98802 7.41321 2.5202C7.46547 1.01864 8.5181 0 10.0635 0C10.9593 0 11.9 0.45273 12.5196 1.19219C12.7137 1.15446 12.9078 1.12428 13.0496 1.13182C14.7816 1.13182 16.4763 2.36929 16.4763 4.13493C16.4763 8.48114 10.0261 8.14913 10.0261 12.7972C10.0261 13.099 10.056 13.4159 10.1306 13.7479C9.74991 13.763 9.53341 13.7705 9.33184 13.7705C8.41359 13.7705 7.51026 13.5366 6.79358 13.1216C7.78649 12.3897 8.37626 11.2654 8.37626 9.8846C8.37626 9.74123 8.36879 9.60541 8.35386 9.4696C10.0037 9.00932 10.7652 7.83222 10.7577 6.17221C10.7577 5.75721 10.4442 5.4403 10.0635 5.4403C9.68272 5.4403 9.3841 5.74967 9.3841 6.13449C9.3841 7.20595 8.99589 7.8775 7.95819 8.14159C7.81635 7.84731 7.61478 7.59077 7.37589 7.38704C7.09966 7.14558 6.90556 7.05504 6.62187 7.05504C6.2262 7.05504 5.94998 7.30404 5.94998 7.66622C5.94998 8.05859 6.17395 8.18686 6.50243 8.49623C6.79358 8.78296 7.01754 9.30359 7.01754 9.82423C7.01754 11.4012 5.89772 12.5557 4.10601 12.5557ZM16.4016 17C15.1026 17 14.2591 15.7927 14.0575 14.4723C13.8932 14.4798 13.7066 14.4873 13.3333 14.4873C12.1538 14.4873 11.3102 13.6196 11.355 12.5104C11.4894 9.09987 16.9541 9.66578 17.9171 4.59521C19.2087 5.25921 20 6.50422 20 7.99068C20 9.68842 18.8055 10.6995 16.9989 10.5486C16.6107 10.5109 16.3046 10.8504 16.3046 11.2428C16.3046 11.6276 16.6032 11.9143 16.9914 11.937C17.8574 11.9973 18.6264 11.8615 19.2609 11.5597C19.2758 11.8238 19.2833 12.103 19.2833 12.4048C19.2833 14.7665 18.2904 17 16.4016 17Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 17",
});

export { Brain };
