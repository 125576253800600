import {
  Box,
  Button,
  chakra,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import * as yup from "yup";
import {
  ButtonProps,
  DividerList,
  Input,
  Loading,
  WarningDialog,
} from "shared";
import { ReasonSelector, ReasonSelectorItem } from "./ReasonSelector";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useYupValidationResolver } from "hooks";
import {
  useRequestOrderCancellation,
  useEncounterOrder,
} from "modules/orders/api";
import { formMessages, genericErrors } from "messages";
import { CancellationReason } from "modules/orders/enums";
import {
  OrderDetailsEntry,
  OrderDetailsEntryDateBox,
  OrderDetailsEntryTextBox,
} from "../OrderDetailsEntry";
import { OrderStatusPill } from "../OrderStatusPill";
import { enumMapper } from "utils";
import { getOrderDetailsDateShortFormat } from "modules/orders/helpers";
import { MedicalStaffInfo } from "../MedicalStaffInfo";
import { FaCheck } from "react-icons/fa";
import { OrderStatus } from "enums";

type CancelOrderModalProps = {
  onCancelSuccess?: () => void;
  isModalOpen: boolean;
  onModalClose: () => void;
  orderId: string;
  encounterId: string;
};

type ButtonCancelOrderModalProps = {
  onCancelSuccess?: () => void;
  orderId: string;
  encounterId: string;
  orderStatus?: OrderStatus;
} & Omit<ButtonProps, "onClick">;

const schema = yup
  .object({
    reason: yup.string().required("Please select at least one option"),
    other: yup.string(),
  })
  .test(
    "noCustomReason",
    "Please type a valid reason ",
    (values) =>
      values.reason !== "Other" || (values.reason === "Other" && !!values.other)
  );

function ButtonCancelOrderModal(props: ButtonCancelOrderModalProps) {
  const { onCancelSuccess, orderId, encounterId, orderStatus, ...rest } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  let orderStatusLabel = "Cancel Order";
  switch (orderStatus) {
    case "CancellationRequested":
      orderStatusLabel = "Cancellation Requested";
      break;
    case "Cancelled":
      orderStatusLabel = "Cancelled";
      break;
  }

  return (
    <>
      <Button
        variant="outlineSquared"
        colorScheme="red"
        onClick={onOpen}
        {...rest}
      >
        {orderStatusLabel}
      </Button>
      <CancelOrderModal
        isModalOpen={isOpen}
        onModalClose={onClose}
        orderId={orderId}
        encounterId={encounterId}
        onCancelSuccess={onCancelSuccess}
      />
    </>
  );
}

function CancelOrderModalContent(props: CancelOrderModalProps) {
  const { onModalClose, onCancelSuccess, orderId, encounterId } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const { data, isLoading: isLoadingOrder } = useEncounterOrder(orderId);
  const {
    control,
    register,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm({
    resolver: useYupValidationResolver(schema),
    mode: "onChange",
    defaultValues: {
      reason: "",
      other: "",
    },
  });

  const watchReason = watch("reason");

  const { mutateAsync: requestOrderCancellation, isLoading } =
    useRequestOrderCancellation(orderId, encounterId);
  const onSubmit: SubmitHandler<{
    reason: CancellationReason;
    other: string;
  }> = async ({ reason, other }) => {
    try {
      onClose();
      const payload = {
        reason,
        other: reason === "Other" ? other : null,
      };
      await requestOrderCancellation(payload);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Order"),
      });
      onModalClose();
      onCancelSuccess?.();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: e.message || genericErrors.unknownError,
        });
      }
    }
  };

  return (
    <>
      <WarningDialog
        isOpen={isOpen}
        onCancel={onClose}
        title="Warning!"
        mainText="Are you sure you want to request cancellation for this order?"
        onClose={onClose}
        onAction={handleSubmit(onSubmit)}
        cancelLabel="Go Back"
        actionLabel="Request"
        actionsDirection="vertical"
        blockScrollOnMount={false}
      />
      <ModalHeader>
        <Box
          textAlign="center"
          wordBreak="break-word"
          fontSize="1.0625rem"
          fontWeight="600"
        >
          Cancel Request
        </Box>
      </ModalHeader>
      <ModalBody p="0 15px 19px">
        {isLoadingOrder || !data ? (
          <Loading padding="16px" height="180px" />
        ) : (
          <Box
            background="white"
            borderRadius="10px"
            p="0 25px"
            border="1px solid"
            borderColor="gray.450"
            mb="15px"
          >
            <DividerList dividerLeftMargin="0px">
              <OrderDetailsEntry
                label="EMR Order ID"
                value={
                  <OrderDetailsEntryTextBox>
                    {data.data.orderId}
                  </OrderDetailsEntryTextBox>
                }
              />
              <OrderDetailsEntry
                label="Order Status"
                value={<OrderStatusPill status={data.data.status} />}
              />
              {data.data.elementType === "Radiology" ? (
                <OrderDetailsEntry
                  label="Study Type ID/CPT Code"
                  value={
                    data.data.studyTypeId && data.data.cptCode ? (
                      <OrderDetailsEntryTextBox>
                        {data.data.studyTypeId} / {data.data.cptCode}
                      </OrderDetailsEntryTextBox>
                    ) : (
                      ""
                    )
                  }
                />
              ) : (
                <OrderDetailsEntry
                  label="Type/Study Type ID"
                  value={
                    <OrderDetailsEntryTextBox>
                      {data.data.elementCategory &&
                        enumMapper.toDisplay(
                          "orderFormElementCategory",
                          data.data.elementCategory
                        )}{" "}
                      / {data.data.type}
                    </OrderDetailsEntryTextBox>
                  }
                />
              )}
              <OrderDetailsEntry
                label="Description"
                value={
                  <OrderDetailsEntryTextBox textAlign="end">
                    {data.data.description}
                  </OrderDetailsEntryTextBox>
                }
              />
              <OrderDetailsEntry
                label="Time Ordered"
                value={
                  <OrderDetailsEntryDateBox>
                    {data.data.orderedAt &&
                      getOrderDetailsDateShortFormat(
                        new Date(data.data.orderedAt)
                      )}
                  </OrderDetailsEntryDateBox>
                }
              />
              {data.data.orderedBy && (
                <OrderDetailsEntry
                  label="Ordered By"
                  value={
                    <MedicalStaffInfo
                      name={data.data.orderedBy.fullName}
                      role={data.data.orderedBy.teams}
                      pictureUrl={data.data.orderedBy.pictureUrl}
                    />
                  }
                />
              )}
            </DividerList>
          </Box>
        )}

        <chakra.span color="gray.700" fontSize="15px" fontWeight="500">
          Reason:
        </chakra.span>

        <form>
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <ReasonSelector
                bg="white"
                mb="25px"
                borderRadius="10px"
                padding="15px"
                border="1px solid"
                borderColor="gray.450"
                {...field}
              >
                <ReasonSelectorItem value="Mistake">
                  <Flex
                    dir="row"
                    minW="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <chakra.span fontWeight="500" fontSize="1.0625rem">
                      Ordered by mistake.
                    </chakra.span>
                    <Icon as={FaCheck} />
                  </Flex>
                </ReasonSelectorItem>
                <ReasonSelectorItem value="Duplicate">
                  <Flex
                    dir="row"
                    minW="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <chakra.span fontWeight="500" fontSize="1.0625rem">
                      Duplicate Order.
                    </chakra.span>
                    <Icon as={FaCheck} />
                  </Flex>
                </ReasonSelectorItem>
                <ReasonSelectorItem value="Provider">
                  <Flex
                    dir="row"
                    minW="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <chakra.span fontWeight="500" fontSize="1.0625rem">
                      Canceled by Dr's request.
                    </chakra.span>
                    <Icon as={FaCheck} />
                  </Flex>
                </ReasonSelectorItem>
                <ReasonSelectorItem value="Other">
                  <Flex
                    dir="row"
                    minW="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <chakra.span fontWeight="500" fontSize="1.0625rem">
                      Other:
                    </chakra.span>
                    <Icon as={FaCheck} />
                  </Flex>
                  {watchReason === "Other" && (
                    <Input
                      placeholder="Please write a reason"
                      borderWidth="0px"
                      fontSize="1.0625rem"
                      mt="10px"
                      {...register("other")}
                    />
                  )}
                </ReasonSelectorItem>
              </ReasonSelector>
            )}
          />
        </form>

        <Button onClick={onModalClose} variant="outline" w="100%" mb="25px">
          Cancel Request
        </Button>

        <Button
          onClick={onOpen}
          disabled={!isValid}
          isLoading={isLoading}
          variant="solid"
          w="100%"
          mb="25px"
        >
          Send Request
        </Button>
      </ModalBody>
    </>
  );
}

function CancelOrderModal(props: CancelOrderModalProps) {
  const { onCancelSuccess, isModalOpen, onModalClose, orderId, encounterId } =
    props;

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      isCentered
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent bg="gray.200" maxWidth="540px">
        {isModalOpen && (
          <CancelOrderModalContent
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            orderId={orderId}
            encounterId={encounterId}
            onCancelSuccess={onCancelSuccess}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { CancelOrderModal, ButtonCancelOrderModal };
