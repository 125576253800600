import * as React from "react";

import { useEffectOnce } from "utils";

type ChildProps = {
  type?: string;
  name?: string;
  children?: React.ReactNode;
  content?: {
    props: {
      children?: React.ReactNode;
    };
  };
};

function useListChartElements(
  parentChildren: React.ReactNode,
  setState: ((chartElementList: Record<string, string>[]) => void) | undefined
) {
  useEffectOnce(() => {
    const filteredElements: React.ReactElement["props"][] = [];

    const recursivelySearchChildren = (children: React.ReactNode) => {
      React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
          const typedChild = child as React.ReactElement<ChildProps>;

          if (typedChild.props.name) {
            filteredElements.push(typedChild.props);
          }

          if (typedChild.props.content?.props?.children) {
            recursivelySearchChildren(typedChild.props.content?.props.children);
          }

          if (typedChild.props.children) {
            recursivelySearchChildren(typedChild.props.children);
          }
        }
      });
    };

    recursivelySearchChildren(parentChildren);

    setState?.(filteredElements);
  });
}

export { useListChartElements };
