import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseEditTeamPayload = {
  name: string;
  permissions: string[];
};

function useEditTeam(teamId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["editTeam"],
    (input: UseEditTeamPayload) =>
      axiosIdentityInstance.put(`/v1/team/${teamId}`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("teamsList");
        queryClient.invalidateQueries("teamsMin");
        queryClient.invalidateQueries(["teamDetail", { teamId }]);
      },
    }
  );
}

export { useEditTeam };
