import { Icon } from "@chakra-ui/react";
import { EmrPuzzle } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import {
  ChartRow,
  ChartRowElement,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  useChartForm,
} from "modules";
import { ChartForm } from "modules/charts-shared-v2";
import { FormProvider } from "react-hook-form";
import { Loading } from "shared";

const sectionId = "SCHC";
const triageChartCode = "T006x";

function N06ChiefComplaint() {
  const {
    encounterId,
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormContext,
    isDirty,
    onSaveAndNext,
    onRouteChangeSave,
  } = useChartForm({
    sectionId,
    sectionLabel: "Chief Complaint",
    chartCode: triageChartCode,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:cc").isEditable;

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPuzzle} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Chief Complaint {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q001"
              subsectionLabel="Chief Complaint"
            >
              Chief Complaint
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="MVC"
                  name="Q001.T006xSCHCQ001A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wound recheck"
                  name="Q001.T006xSCHCQ001A003"
                />
                <ChartRowElement type="input" name="Q001.T006xSCHCQ001A002" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T006xSCHCQ001A010"
                  label="ocurred:"
                />
                <ChartRowElement
                  type="cross-option"
                  label="just PTA"
                  name="Q001.T006xSCHCQ001A003"
                />
                <ChartRowElement
                  type="input"
                  width="60px"
                  defaultValue={0}
                  textAlign="center"
                  inputType="number"
                  name="Q001.T006xSCHCQ001A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mins ago"
                  name="Q001.T006xSCHCQ001A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hours ago"
                  name="Q001.T006xSCHCQ001A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="days ago"
                  name="Q001.T006xSCHCQ001A007"
                />
                <ChartRowElement type="input" name="Q001.T006xSCHCQ001A008" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q001.T006xSCHCQ001A011"
                  label="other"
                />
                <ChartRowElement type="input" name="Q001.T006xSCHCQ001A009" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="Location"
            >
              Location
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="head"
                  name="Q002.T006xSCHCQ002A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="face"
                  name="Q002.T006xSCHCQ002A002"
                />
                <ChartRowElement
                  type="cross-option"
                  label="nose"
                  name="Q002.T006xSCHCQ002A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mouth"
                  name="Q002.T006xSCHCQ002A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="lip"
                  name="Q002.T006xSCHCQ002A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chin"
                  name="Q002.T006xSCHCQ002A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="neck"
                  name="Q002.T006xSCHCQ002A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="back"
                  name="Q002.T006xSCHCQ002A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="chest"
                  name="Q002.T006xSCHCQ002A009"
                />
                <ChartRowElement
                  type="cross-option"
                  label="abdomen"
                  name="Q002.T006xSCHCQ002A010"
                />
                <ChartRowElement
                  type="cross-option"
                  label="coccyx"
                  name="Q002.T006xSCHCQ002A011"
                />
                <ChartRowElement type="input" name="Q002.T006xSCHCQ002A012" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="R"
                  name="Q002.T006xSCHCQ002A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="shoulder"
                  name="Q002.T006xSCHCQ002A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="arm"
                  name="Q002.T006xSCHCQ002A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="elbow"
                  name="Q002.T006xSCHCQ002A016"
                />
                <ChartRowElement
                  type="cross-option"
                  label="forearm"
                  name="Q002.T006xSCHCQ002A017"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wrist"
                  name="Q002.T006xSCHCQ002A018"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hand"
                  name="Q002.T006xSCHCQ002A019"
                />
                <ChartRowElement
                  type="cross-option"
                  label="fingers"
                  name="Q002.T006xSCHCQ002A020"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hip"
                  name="Q002.T006xSCHCQ002A021"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thigh"
                  name="Q002.T006xSCHCQ002A022"
                />
                <ChartRowElement
                  type="cross-option"
                  label="knee"
                  name="Q002.T006xSCHCQ002A023"
                />
                <ChartRowElement
                  type="cross-option"
                  label="leg"
                  name="Q002.T006xSCHCQ002A024"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ankle"
                  name="Q002.T006xSCHCQ002A025"
                />
                <ChartRowElement
                  type="cross-option"
                  label="foot"
                  name="Q002.T006xSCHCQ002A026"
                />
                <ChartRowElement
                  type="cross-option"
                  label="toes"
                  name="Q002.T006xSCHCQ002A027"
                />
                <ChartRowElement type="input" name="Q002.T006xSCHCQ002A028" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="L"
                  name="Q002.T006xSCHCQ002A029"
                />
                <ChartRowElement
                  type="cross-option"
                  label="shoulder"
                  name="Q002.T006xSCHCQ002A030"
                />
                <ChartRowElement
                  type="cross-option"
                  label="arm"
                  name="Q002.T006xSCHCQ002A031"
                />
                <ChartRowElement
                  type="cross-option"
                  label="elbow"
                  name="Q002.T006xSCHCQ002A032"
                />
                <ChartRowElement
                  type="cross-option"
                  label="forearm"
                  name="Q002.T006xSCHCQ002A033"
                />
                <ChartRowElement
                  type="cross-option"
                  label="wrist"
                  name="Q002.T006xSCHCQ002A034"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hand"
                  name="Q002.T006xSCHCQ002A035"
                />
                <ChartRowElement
                  type="cross-option"
                  label="fingers"
                  name="Q002.T006xSCHCQ002A036"
                />
                <ChartRowElement
                  type="cross-option"
                  label="hip"
                  name="Q002.T006xSCHCQ002A037"
                />
                <ChartRowElement
                  type="cross-option"
                  label="thigh"
                  name="Q002.T006xSCHCQ002A038"
                />
                <ChartRowElement
                  type="cross-option"
                  label="knee"
                  name="Q002.T006xSCHCQ002A039"
                />
                <ChartRowElement
                  type="cross-option"
                  label="leg"
                  name="Q002.T006xSCHCQ002A040"
                />
                <ChartRowElement
                  type="cross-option"
                  label="ankle"
                  name="Q002.T006xSCHCQ002A041"
                />
                <ChartRowElement
                  type="cross-option"
                  label="foot"
                  name="Q002.T006xSCHCQ002A042"
                />
                <ChartRowElement
                  type="cross-option"
                  label="toes"
                  name="Q002.T006xSCHCQ002A043"
                />
                <ChartRowElement type="input" name="Q002.T006xSCHCQ002A044" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  name="Q002.T006xSCHCQ002A046"
                  label="other"
                />
                <ChartRowElement type="input" name="Q002.T006xSCHCQ002A045" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="Crash"
            >
              Crash
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="driver"
                  name="Q003.T006xSCHCQ003A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="passenger:"
                  name="Q003.T006xSCHCQ003A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="front"
                  name="Q003.T006xSCHCQ003A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="back"
                  name="Q003.T006xSCHCQ003A005"
                />
                <ChartRowElement type="input" name="Q003.T006xSCHCQ003A006" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="lap belt"
                  name="Q003.T006xSCHCQ003A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="shoulder belt"
                  name="Q003.T006xSCHCQ003A008"
                />
                <ChartRowElement
                  type="cross-option"
                  label="car seat"
                  name="Q003.T006xSCHCQ003A009"
                />
                <ChartRowElement type="input" name="Q003.T006xSCHCQ003A010" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="air bag deployed"
                  name="Q003.T006xSCHCQ003A011"
                />
                <ChartRowElement type="input" name="Q003.T006xSCHCQ003A012" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="walking at scene"
                  name="Q003.T006xSCHCQ003A021"
                />
                <ChartRowElement type="input" name="Q003.T006xSCHCQ003A014" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="lost consciousness"
                  name="Q003.T006xSCHCQ003A015"
                />
                <ChartRowElement type="input" name="Q003.T006xSCHCQ003A016" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="thrown from vehicle"
                  name="Q003.T006xSCHCQ003A017"
                />
                <ChartRowElement type="input" name="Q003.T006xSCHCQ003A018" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="long extraction"
                  name="Q003.T006xSCHCQ003A019"
                />
                <ChartRowElement type="input" name="Q003.T006xSCHCQ003A022" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q003.T006xSCHCQ003A013"
                />
                <ChartRowElement type="input" name="Q003.T006xSCHCQ003A020" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn={true}
              showAuditsBtn={true}
              chartId={triageChartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Site of Impact"
            >
              Site of Impact
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="primary:"
                  name="Q004.T006xSCHCQ004A001"
                />
                <ChartRowElement
                  type="cross-option"
                  label="force"
                  name="Q004.T006xSCHCQ004A003"
                />
                <ChartRowElement
                  type="cross-option"
                  label="low"
                  name="Q004.T006xSCHCQ004A004"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mod."
                  name="Q004.T006xSCHCQ004A005"
                />
                <ChartRowElement
                  type="cross-option"
                  label="high"
                  name="Q004.T006xSCHCQ004A006"
                />
                <ChartRowElement
                  type="cross-option"
                  label="direct"
                  name="Q004.T006xSCHCQ004A007"
                />
                <ChartRowElement
                  type="cross-option"
                  label="glancing"
                  name="Q004.T006xSCHCQ004A008"
                />
                <ChartRowElement type="input" name="Q004.T006xSCHCQ004A009" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="secondary:"
                  name="Q004.T006xSCHCQ004A010"
                />
                <ChartRowElement
                  type="cross-option"
                  label="force"
                  name="Q004.T006xSCHCQ004A011"
                />
                <ChartRowElement
                  type="cross-option"
                  label="low"
                  name="Q004.T006xSCHCQ004A012"
                />
                <ChartRowElement
                  type="cross-option"
                  label="mod."
                  name="Q004.T006xSCHCQ004A013"
                />
                <ChartRowElement
                  type="cross-option"
                  label="high"
                  name="Q004.T006xSCHCQ004A014"
                />
                <ChartRowElement
                  type="cross-option"
                  label="direct"
                  name="Q004.T006xSCHCQ004A015"
                />
                <ChartRowElement
                  type="cross-option"
                  label="glancing"
                  name="Q004.T006xSCHCQ004A016"
                />
                <ChartRowElement type="input" name="Q004.T006xSCHCQ004A017" />
              </ChartRow>
              <ChartRow>
                <ChartRowElement
                  type="cross-option"
                  label="other"
                  name="Q004.T006xSCHCQ004A018"
                />
                <ChartRowElement type="input" name="Q004.T006xSCHCQ004A019" />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { N06ChiefComplaint };
