import {
  ChartFormSubsectionPayload,
  ChartTrackingItem,
  findChartFormDifferences,
  useTrackChartInteractions,
} from "modules";
import React, { ReactNode, createContext, useContext } from "react";

import { extractApiErrorMessage } from "utils";
import { genericErrors } from "messages";
import { useToast } from "@chakra-ui/react";

type ChartProps = {
  encounterId: string;
  chartCode?: string;
  sectionId?: string;
  chartType: ChartTrackingItem["type"];
};

type ChartTrackingContextType = {
  currentChartElements: Record<string, string>[] | null;
  updateChartElements: (newData: Record<string, string>[]) => void;
  isLoading: boolean;
  trackChartInteractions: (
    oldArray: ChartFormSubsectionPayload[],
    newArray: ChartFormSubsectionPayload[],
    chartProps: ChartProps
  ) => Promise<void>;
  isPastEncounter: boolean;
  setIsPastEncounter: (value: boolean) => void;
};

const ChartTrackingContext = createContext<
  ChartTrackingContextType | undefined
>(undefined);

const useChartTrackingContext = () => {
  const context = useContext(ChartTrackingContext);
  if (!context) {
    throw new Error(
      "useChartTrackingContext must be used within a ChartTrackingContextProvider"
    );
  }
  return context;
};

type ChartTrackingContextProviderProps = {
  children: ReactNode;
};

const ChartTrackingContextProvider: React.FC<
  ChartTrackingContextProviderProps
> = ({ children }) => {
  const [currentChartElements, setCurrentChartElements] = React.useState<
    Record<string, string>[] | null
  >(null);
  const [isPastEncounter, setIsPastEncounter] = React.useState<boolean>(false);

  const toast = useToast();

  const { mutateAsync: track, isLoading } = useTrackChartInteractions();

  const trackChartInteractions = async (
    oldArray: ChartFormSubsectionPayload[],
    newArray: ChartFormSubsectionPayload[],
    chartProps: ChartProps
  ) => {
    try {
      const { encounterId, chartType, chartCode, sectionId } = chartProps;

      const trackingInfo = findChartFormDifferences(oldArray, newArray).map(
        (x) => {
          const chartElementInfo = currentChartElements?.find(
            (el) => el.name?.split(".").pop() === x.qCode
          );

          return {
            chartCode,
            encounterId,
            sectionId,
            type: chartType,
            label: chartElementInfo?.label || chartElementInfo?.type,
            ...x,
          } as ChartTrackingItem;
        }
      );

      await track(trackingInfo);
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const updateChartElements = (newData: Record<string, string>[]) => {
    setCurrentChartElements(newData);
  };

  const contextValue: ChartTrackingContextType = {
    currentChartElements,
    updateChartElements,
    trackChartInteractions,
    isLoading,
    isPastEncounter,
    setIsPastEncounter: (value: boolean) => setIsPastEncounter(value),
  };

  return (
    <ChartTrackingContext.Provider value={contextValue}>
      {children}
    </ChartTrackingContext.Provider>
  );
};

export {
  ChartTrackingContext,
  ChartTrackingContextProvider,
  useChartTrackingContext,
};
