import { Icon } from "@chakra-ui/react";
import {
  EmrActions,
  EmrAssessments,
  EmrCar,
  EmrCrossCase,
  EmrForm,
  EmrHeartBolt,
  EmrLabs,
  EmrMedicalHX,
  EmrProcedures,
  EmrRadiology,
  EmrSignature,
  EmrTakeVitals,
} from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { TriageChartCode } from "enums";
import {
  Chart,
  Dialog,
  Inbox,
  InfoRoundOutline,
  MedicalCross,
  PillsBottle,
} from "icons";
import {
  ChartNavigationButton,
  ChartNavigationButtonProps,
  ChartsSidebar,
} from "modules/charts-shared";
import { TriageRouteBaseParams } from "modules/triage/types";
import * as React from "react";
import { useParams } from "react-router-dom";
import { SidebarNavigation } from "shared";

type TriageSidebarProps = {
  triageChartCode: TriageChartCode;
};

type GetNavigationItemsParams = {
  baseUrl: string;
  encounterId: string;
  chartCode: string;
};

function useGetNavigationItems(
  params: GetNavigationItemsParams
): ChartNavigationButtonProps[] {
  const { baseUrl, ...restParams } = params;
  const { scope } = usePermissions();

  return [
    {
      label: "Patient Info",
      sectionId: "SPIF",
      labelIcon: <Icon as={InfoRoundOutline} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/patient-info`,
      isAccessible:
        scope("nurse:patientinfo").isAccessible || scope("audits").isAccessible,
      isSaved: true,
      ...restParams,
    },
    {
      label: "Chief Complaint",
      sectionId: "SCHC",
      hasAudits: true,
      labelIcon: <Icon as={Dialog} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/chief-complaint`,
      isAccessible:
        scope("nurse:cc").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Arrival Info",
      sectionId: "SARI",
      hasAudits: true,
      isShared: true,
      labelIcon: <Icon as={EmrCar} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/arrival-info`,
      isAccessible:
        scope("nurse:ai").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Actions",
      sectionId: "SACT",
      hasAudits: true,
      labelIcon: <Icon as={EmrActions} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/actions`,
      isAccessible:
        scope("nurse:actions").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Vitals",
      sectionId: "Vitals",
      labelIcon: <Icon as={EmrTakeVitals} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/vitals`,
      isShared: true,
      isAccessible:
        scope("nurse:vitals").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Medical HX",
      sectionId: "SMHX",
      hasAudits: true,
      labelIcon: <Icon as={EmrMedicalHX} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/medical-hx`,
      isShared: true,
      isAccessible:
        scope("nurse:mh").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Assessments",
      sectionId: "SASS",
      hasNotes: false,
      hasAudits: true,
      labelIcon: <Icon as={EmrAssessments} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/assessments`,
      isAccessible:
        scope("nurse:assts").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Orders",
      sectionId: "SPOF",
      isShared: true,
      labelIcon: <Icon as={EmrForm} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/order-form`,
      isAccessible:
        scope("nurse:odf").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Meds & IV",
      sectionId: "SIVM",
      labelIcon: <Icon as={PillsBottle} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/iv-and-medications`,
      isShared: true,
      isAccessible:
        scope("nurse:ivm").isAccessible || scope("audits").isAccessible,
      showIvMedicationCounters: true,
      ...restParams,
    },
    {
      label: "Treatments",
      sectionId: "Treatments",
      labelIcon: <Icon as={MedicalCross} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/treatments`,
      isShared: true,
      isAccessible:
        scope("nurse:ivm").isAccessible || scope("audits").isAccessible,
      showTreatmentsCounters: true,
      ...restParams,
    },
    {
      label: "Radiology",
      sectionId: "SRAD",
      hasAudits: true,
      notesType: "order",
      orderType: "Radiology",
      labelIcon: <Icon as={EmrRadiology} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/radiology`,
      isShared: true,
      isAccessible:
        scope("nurse:rad").isAccessible || scope("audits").isAccessible,
      showOrderCounters: "Radiology",
      ...restParams,
    },
    {
      label: "Labs",
      sectionId: "SLAB",
      hasAudits: true,
      notesType: "order",
      orderType: "Labs",
      labelIcon: <Icon as={EmrLabs} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/labs`,
      isShared: true,
      isAccessible:
        scope("nurse:labs").isAccessible || scope("audits").isAccessible,
      showOrderCounters: "Lab",
      ...restParams,
    },
    {
      label: "EKG",
      sectionId: "SEKG",
      hasAudits: true,
      labelIcon: <Icon as={EmrHeartBolt} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/ekg`,
      notesType: "general",
      isShared: true,
      isAccessible:
        scope("provider:ekg").isAccessible || scope("audits").isAccessible,
      showEKGCounters: true,
      ...restParams,
    },
    {
      label: "Procedures",
      sectionId: "SPRO",
      hasAudits: true,
      labelIcon: <Icon as={EmrProcedures} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/procedures`,
      isAccessible:
        scope("nurse:proc").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Progress",
      sectionId: "SPRG",
      labelIcon: <Icon as={Chart} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/progress`,
      isShared: true,
      isAccessible:
        scope("nurse:prog").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Order History",
      sectionId: "SPOF",
      labelIcon: <Icon as={Inbox} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/order-history`,
      isShared: true,
      isAccessible:
        scope("nurse:mh").isAccessible || scope("audits").isAccessible,
      // hasPendingOrdersCancellation: data?.data?.hasPendingOrdersCancellation,
      ...restParams,
    },
    {
      label: "Supplies",
      sectionId: "SSUP",
      isShared: true,
      labelIcon: <Icon as={EmrCrossCase} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/supplies`,
      isAccessible:
        scope("nurse:sup").isAccessible || scope("audits").isAccessible,
      ...restParams,
    },
    {
      label: "Disposition",
      sectionId: "SDIS",
      hasAudits: true,
      labelIcon: <Icon as={EmrSignature} color="blue" fontSize="1.25rem" />,
      to: `${baseUrl}/disposition`,
      isAccessible:
        scope("nurse:disp").isAccessible || scope("audits").isAccessible,
      showSignedSections: true,
      ...restParams,
    },
  ];
}

function TriageSidebar(props: TriageSidebarProps) {
  const { triageChartCode } = props;
  const { encounterId = "" } = useParams<TriageRouteBaseParams>();
  const mainRoute = window.location.hash.split("/")[1];
  const NAVIGATION_ITEMS = useGetNavigationItems({
    baseUrl: `/${mainRoute}/charts/${encounterId}/triage/${triageChartCode}`,
    chartCode: triageChartCode,
    encounterId,
  });

  return (
    <ChartsSidebar>
      <SidebarNavigation>
        {NAVIGATION_ITEMS.filter((item) => item.isAccessible).map((navItem) => (
          <ChartNavigationButton key={navItem.sectionId} {...navItem} />
        ))}
      </SidebarNavigation>
    </ChartsSidebar>
  );
}

export { TriageSidebar };
