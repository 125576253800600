import { PaginationPayload, PaginationResponse } from "types";
import { useAxiosQuery } from "hooks";
import { axiosFacilityInstance } from "config";
import { Pharmacy } from "../../types";

type PrimaryCarePhysiciansResponse = PaginationResponse<Pharmacy>;

type UsePharmacyListProps = {
  NCPDPId?: string;
  city?: string;
  address?: string;
  name?: string;
} & PaginationPayload;

function usePharmacyList(props: UsePharmacyListProps) {
  const {
    sort,
    descending,
    page = 1,
    size = 10,
    NCPDPId,
    address,
    city,
    name,
  } = props;

  return useAxiosQuery(
    ["pharmacyList", { page, name, NCPDPId, address, city, sort, descending }],
    () =>
      axiosFacilityInstance.get<PrimaryCarePhysiciansResponse>("v1/pharmacy", {
        params: {
          page,
          pageSize: size,
          sortDesc: descending || false,
          orderBy: sort,
          name,
          NCPDPId,
          address,
          city,
        },
      }),
    { keepPreviousData: true }
  );
}

export { usePharmacyList };
export type { PrimaryCarePhysiciansResponse, UsePharmacyListProps };
