import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { MedicationRxType } from "enums";
import { MedicationRx } from "modules/charts-shared";
import { useOrderListElements } from "modules/order-list/api";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Select, SelectFilter, ToolbarHeader } from "shared";

type AddMedicationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  type: MedicationRxType;
  onAddItem: (item: MedicationRx) => void;
  onDeleteItem: (item: MedicationRx) => void;
  editingMedication: MedicationRx | null;
};

type AddMedicationForm = {
  medication: string;
  dosage: string;
  frequency: string;
  otherFrequency: string | null;
};

const FREQS = [
  "1x daily",
  "2x daily",
  "3x daily",
  "4x daily",
  "5x daily",
  "As Needed",
];

function AddMedicationModal({
  isOpen,
  onClose,
  type,
  onAddItem,
  onDeleteItem,
  editingMedication,
}: AddMedicationModalProps) {
  const [otherMedication, setOtherMedication] = useState(false);
  const { data: medicationData } = useOrderListElements(
    "Medication",
    type === "PRESCRIPTION_RX"
  );

  const medications = useMemo(() => {
    if (!medicationData) return [];
    return [
      ...medicationData.data.map((med) => ({
        value: med.description,
        label: med.description,
      })),
      { value: "other", label: "Other" },
    ];
  }, [medicationData]);

  const { handleSubmit, register, watch, setValue, reset } =
    useForm<AddMedicationForm>({
      defaultValues: {
        medication: "",
        dosage: "",
        frequency: "",
        otherFrequency: null,
      },
    });

  useEffect(() => {
    if (editingMedication) {
      const freqs = editingMedication.medFrequency.split(",");

      const otherFeqs = freqs.filter((freq) => !FREQS.includes(freq));

      reset({
        medication: editingMedication.medName,
        dosage: editingMedication.medDosage,
        frequency: freqs.filter((freq) => FREQS.includes(freq)).join(","),
        otherFrequency: otherFeqs.length > 0 ? otherFeqs.join(",") : null,
      });
    }
  }, [editingMedication, reset]);

  const dosage = watch("dosage");
  const frequency = watch("frequency");
  const medication = watch("medication");

  const onSubmit = (data: AddMedicationForm) => {
    const freqs = [
      ...data.frequency.split(","),
      ...(data.otherFrequency ?? "").split(","),
    ];
    onAddItem({
      medDosage: data.dosage,
      medName: data.medication,
      medFrequency: freqs.join(","),
      type: type,
      id: editingMedication?.id,
    });
    onClose();
  };

  const onDelete = (data: AddMedicationForm) => {
    const freqs = [
      ...data.frequency.split(","),
      ...(data.otherFrequency ?? "").split(","),
    ];

    onDeleteItem({
      medDosage: data.dosage,
      medName: data.medication,
      medFrequency: freqs.join(","),
      type: type,
      id: editingMedication?.id,
    });

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        width="540px"
        height="600px"
        overflow="hidden"
        m="auto"
        p={0}
      >
        <ModalHeader>
          <ToolbarHeader
            titleText={`${
              type === "OTC_RX" ? "OTC" : "Prescription"
            } Medication`}
            leftButtons={[null]}
          />
        </ModalHeader>
        <ModalBody>
          <form
            style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
          >
            <FormControl>
              <Label>Medication</Label>
              {type === "PRESCRIPTION_RX" && (
                <SelectFilter
                  items={medications}
                  labelAccessor="label"
                  valueAccessor="value"
                  selectedItem={{ value: medication, label: medication }}
                  onSelectedItemChange={(newMedication) => {
                    if (newMedication.selectedItem?.value !== "other") {
                      setValue(
                        "medication",
                        newMedication.selectedItem?.value || ""
                      );
                      setOtherMedication(false);
                    } else {
                      setValue("medication", "");
                      setOtherMedication(true);
                    }
                  }}
                />
              )}
              {(otherMedication || type === "OTC_RX") && (
                <Input
                  {...register("medication")}
                  sx={{ marginTop: "0.5rem" }}
                />
              )}
            </FormControl>
            <Flex columnGap="1.25rem">
              <FormControl>
                <Label>Dosage</Label>
                <Input {...register("dosage")} />
              </FormControl>
              <FormControl>
                <Label>Frequency</Label>
                <Select
                  items={FREQS.map((f) => ({ value: f, label: f }))}
                  labelAccessor="label"
                  valueAccessor="value"
                  selectedItem={{ label: frequency, value: frequency }}
                  onSelectedItemChange={(changes) =>
                    setValue("frequency", changes.selectedItem?.value || "")
                  }
                />
              </FormControl>
            </Flex>
          </form>
        </ModalBody>
        <ModalFooter sx={{ display: "flex", justifyContent: "center" }}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            width="100%"
            rowGap="1.25rem"
          >
            <Button
              colorScheme="blue"
              variant="solid"
              width="100%"
              isDisabled={!medication || !frequency || !dosage}
              onClick={() => handleSubmit(onSubmit)()}
              borderRadius="0.625rem"
            >
              Save
            </Button>
            <Flex justify="space-between" width="100%" columnGap="1.25rem">
              <Button
                variant="outline"
                colorScheme="red"
                flex={1}
                onClick={() => handleSubmit(onDelete)()}
                borderRadius="0.625rem"
              >
                Delete
              </Button>
              <Button
                variant="outline"
                colorScheme="gray"
                flex={1}
                onClick={onClose}
                borderRadius="0.625rem"
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { AddMedicationModal };
