import {
  Avatar,
  Box,
  chakra,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { EmrBed, EmrRemove, EmrRoomCard } from "@medstonetech/slate-icons";
import { ClosedRoom } from "icons";
import { formMessages } from "messages";
import {
  BedRequestDto,
  RoomDto,
  UpdateRoomPayload,
  useUpdateRoom,
  useUpdateRoomStatus,
} from "modules/room-list/api";
import React from "react";
import { Control, useFieldArray, useForm } from "react-hook-form";
import { Button, EncounterAvatar, Loading, ToolbarHeader } from "shared";
import { extractApiErrorMessage } from "utils";
import {
  TicketRequestDto,
  useDeleteTickets,
  useRoomById,
  useUpdateTickets,
} from "../api";
import { useTicketsByRoom } from "../api/queries/useTicketsByRoom";
import { TicketList } from "./TicketList";

type TicketForm = {
  number?: number;
  user?: TicketUser;
} & TicketRequestDto;

type TicketUser = {
  id: string;
  fullName: string;
  pictureUrl: string;
  teams: string;
};

type RoomDetailsForm = {
  room: UpdateRoomPayload;
  newTickets: TicketForm[];
  updatedTickets: TicketForm[];
  deletedTickets: TicketForm[];
};

type BedEncounterInfoProps = {
  encounterId: string;
  fullName: string;
  control: Control<RoomDetailsForm>;
  index: number;
};

const BedEncounterInfo = ({
  encounterId,
  fullName,
  control,
  index,
}: BedEncounterInfoProps) => {
  const { update } = useFieldArray({ control, name: "room.beds" });

  const handleRemove = () => {
    update(index, { encounterId: "" });
  };

  return (
    <Box display="flex" alignItems="center">
      <Button variant="label" onClick={handleRemove}>
        <Icon as={EmrRemove} mr={4} h="24px" w="24px" color="red" />
      </Button>
      <EncounterAvatar encounterId={encounterId} h="32px" w="32px" mr={2} />
      <chakra.span>{fullName}</chakra.span>
    </Box>
  );
};

type BedItemProps = {
  index: number;
  bed: BedRequestDto;
  control: Control<RoomDetailsForm>;
  isClosedRoom: boolean;
};

const BedItem = ({ index, control, bed, isClosedRoom }: BedItemProps) => {
  const { update } = useFieldArray({ control, name: "room.beds" });

  return (
    <Box
      bgColor="white"
      my={5}
      pb={4}
      borderRadius="10px"
      w="510px"
      h="140px"
      p={4}
    >
      <VStack spacing="24px">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" justifyContent="start" alignItems="center">
            <Box
              w="32px"
              h="32px"
              borderRadius="5px"
              bgColor={
                bed.encounterId ? "blue" : isClosedRoom ? "gray.450" : "green"
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
              mr={4}
            >
              <Icon as={EmrBed} h="60%" w="60%" color="white" />
            </Box>
            <chakra.span fontSize="17px" fontWeight="500">
              {bed.name}
            </chakra.span>
          </Box>
          <Box>
            {bed.encounter && bed.encounterId && (
              <BedEncounterInfo
                control={control}
                index={index}
                fullName={`${bed.encounter.firstName} ${bed.encounter.lastName}`}
                encounterId={bed.encounter.encounterId}
              />
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" w="100%">
          <Button
            variant="outlineSquared"
            color={bed.status === "Cleaning" ? "blue" : "gray"}
            borderColor={bed.status === "Cleaning" ? "blue" : "gray.650"}
            width="230px"
            borderRadius="10px"
            h="40px"
            disabled={
              !!bed.encounter || bed.status === "Closed" || isClosedRoom
            }
            onClick={() => {
              if (bed.status === "Cleaning") {
                update(index, { status: "Active" });
              } else {
                update(index, { status: "Cleaning" });
              }
            }}
          >
            {bed.status === "Cleaning" ? "Done Cleaning Bed" : "Clean Bed"}
          </Button>
          <Button
            variant="outlineSquared"
            color="gray"
            borderColor="gray.650"
            width="230px"
            borderRadius="10px"
            h="40px"
            disabled={!!bed.encounter || isClosedRoom}
            onClick={() => {
              if (bed.status === "Closed") {
                update(index, { status: "Active" });
              } else {
                update(index, { status: "Closed" });
              }
            }}
          >
            {bed.status === "Closed" ? "Open Bed" : "Close Bed"}
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

type BedListProps = {
  beds?: BedRequestDto[];
  control: Control<RoomDetailsForm>;
  isClosedRoom: boolean;
};

const BedList = ({ control, beds, isClosedRoom }: BedListProps) => {
  return (
    <Box>
      {beds &&
        beds.map((bed, index) => (
          <BedItem
            key={bed.bedId}
            bed={bed}
            index={index}
            control={control}
            isClosedRoom={isClosedRoom}
          />
        ))}
    </Box>
  );
};

const CloseRoomButton = ({
  room,
  onClose,
}: RoomManagementDetailsContentProps) => {
  const toast = useToast();

  const {
    mutateAsync: updateRoomStatus,
    isLoading: isUpdateRoomStatusLoading,
  } = useUpdateRoomStatus(room.id);
  const handleUpdateRoomStatus = async () => {
    try {
      await updateRoomStatus({
        roomStatus: room.status === "Closed" ? "Open" : "Closed",
      });

      toast({
        status: "success",
        description: formMessages.updateSuccess("Room"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  return (
    <>
      <Button
        variant="outlineSquared"
        borderRadius="25px"
        w="100%"
        color={room.status === "Closed" ? "blue" : "red"}
        borderColor={room.status === "Closed" ? "blue" : "red"}
        mb={4}
        isLoading={isUpdateRoomStatusLoading}
        onClick={handleUpdateRoomStatus}
      >
        {room.status === "Closed" ? "Open Room" : "Close Room"}
      </Button>
    </>
  );
};

type RoomManagementDetailsContentProps = {
  room: RoomDto;
  isOpen: boolean;
  onClose: () => void;
};

const RoomManagementDetailsContent = (
  props: RoomManagementDetailsContentProps
) => {
  const toast = useToast();

  const { room, onClose } = props;

  const isClosedRoom = room.status === "Closed";

  const { watch, control, getValues } = useForm<RoomDetailsForm>({
    defaultValues: {
      room:
        {
          room: {
            roomId: room.id,
            name: room.name,
            status: room.status,
          },
          beds: room.beds?.map((bed) => {
            return {
              bedId: bed.id,
              roomId: room.id,
              name: bed.name,
              status: bed.status,
              encounterId: bed.encounterId,
              encounter: bed.encounter,
            };
          }),
        } ?? [],
      newTickets: [],
      updatedTickets: [],
      deletedTickets: [],
    },
  });

  const beds = watch("room.beds");

  const { mutateAsync: updateRoom, isLoading: isUpdateRoomLoading } =
    useUpdateRoom(room.id, false);

  const { mutateAsync: updateTickets, isLoading: isUpdateTicketsLoading } =
    useUpdateTickets(room.id);

  const { mutateAsync: deleteTickets, isLoading: isDeleteTicketsLoading } =
    useDeleteTickets(room.id);

  const handleUpdateRoom = async () => {
    try {
      await updateRoom(getValues("room"));

      await updateTickets({
        newTickets: getValues("newTickets"),
        updatedTickets: getValues("updatedTickets"),
      });

      const deletedTickets = getValues("deletedTickets");
      if (deletedTickets.length) {
        const ticketsIds: string[] = [];
        deletedTickets.forEach((ticket) => {
          if (ticket.ticketId) ticketsIds.push(ticket.ticketId);
        });
        await deleteTickets({
          ids: ticketsIds,
        });
      }

      toast({
        status: "success",
        description: formMessages.updateSuccess("Room"),
      });
      onClose();
    } catch (err) {
      toast({ status: "error", description: extractApiErrorMessage(err) });
    }
  };

  const { data: ticketsData, isLoading: isTicketsLoading } = useTicketsByRoom(
    room.id
  );

  const { append, remove } = useFieldArray({ control, name: "updatedTickets" });

  React.useEffect(() => {
    const updatedTickets = getValues("updatedTickets");
    if (
      ticketsData?.data &&
      ticketsData.data.length !== updatedTickets.length
    ) {
      remove();
      append(
        ticketsData.data.map((ticket) => {
          return {
            ticketId: ticket.id,
            number: ticket.number,
            roomId: room.id,
            userId: ticket.userId,
            reportedAt: new Date(ticket.reportedAt),
            description: ticket.description,
            status: ticket.status,
            type: ticket.type,
            user: {
              id: ticket.userId,
              fullName: `${ticket.user?.firstName} ${ticket.user?.lastName}`,
              pictureUrl: ticket.user?.pictureUrl ?? "",
              teams:
                ticket.user?.teams.map((team) => team.name).join(", ") ?? "",
            },
          };
        })
      );
    }
  }, [ticketsData, append, room.id, getValues, remove]);

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          p="10px"
          titleText={
            <chakra.span fontSize="17px" fontWeight="600">
              Room Details
            </chakra.span>
          }
          leftButtons={[
            <Button
              key="cancelBtn"
              fontSize="16px"
              fontWeight="400"
              onClick={onClose}
            >
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="doneBtn"
              fontSize="16px"
              fontWeight="400"
              isLoading={
                isUpdateRoomLoading ||
                isUpdateTicketsLoading ||
                isDeleteTicketsLoading
              }
              onClick={handleUpdateRoom}
            >
              Done
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody p="15px">
        <Box display="flex" justifyContent="center" h="100%">
          <Box
            w="540px"
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            h="100%"
          >
            <Box>
              <VStack>
                <Avatar
                  bg={isClosedRoom ? "gray.450" : "blue"}
                  icon={
                    <Icon
                      as={isClosedRoom ? ClosedRoom : EmrRoomCard}
                      fontSize="3rem"
                      color="white"
                    />
                  }
                  margin="auto"
                  h="100px"
                  w="100px"
                />
                <chakra.span fontSize="26px" fontWeight="500">
                  {room.name}
                </chakra.span>
                <BedList
                  beds={beds}
                  control={control}
                  isClosedRoom={isClosedRoom}
                />
              </VStack>
            </Box>
            <Box>
              <CloseRoomButton {...props} />
            </Box>
          </Box>
          <Box w="540px" display="flex" justifyContent="center">
            {isTicketsLoading ? (
              <Loading />
            ) : (
              <TicketList roomId={room.id} control={control} watch={watch} />
            )}
          </Box>
        </Box>
      </ModalBody>
    </>
  );
};

type RoomManagementDetailsModalProps = {
  roomId: string;
  isOpen: boolean;
  onClose: () => void;
};

const RoomManagementDetailsModal = (props: RoomManagementDetailsModalProps) => {
  const { data, isLoading } = useRoomById(props.roomId);
  const room = data?.data;

  return (
    <Modal {...props}>
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="1080px"
        height="95%"
        margin="auto"
        overflowY="auto"
        overflowX="hidden"
      >
        {isLoading && <Loading />}
        {!!room && <RoomManagementDetailsContent room={room} {...props} />}
      </ModalContent>
    </Modal>
  );
};

export { RoomManagementDetailsModal };
export type { RoomDetailsForm, TicketForm };
