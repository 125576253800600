import * as React from "react";

import {
  ChartRouteBaseParams,
  mapFormToPayload,
  mapPayloadToFormObject,
} from "modules";
import { DispositionForm, DispositionFormSignatureInfo } from "../types";
import {
  DispositionResponseSignatureInfo,
  useAddChartApplicationSignatures,
  useDeleteChartApplicationSignatures,
  useUpdateChartApplication,
} from "../api";
import { SubmitHandler, useForm } from "react-hook-form";

import { UseChartFormProps } from "./useChartForm";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useChartDisposition } from "../api/queries/useChartDisposition";
import { useChartTrackingContext } from "contexts";
import { useParams } from "react-router-dom";
import { useToast } from "hooks";
import { ChartType } from "types";
import faker from "faker";

function useChartDispositionForm(
  props: UseChartFormProps & { chartType?: ChartType }
) {
  const { chartCode, sectionId, sectionLabel, chartType } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<DispositionForm>();
  const { handleSubmit, reset } = useFormContext;
  const toast = useToast();

  const signatures = useFormContext.watch("signatures");
  const signaturesToDelete = useFormContext.watch("signaturesToDelete");

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartDisposition({
    chartCode,
    encounterId,
    sectionId,
  });

  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
      chartType,
    });

  const { mutateAsync: addSignatures } = useAddChartApplicationSignatures({
    chartCode,
    encounterId,
    sectionId,
    chartType,
  });

  const { mutateAsync: deleteSignatures } = useDeleteChartApplicationSignatures(
    {
      chartCode,
      encounterId,
      sectionId,
      chartType,
    }
  );

  const onSubmit: SubmitHandler<DispositionForm> = React.useCallback(
    async (formObject) => {
      try {
        const payload = mapFormToPayload(formObject.subsections);
        await trackChartInteractions(
          data?.data.subsections.map((x) => ({
            code: x.code,
            answers: x.answers,
          })) || [],
          payload,
          {
            encounterId,
            sectionId,
            chartCode,
            chartType: "shared",
          }
        );

        const signaturesToAdd = formObject.signatures ?? [];
        if (signaturesToAdd.length) {
          await addSignatures(signaturesToAdd);
        }

        const signaturesToDeletePayload = formObject.signaturesToDelete ?? [];
        if (signaturesToDeletePayload.length) {
          const ids = signaturesToDeletePayload.map(
            (signature) => signature.id
          );
          await deleteSignatures(ids);
        }

        await updateChartApplication({
          input: payload,
        });

        reset(formObject);
        toast({ description: formMessages.updateSuccess(sectionLabel) });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      trackChartInteractions,
      data?.data.subsections,
      encounterId,
      sectionId,
      chartCode,
      updateChartApplication,
      reset,
      toast,
      sectionLabel,
      addSignatures,
      deleteSignatures,
    ]
  );

  const setUserToForm = (
    signatureInfo: DispositionResponseSignatureInfo
  ): DispositionFormSignatureInfo => {
    const {
      inPresenceOf: { firstName, lastName, id, ...restInPresence },
      ...restProps
    } = signatureInfo;
    return {
      inPresenceOf: {
        fullName: `${firstName} ${lastName}`,
        id,
        teams: "Provider",
        ...restInPresence,
      },
      ...restProps,
    };
  };

  React.useEffect(() => {
    if (data) {
      const dispositionSubsections = mapPayloadToFormObject(
        data.data.subsections
      );
      const {
        subsections,
        signatures: signatureList,
        nurseSignature,
        scribeSignature,
        actingProviderSignature,
        supervisorSignature,
        ...restData
      } = data.data;
      reset({
        subsections: dispositionSubsections,
        nurseSignature:
          nurseSignature && nurseSignature?.inPresenceOf
            ? setUserToForm(nurseSignature)
            : nurseSignature,
        scribeSignature:
          scribeSignature && scribeSignature?.inPresenceOf
            ? setUserToForm(scribeSignature)
            : scribeSignature,
        actingProviderSignature:
          actingProviderSignature && actingProviderSignature?.inPresenceOf
            ? setUserToForm(actingProviderSignature)
            : actingProviderSignature,
        supervisorSignature:
          supervisorSignature && supervisorSignature?.inPresenceOf
            ? setUserToForm(supervisorSignature)
            : supervisorSignature,
        signaturesToDelete: [],
        signatures: signatureList.map((signature) => ({
          ...signature,
          uuid: faker.datatype.uuid(),
        })),
        ...restData,
      });
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  const value = React.useMemo(
    () => ({
      encounterId,
      useFormContext,
      getIsLoading,
      updateIsLoading: isTrackingLoading || updateIsLoading,
      onSubmit: handleSubmit(onSubmit),
      signatures,
      signaturesToDelete,
    }),
    [
      encounterId,
      useFormContext,
      getIsLoading,
      isTrackingLoading,
      updateIsLoading,
      handleSubmit,
      onSubmit,
      signatures,
      signaturesToDelete,
    ]
  );

  return value;
}

export { useChartDispositionForm };
