import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const HeartSquared = createIcon({
  path: (
    <path
      d="M3.07007 18C1.02662 18 0 16.9929 0 14.9788V3.03096C0 1.01684 1.02662 0 3.07007 0H14.9299C16.9832 0 18 1.01684 18 3.03096V14.9788C18 16.9929 16.9832 18 14.9299 18H3.07007ZM6.58012 4.53666C4.86909 4.53666 3.63715 5.82727 3.63715 7.61651C3.63715 10.2564 6.43346 12.7007 8.49647 14.0109C8.67246 14.1184 8.89734 14.2553 9.02444 14.2553C9.16133 14.2553 9.34709 14.1282 9.50353 14.0109C11.5568 12.6714 14.3726 10.2564 14.3726 7.61651C14.3726 5.82727 13.1309 4.53666 11.4297 4.53666C10.3542 4.53666 9.51331 5.15263 9.00489 6.0717C8.49647 5.15263 7.6654 4.53666 6.58012 4.53666Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 18 18",
});

export { HeartSquared };
