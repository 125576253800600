import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartApplicationStatus } from "modules/charts-shared";
import { useQueryClient } from "react-query";

type UseUpdateChartApplicationStatusList = {
  encounterId: string;
};

type UseUpdateChartApplicationStatusListPayload = {
  chartApplicationIds: string[];
  status: ChartApplicationStatus;
};

function useUpdateChartApplicationStatusList(
  params: UseUpdateChartApplicationStatusList
) {
  const { encounterId } = params;
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["UpdateChartApplicationStatusList", { encounterId }],
    (payload: UseUpdateChartApplicationStatusListPayload) =>
      axiosChartInstance.put(
        `v1/chart-application/${encounterId}/change-status-list`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["activeCharts", { encounterId }]);
      },
    }
  );
}

export type { UseUpdateChartApplicationStatusList };
export { useUpdateChartApplicationStatusList };
