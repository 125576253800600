import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import {
  ChartSection,
  ChartSectionHeader,
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartRouteBaseParams,
  ChartSubsectionHeader,
} from "modules";
import { useTriageChartProceduresForm } from "../../hooks";
import {
  ACTIONS_COLUMNS_GAP,
  ACTIONS_ROW_TEMPLATE_COLUMNS_4,
  CONTAINER_PADDING_2,
} from "modules/triage/shared/actions/constants";
import { ActionsHeaderDescription } from "modules/triage/shared";
import { usePermissions } from "contexts";
import { useParams } from "react-router-dom";

const sectionId = "SPRO";
const chartCode = "T011x";

function N11Procedures() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartProceduresForm({
    chartCode: chartCode,
    sectionId,
  });
  const { getValues } = useFormReturn;
  const sections = getValues("sections");
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:proc").isEditable;

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ChartSubsectionHeader
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          subsectionId="Q001"
          subsectionLabel=""
          showNotesBtn={false}
        />
        <ChartSubsection content={<ActionsHeaderDescription />} />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[0] && sections[0].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[0] && sections[0].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[0].${chartCode}${sectionId}Q001A001`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="sutures"
                    name={`sections[0].${chartCode}${sectionId}Q001A002`}
                    type="option"
                  />
                  <ChartRowElement
                    label="staples"
                    name={`sections[0].${chartCode}${sectionId}Q001A003`}
                    type="option"
                  />
                  <ChartRowElement
                    label="# removed"
                    fontStyle="italic"
                    type="option"
                    name={`sections[0].${chartCode}${sectionId}Q001A032`}
                  />
                  <ChartRowElement
                    name={`sections[0].${chartCode}${sectionId}Q001A004`}
                    w="50px"
                    minW="50px"
                    type="input"
                  />
                  <ChartRowElement
                    label="removed by:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[0].${chartCode}${sectionId}Q001A033`}
                  />
                  <ChartRowElement
                    name={`sections[0].${chartCode}${sectionId}Q001A005`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[1] && sections[1].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[1] && sections[1].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[1].${chartCode}${sectionId}Q001A006`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="drain"
                    name={`sections[1].${chartCode}${sectionId}Q001A007`}
                    type="option"
                  />
                  <ChartRowElement
                    label="packing"
                    name={`sections[1].${chartCode}${sectionId}Q001A008`}
                    type="option"
                  />
                  <ChartRowElement
                    label="removed"
                    name={`sections[1].${chartCode}${sectionId}Q001A009`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[1].${chartCode}${sectionId}Q001A010`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[2] && sections[2].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[2] && sections[2].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[2].${chartCode}${sectionId}Q001A011`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="burn care"
                    name={`sections[2].${chartCode}${sectionId}Q001A012`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[2].${chartCode}${sectionId}Q001A013`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${chartCode}${sectionId}Q001A014`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cleaned wound"
                    name={`sections[3].${chartCode}${sectionId}Q001A015`}
                    type="option"
                  />
                  <ChartRowElement
                    label="applied abx ointment"
                    name={`sections[3].${chartCode}${sectionId}Q001A016`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${chartCode}${sectionId}Q001A017`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[4] && sections[4].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[4] && sections[4].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[4].${chartCode}${sectionId}Q001A018`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="applied:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[4].${chartCode}${sectionId}Q001A032`}
                  />
                  <ChartRowElement
                    label="dressing"
                    name={`sections[4].${chartCode}${sectionId}Q001A019`}
                    type="option"
                  />
                  <ChartRowElement
                    label="Band-Aid"
                    name={`sections[4].${chartCode}${sectionId}Q001A020`}
                    type="option"
                  />
                  <ChartRowElement
                    label="elastic wrap"
                    name={`sections[4].${chartCode}${sectionId}Q001A021`}
                    type="option"
                  />
                  <ChartRowElement
                    label="steri-strips"
                    name={`sections[4].${chartCode}${sectionId}Q001A022`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[4].${chartCode}${sectionId}Q001A023`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[5] && sections[5].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[5] && sections[5].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[5].${chartCode}${sectionId}Q001A024`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[5].${chartCode}${sectionId}Q001A025`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[6] && sections[6].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[6] && sections[6].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[6].${chartCode}${sectionId}Q001A026`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[6].${chartCode}${sectionId}Q001A027`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[7] && sections[7].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[7] && sections[7].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[7].${chartCode}${sectionId}Q001A028`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[7].${chartCode}${sectionId}Q001A029`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[8] && sections[8].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[8] && sections[8].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[8].${chartCode}${sectionId}Q001A030`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[8].${chartCode}${sectionId}Q001A031`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N11Procedures };
