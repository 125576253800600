import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseTeamsResponse = {
  id: string;
  name: string;
};

function useTeams() {
  return useAxiosQuery(
    ["teamsMin"],
    () => axiosIdentityInstance.get<UseTeamsResponse[]>(`/v1/team/teams`),
    { staleTime: 1000 * 60 * 4 }
  );
}

export type { UseTeamsResponse };
export { useTeams };
