import { axiosOrderInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteOrderRange(encounterId: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteOrderRange", { encounterId }],
    ({ orders }: { orders: string[] }) =>
      axiosOrderInstance.delete(`v1`, { data: orders }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["encounterOrders", { encounterId }]);
        queryClient.invalidateQueries(["orderHistory", { encounterId }]);
        queryClient.invalidateQueries([
          "orderHistoryPagination",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "orderHistoryInfinite",
          { encounterId },
        ]);
        queryClient.invalidateQueries([
          "ivAndMedicationCounters",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["medsAndIvCounters", { encounterId }]);
        queryClient.invalidateQueries(["treatmentsCounters", { encounterId }]);
        queryClient.invalidateQueries([
          "encounterOrderCounters",
          { encounterId },
        ]);
        queryClient.invalidateQueries(["prnOrdersPending", { encounterId }]);
      },
    }
  );
}

export { useDeleteOrderRange };
