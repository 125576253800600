import {
  Box,
  BoxProps,
  Button,
  chakra,
  HStack,
  Icon,
  IconButton,
  Portal,
  Spinner,
  StackProps,
  useControllableProp,
  useControllableState,
} from "@chakra-ui/react";
import { useFileUrl } from "hooks";
import * as React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { PageProps } from "react-pdf";
import { Loading } from "shared";
import { DeleteOutline, LeftArrow, PrintOutline, RightArrow } from "icons";
import { EmrPlus } from "@medstonetech/slate-icons";

import {
  PdfViewerContextProvider,
  PdfViewerContextValue,
  usePdfViewerContext,
} from "./PdfViewerContext";

type PdfViewerActionsProps = {
  isSubmitLoading?: boolean;
  isDeleteLoading?: boolean;
  showPrint?: boolean;
  showSubmit?: boolean;
  showDelete?: boolean;
} & StackProps;

const ChakraDocument = chakra(Document);
const ChakraPage = chakra(Page);

function PdfViewerPageController() {
  const { numPages, activePageNumber, turnPage } = usePdfViewerContext();
  const hasPreviousPage = activePageNumber > 1 && numPages > 0;
  const hasNextPage = activePageNumber < numPages;

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      bg="gray.50"
      height="50px"
      justifyContent="center"
      maxH="100px"
    >
      <HStack spacing="120px">
        <IconButton
          aria-label="previous page"
          variant="icon"
          icon={
            <Icon
              as={LeftArrow}
              w="0.75rem"
              h="1.25rem"
              color={hasPreviousPage ? "blue" : "gray.550"}
            />
          }
          isDisabled={!hasPreviousPage}
          onClick={() => turnPage(activePageNumber - 1)}
        />
        <Box fontWeight="500" color="gray.650">
          {`${activePageNumber} of ${numPages}`}
        </Box>
        <IconButton
          aria-label="previous page"
          variant="icon"
          icon={
            <Icon
              as={RightArrow}
              w="0.75rem"
              h="1.25rem"
              color={hasNextPage ? "blue" : "gray.550"}
            />
          }
          isDisabled={!hasNextPage}
          onClick={() => turnPage(activePageNumber + 1)}
        />
      </HStack>
    </Box>
  );
}

function PdfViewerPageIndicator() {
  const { numPages, activePageNumber } = usePdfViewerContext();
  return (
    <chakra.span color="blue">{`${activePageNumber} of ${numPages}`}</chakra.span>
  );
}

type PdfViewerHeaderProps = {
  leftElements?: React.ReactNode[];
  rightElements?: React.ReactNode[];
} & Omit<BoxProps, "children">;

function PdfViewerHeader(props: PdfViewerHeaderProps) {
  const { leftElements, rightElements, title, ...restProps } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80px"
      borderBottom="1px"
      borderColor="gray.450"
      padding="30px 30px 30px 44px"
      position="relative"
      {...restProps}
    >
      <HStack position="absolute" left="44px">
        {leftElements}
      </HStack>
      <Box fontSize="0.9375rem">{title}</Box>
      <HStack position="absolute" right="30px">
        {rightElements}
      </HStack>
    </Box>
  );
}

function PdfViewerActions(props: PdfViewerActionsProps) {
  const { value, onDelete, fileUrl, onChange, onPrint } = usePdfViewerContext();
  const {
    isSubmitLoading,
    isDeleteLoading,
    showDelete = true,
    showPrint = true,
    showSubmit = true,
    children,
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSubmitClick = () => {
    inputRef.current?.click();
  };

  return (
    <HStack spacing="2.5rem" maxWidth="270px" overflow="auto" height="40px">
      {children}
      {showSubmit && (
        <input
          key={fileUrl || "file-input"}
          ref={inputRef}
          type="file"
          accept="application/pdf"
          style={{ display: "none" }}
          onChange={onChange}
        />
      )}
      {isSubmitLoading ? (
        <Spinner color="blue" />
      ) : (
        showSubmit && (
          <IconButton
            aria-label="submit document"
            icon={<Icon as={EmrPlus} />}
            color="blue"
            variant="icon"
            onClick={handleSubmitClick}
          />
        )
      )}
      {showPrint && (
        <IconButton
          aria-label="print document"
          icon={<Icon as={PrintOutline} color="blue" />}
          variant="icon"
          onClick={onPrint}
          isDisabled={!value}
        />
      )}
      {isDeleteLoading ? (
        <Spinner color="blue" />
      ) : (
        showDelete && (
          <IconButton
            aria-label="delete document"
            icon={<Icon as={DeleteOutline} color="blue" />}
            variant="icon"
            onClick={onDelete}
            isDisabled={!value}
          />
        )
      )}
    </HStack>
  );
}

type CustomPageProps = Omit<PageProps, "pageNumber"> & { pageNumber: number };

function CustomPage(props: CustomPageProps) {
  const { pageNumber, ...pageProps } = props;
  const { activePageNumber, turnPage } = usePdfViewerContext();

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      sx={{ "&:not(:first-of-type)": { marginTop: "14px" } }}
    >
      <chakra.span color="gray.550" marginRight="0.375rem" fontSize="0.75rem">
        {pageNumber}
      </chakra.span>
      <Button
        onClick={() => turnPage(pageNumber)}
        borderRadius="10px"
        overflow="hidden"
        height="110px"
        width="84px"
        position="relative"
        boxShadow={
          activePageNumber === pageNumber ? "0 0 0 2px #007AFF" : undefined
        }
        border="none"
        minW="none"
        padding={0}
        bg="transparent"
        _hover={{ bg: "transparent" }}
      >
        <ChakraPage
          width="100%"
          height="100%"
          pageNumber={pageNumber}
          sx={{
            "> canvas": {
              width: "100% !important",
              height: "100% !important",
            },
          }}
          renderAnnotationLayer={false}
          {...pageProps}
        />
      </Button>
    </Box>
  );
}

function PdfViewerPreview() {
  const { numPages, value } = usePdfViewerContext();

  return (
    <Box
      minW="124px"
      padding="7px 14px"
      borderRight="1px"
      borderColor="gray.450"
      height="100%"
      overflow="auto"
    >
      {value && (
        <ChakraDocument
          file={value}
          loading={<Loading />}
          options={{ disableAnnotationLayer: true }}
        >
          {Array(numPages)
            .fill(0)
            .map((_, index) => {
              const pageNumber = index + 1;
              return (
                <CustomPage
                  key={pageNumber}
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  renderInteractiveForms={false}
                  renderTextLayer={false}
                />
              );
            })}
        </ChakraDocument>
      )}
    </Box>
  );
}

function PdfZoomControls() {
  const { setScale, scale } = usePdfViewerContext();

  return (
    <HStack spacing="8px">
      <Button
        color="blue"
        fontSize="1.25rem"
        variant="label"
        onClick={() => setScale(scale - 0.25)}
      >
        -
      </Button>
      <Box width="50px" maxWidth="50px" textAlign="center">
        {(scale * 100).toFixed(0)}%
      </Box>
      <Button
        color="blue"
        fontSize="1.25rem"
        variant="label"
        onClick={() => setScale(scale + 0.25)}
      >
        +
      </Button>
    </HStack>
  );
}

type PdfViewerActivePageProps = BoxProps & {
  pageWidth?: number;
  pageHeight?: number;
};

function PdfViewerActivePage(props: PdfViewerActivePageProps) {
  const { pageWidth, pageHeight, ...rest } = props;
  const { activePageNumber, setNumPages, value, scale } = usePdfViewerContext();

  return (
    <>
      {value ? (
        <ChakraDocument
          file={value}
          onLoadSuccess={({ numPages: np }: { numPages: number }) =>
            setNumPages(np)
          }
          loading={<Loading />}
          overflow="auto"
          sx={{ canvas: { margin: "auto" } }}
          {...rest}
        >
          <Page
            pageNumber={activePageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={scale}
            width={pageWidth}
            height={pageHeight}
          />
        </ChakraDocument>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="1rem"
          {...rest}
        >
          No PDF Selected...
        </Box>
      )}
    </>
  );
}

type PdfViewerContainerProps = {
  header?: React.ReactNode;
  preview?: React.ReactNode;
  activePage?: React.ReactNode;
  pageController?: React.ReactNode;
  customContent?: React.ReactNode;
  contentContainerProps?: BoxProps;
  isDisabled?: boolean;
} & BoxProps;

function PdfViewerContainer(props: PdfViewerContainerProps) {
  const {
    header,
    preview,
    activePage,
    pageController,
    customContent,
    contentContainerProps,
    isDisabled,
    ...boxProps
  } = props;

  const { onChange, fileUrl } = usePdfViewerContext();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSubmitClick = () => {
    inputRef.current?.click();
  };

  if (customContent && !fileUrl)
    return (
      <>
        {React.isValidElement(customContent) &&
          React.cloneElement(customContent, {
            onClick: handleSubmitClick,
            ...(isDisabled && {
              style: { opacity: 0.5, cursor: "not-allowed" },
            }),
          })}
        <Portal>
          <input
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            onChange={onChange}
            disabled={isDisabled}
          />
        </Portal>
      </>
    );

  return (
    <Box
      bg="gray.200"
      width="100%"
      display="flex"
      flexDirection="column"
      borderRadius="10px"
      border="1px"
      borderColor="gray.450"
      overflow="hidden"
      {...boxProps}
    >
      {header}
      <Box display="flex" height="calc(100% - 10px)" {...contentContainerProps}>
        {preview}
        <Box display="flex" flexDirection="column" flex={1} overflow="auto">
          {React.isValidElement(activePage) &&
            React.cloneElement(activePage, { flex: 1 })}
          {pageController}
        </Box>
      </Box>
    </Box>
  );
}

type PdfViewerPublicState = Pick<
  PdfViewerContextValue,
  "activePageNumber" | "numPages" | "value"
>;

type PdfViewerProps = React.PropsWithChildren<{
  value?: Nullable<string | File | Blob>;
  numPages?: number;
  activePageNumber?: number;
  defaultValue?: string | File | Blob;
  onChange?: (value: Nullable<string | File | Blob>) => void;
  setNumPages?: (numPages: number) => void;
  setActivePageNumber?: (newActivePageNumber: number) => void;
  onDelete?: (state: PdfViewerPublicState) => void;
}>;

function PdfViewer(props: PdfViewerProps) {
  const {
    value: valueProp,
    defaultValue,
    onChange,
    onDelete,
    numPages: numPagesProp,
    setNumPages: setNumPagesProp,
    activePageNumber: activePageNumberProp,
    setActivePageNumber: setActivePageNumberProp,
    children,
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    onChange,
    value: valueProp,
  });
  const [isValueControlled] = useControllableProp(valueProp, value);
  const [numPages, setNumPages] = useControllableState({
    value: numPagesProp,
    onChange: setNumPagesProp,
    defaultValue: 0,
  });
  const [activePageNumber, setActivePageNumber] = useControllableState({
    value: activePageNumberProp,
    onChange: setActivePageNumberProp,
    defaultValue: 1,
  });
  const fileUrl = useFileUrl(value);
  const [scale, setScale] = React.useState(1.0);

  const contextValue: PdfViewerContextValue = React.useMemo(
    () => ({
      numPages,
      activePageNumber,
      turnPage: (page) => setActivePageNumber(page),
      setNumPages: (n) => setNumPages(n),
      onDelete: onDelete
        ? () => onDelete({ activePageNumber, numPages, value })
        : () => {
            setValue(null);
            setNumPages(0);
            setActivePageNumber(1);
          },
      onChange: isValueControlled
        ? (e: React.ChangeEvent<HTMLInputElement>) => {
            const newFile = e.target.files?.item(0);

            if (newFile) {
              setValue(newFile);
            }
          }
        : (e: React.ChangeEvent<HTMLInputElement>) => {
            const newFile = e.target.files?.item(0);

            if (newFile) {
              setNumPages(0);
              setActivePageNumber(1);
              setValue(newFile);
            }
          },
      onPrint: () => {
        if (fileUrl) {
          const myWindow = window.open(fileUrl);
          myWindow?.focus();
          myWindow?.print();
        }
      },
      value,
      fileUrl,
      scale,
      setScale,
    }),
    [
      numPages,
      activePageNumber,
      value,
      setValue,
      fileUrl,
      onDelete,
      setActivePageNumber,
      setNumPages,
      isValueControlled,
      scale,
      setScale,
    ]
  );

  return (
    <PdfViewerContextProvider value={contextValue}>
      {children}
    </PdfViewerContextProvider>
  );
}

export type { PdfViewerProps };

export {
  PdfViewer,
  PdfViewerActivePage,
  PdfViewerActions,
  PdfViewerHeader,
  PdfViewerPreview,
  PdfViewerPageIndicator,
  PdfViewerContainer,
  PdfViewerPageController,
  PdfZoomControls,
};
