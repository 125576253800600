import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ProviderChartCode, TriageChartCode } from "enums";
import { useToast } from "hooks";
import { OrderQueue } from "icons";
import { formMessages, genericErrors } from "messages";
import {
  UseActiveChartsResponseItem,
  useSendOrderQueue,
  useUpdateChartApplicationStatusList,
} from "modules/charts-shared/api";
import { OrderItem } from "modules/charts-shared/types";
import { useParams } from "react-router-dom";
import { extractApiErrorMessage } from "utils";
import { OrderFormCartItem } from "./OrderFormCartItem";
import { OrderHistoryPreview } from "./OrderHistoryPreview";
import { WarningDialog } from "shared";

type OrderFormCartProps = {
  items: OrderItem[];
  onDelete: (id: string) => void;
  onClear: () => void;
  chartCode: TriageChartCode | ProviderChartCode;
  isOrderQueueLoading?: boolean;
  isDeleteOrderQueueLoading?: boolean;
  isDisabled?: boolean;
  activeCharts?: UseActiveChartsResponseItem[];
};

function OrderFormCart({
  items,
  onDelete,
  onClear,
  isOrderQueueLoading,
  isDeleteOrderQueueLoading,
  isDisabled,
  activeCharts,
}: OrderFormCartProps) {
  const toast = useToast();

  const { encounterId = "" } = useParams();

  const { mutateAsync: sendOrderQueue, isLoading } = useSendOrderQueue({
    encounterId,
  });

  const {
    mutateAsync: updateChartStatus,
    isLoading: isLoadingChartStatusUpdate,
  } = useUpdateChartApplicationStatusList({
    encounterId,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleUpdateChartStatusRange = async () => {
    try {
      await updateChartStatus({
        chartApplicationIds:
          activeCharts?.map((c) => c.chartApplicationId) || [],
        status: "Incompleted",
      });
      toast({
        status: "success",
        description: formMessages.updateSuccess("Charts"),
      });
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }

    onClose();
  };

  const handleSendOrder = async () => {
    try {
      const orderIds = items.map((item) => item.id);
      await sendOrderQueue(orderIds);
      toast({
        status: "success",
        description: formMessages.createSuccess("Order"),
      });
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const onSendOrder = async () => {
    if ((activeCharts || []).some((c) => c.chartStatus === "Completed")) {
      onOpen();
    } else {
      handleSendOrder();
    }
  };

  return (
    <>
      <HStack alignItems="center">
        <Icon as={OrderQueue} color="gray.700" mr="15px" w="24px" h="24px" />
        <Text fontSize="1.125rem" fontWeight="bold">
          Order Queue
        </Text>
        <Box flex={1} />
        <Button
          variant="square"
          disabled={items.length === 0}
          onClick={onClear}
          color="gray.700"
          borderColor="gray.700"
          isLoading={isDeleteOrderQueueLoading}
        >
          Clear
        </Button>
        <Button
          variant="square"
          disabled={
            items.length === 0 ||
            isLoadingChartStatusUpdate ||
            isLoading ||
            isDisabled
          }
          onClick={onSendOrder}
          isLoading={isLoading || isLoadingChartStatusUpdate}
        >
          Send
        </Button>
      </HStack>
      <Divider my="10px" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        rowGap="10px"
      >
        {(isOrderQueueLoading || items.length === 0) && (
          <Text
            color="gray"
            fontSize="1.125rem"
            fontWeight="bold"
            h="100px"
            display="flex"
            alignItems="center"
          >
            {isOrderQueueLoading ? <Spinner /> : "Queue is empty"}
          </Text>
        )}

        {!isOrderQueueLoading &&
          items.length > 0 &&
          items.map((item) => (
            <OrderFormCartItem item={item} onDelete={() => onDelete(item.id)} />
          ))}
      </Box>
      <Divider my="10px" />
      <OrderHistoryPreview />
      <WarningDialog
        isOpen={isOpen}
        onCancel={onClose}
        title="Warning"
        mainText="One or more Charts have already been marked Complete."
        secondaryText="By pressing Continue, these Charts  will be Reopened."
        onClose={onClose}
        onAction={async () => {
          await handleSendOrder();
          await handleUpdateChartStatusRange();
        }}
        isActionLoading={isLoading || isLoadingChartStatusUpdate}
        cancelLabel="Cancel"
        actionLabel="Continue"
        blockScrollOnMount={false}
        cancelButtonProps={{ color: "gray.600" }}
        actionButtonProps={{ color: "blue" }}
      />
    </>
  );
}
export { OrderFormCart };
