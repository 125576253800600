import {
  Box,
  BoxProps,
  HStack,
  StackProps,
  useMergeRefs,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
} from "@chakra-ui/react";
import { config } from "config";
import * as React from "react";

type IconCardRadioProps = UseRadioGroupProps &
  Omit<StackProps, keyof UseRadioGroupProps>;

type IconCardRadioContextValue = {
  getRadioProps: ReturnType<typeof useRadioGroup>["getRadioProps"];
  isDisabled?: boolean;
};

const IconCardRadioContext =
  React.createContext<IconCardRadioContextValue | null>(null);

function useIconCardRadioContext() {
  const context = React.useContext(IconCardRadioContext);

  if (!context) {
    throw new Error(
      "Consumers of this context must be children of a Icon Card Radio component."
    );
  }

  return context;
}

type IconCardRadioItemProps = {
  value: string | number;
  icon: React.ReactNode;
  label: string;
} & BoxProps;

function IconCardRadioItem(props: IconCardRadioItemProps) {
  const { value, icon, label = false, ...boxProps } = props;
  const { getRadioProps, isDisabled } = useIconCardRadioContext();
  const radioProps = getRadioProps({ value });
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} disabled={isDisabled} />
      <Box
        {...checkbox}
        aria-disabled={isDisabled}
        display="flex"
        flexDirection="column"
        bg="white"
        width="116px"
        height="140px"
        borderRadius="10px"
        justifyContent="center"
        alignItems="center"
        color="gray.450"
        fontSize="1.0625rem"
        fontWeight="500"
        transition="all 200ms"
        _checked={{ borderColor: "blue", color: "blue", border: "1px" }}
        _focus={{ boxShadow: "var(--chakra-shadows-outline)" }}
        _disabled={{ opacity: 0.6, cursor: "not-allowed" }}
        {...boxProps}
      >
        {React.isValidElement(icon) &&
          React.cloneElement(icon, { w: "1.25rem", h: "1.25rem" })}
        <Box fontWeight="600" fontSize="1.0625rem">
          {label}
        </Box>
      </Box>
    </Box>
  );
}

const IconCardRadio = React.forwardRef<HTMLDivElement, IconCardRadioProps>(
  (props, ref) => {
    const {
      value,
      defaultValue,
      onChange,
      isDisabled,
      isFocusable,
      name,
      isNative,
      ...stackProps
    } = props;
    const { getRadioProps, getRootProps } = useRadioGroup({
      value,
      defaultValue,
      onChange,
      isDisabled,
      isFocusable,
      name,
      isNative,
    });

    const { ref: groupRef, ...rest } = getRootProps();
    const mergedRefs = useMergeRefs(ref, groupRef);

    const contextValue: IconCardRadioContextValue = React.useMemo(
      () => ({ getRadioProps, isDisabled }),
      [getRadioProps, isDisabled]
    );

    return (
      <IconCardRadioContext.Provider value={contextValue}>
        <HStack ref={mergedRefs} spacing="15px" {...stackProps} {...rest} />
      </IconCardRadioContext.Provider>
    );
  }
);

if (config.isDev) {
  IconCardRadio.displayName = "IconCardRadio";
}

export type { IconCardRadioProps };
export { IconCardRadio, IconCardRadioItem };
