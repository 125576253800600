import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UsePermissionsCountersResponse = {
  teams: number;
  enabledUsers: number;
  disabled: number;
};

function usePermissionsCounters() {
  return useAxiosQuery(["permissionsCounters"], () =>
    axiosIdentityInstance.get<UsePermissionsCountersResponse>(
      `/v1/user/permissions-sidebar`
    )
  );
}

export type { UsePermissionsCountersResponse };
export { usePermissionsCounters };
