import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseGetUserProfileResponse = {
  id: string;
  name: string;
  username: string;
  imgUrl: string;
  title: string;
  email: string;
  phone: string;
  teams: number;
  teamIds: {
    id: string;
  }[];
};

function useGetUserProfile(userId: string, options?: { enabled: boolean }) {
  return useAxiosQuery(
    ["getUserProfile", { userId }],
    () =>
      axiosIdentityInstance.get<UseGetUserProfileResponse>(
        `/v1/user/get-user/${userId}`
      ),
    { ...options }
  );
}

export type { UseGetUserProfileResponse };
export { useGetUserProfile };
