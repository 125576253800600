import {
  Box,
  Icon,
  Flex,
  useBreakpointValue,
  IconButton,
  Button,
  useDisclosure,
  Divider,
  DividerProps,
} from "@chakra-ui/react";
import {
  SiSearch,
  SiChevronLeft,
  EmrRadiology,
  EmrLabs,
  EmrInProcess,
  EmrRegistration,
} from "@medstonetech/slate-icons";
import * as React from "react";
import { OrderHeaderCard, OrdersNavigationBar } from "modules/orders";
import { useNavigate } from "react-router-dom";
import { SCROLLBARSTYLE } from "modules/labs/constants";
import { OrderGroup } from "enums";
import { OrdersList } from "./OrdersList";

type OrdersDashboardProps = {
  orderGroup?: OrderGroup;
};

const labsListStyles = {
  flex: { xl: "1" },
  mb: { base: "15px", xl: "0" },
  overflow: "hidden",
};

function VerticalSeparator(props: DividerProps) {
  const { ...rest } = props;
  return (
    <Divider
      orientation="vertical"
      borderColor="gray.450"
      margin="20px 5px 0px 5px"
      {...rest}
    />
  );
}

function OrdersDashboard(props: OrdersDashboardProps) {
  const { orderGroup = "Labs" } = props;
  const listCanCollapse = useBreakpointValue({ base: true, xl: false }, "xl");
  const navigate = useNavigate();
  const [expandedListIndex, setExpandedListIndex] = React.useState(0);

  const [sortDesc, setSortDesc] = React.useState({
    Ordered: true,
    InProcess: true,
    Completed: true,
  });

  const { isOpen, onToggle, onOpen } = useDisclosure({ defaultIsOpen: true });

  React.useEffect(() => {
    if (listCanCollapse) {
      setExpandedListIndex(0);
      onOpen();
    }
  }, [listCanCollapse, onOpen]);

  const setToggle = (index: number) => {
    if (listCanCollapse) {
      if (index === expandedListIndex) onToggle();
      else {
        setExpandedListIndex(index);
        onOpen();
      }
    }
  };

  const expandedAttributes = (indexList: number) => {
    const expanded = isOpen && expandedListIndex === indexList;
    if (listCanCollapse) {
      return {
        ...labsListStyles,
        expanded,
        ...(expanded && { flex: 1 }),
      };
    }
    return {
      expanded: true,
      ...labsListStyles,
    };
  };

  return (
    <Flex
      direction="column"
      backgroundColor="#f2f2f7"
      height="100vh"
      overflow={{ base: "auto", xl: "hidden" }}
      css={SCROLLBARSTYLE}
    >
      <OrdersNavigationBar
        icon={
          <Icon
            as={orderGroup === "Labs" ? EmrLabs : EmrRadiology}
            color="gray.650"
            fontSize="1.125rem"
          />
        }
        title={orderGroup}
        leftActions={[
          <Button
            fontSize="1.125rem"
            key="backButton"
            size="iconSm"
            minHeight="42px"
            padding="10px"
            variant="ghost"
            color="blue"
            onClick={() => {
              navigate("/home");
            }}
            leftIcon={<Icon as={SiChevronLeft} fontSize="1.125rem" />}
          >
            Back
          </Button>,
        ]}
        rightActions={[
          <IconButton
            key="searchButton"
            size="iconSm"
            aria-label="Search"
            variant="ghost"
            minHeight="42px"
            minWidth="42px"
            padding="10px"
            onClick={() => {
              navigate(`/${orderGroup.toLowerCase()}/search`);
            }}
            icon={<Icon as={SiSearch} color="blue" fontSize="1.375rem" />}
          />,
        ]}
      />
      <Box
        flex="1"
        overflow="hidden"
        height="100%"
        padding="30px 20px 0px 20px"
        paddingBottom={{ base: "30px", xl: "0" }}
      >
        <Flex direction={{ base: "column", xl: "row" }} height="100%">
          <OrdersList
            sortDesc={sortDesc.Ordered}
            filter="Ordered"
            {...expandedAttributes(0)}
            colorIcon="yellow"
            orderGroup={orderGroup}
            headerElement={
              <OrderHeaderCard
                title="Ordered"
                icon={<Icon as={EmrRegistration} />}
                bg="orange"
                counter={0}
                width="initial"
                showSortDesc
                sortDesc={sortDesc.Ordered}
                onSortChange={() =>
                  setSortDesc((prev) => ({ ...prev, Ordered: !prev.Ordered }))
                }
                onClick={() => setToggle(0)}
              />
            }
          />
          {!listCanCollapse && <VerticalSeparator />}
          <OrdersList
            sortDesc={sortDesc.InProcess}
            filter="InProcess"
            {...expandedAttributes(1)}
            colorIcon="blue"
            orderGroup={orderGroup}
            headerElement={
              <OrderHeaderCard
                title="In Process"
                icon={<Icon as={EmrInProcess} />}
                bg="blue"
                counter={0}
                width="initial"
                showSortDesc
                sortDesc={sortDesc.InProcess}
                onSortChange={() =>
                  setSortDesc((prev) => ({
                    ...prev,
                    InProcess: !prev.InProcess,
                  }))
                }
                onClick={() => setToggle(1)}
              />
            }
          />
          {!listCanCollapse && <VerticalSeparator />}
          <OrdersList
            sortDesc={sortDesc.Completed}
            filter="Completed"
            {...expandedAttributes(2)}
            colorIcon="green"
            orderGroup={orderGroup}
            headerElement={
              <OrderHeaderCard
                title="Completed (Last 2 Hrs)"
                icon={<Icon as={EmrInProcess} />}
                bg="green"
                counter={0}
                width="initial"
                showSortDesc
                sortDesc={sortDesc.Completed}
                onSortChange={() =>
                  setSortDesc((prev) => ({
                    ...prev,
                    Completed: !prev.Completed,
                  }))
                }
                onClick={() => setToggle(2)}
              />
            }
          />
        </Flex>
      </Box>
    </Flex>
  );
}

export { OrdersDashboard };
