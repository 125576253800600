import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  ModalFooter,
  Button,
  VStack,
  ButtonProps,
} from "@chakra-ui/react";
import * as React from "react";
import { UNSAFE_NavigationContext, Navigator } from "react-router-dom";
import { History } from "history";

type OrderPromptProps = {
  when: boolean;
  isSaveLoading?: boolean;
  onContinue?: () => void;
};

const DEFAULT_BTN_STYLE_PROPS: ButtonProps = {
  mx: 0.5,
  my: 1,
  variant: "label",
  padding: "0.75rem .25rem",
  borderRadius: "15px",
  fontSize: ".9625rem",
  fontWeight: "400",
  width: "90%",
  color: "white",
};

function OrderPrompt(props: OrderPromptProps) {
  const { when, isSaveLoading, onContinue } = props;
  const history = React.useContext(UNSAFE_NavigationContext)
    .navigator as Navigator & Partial<Pick<History, "block">>;
  window.onbeforeunload = null;
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState("");
  const unblockRef = React.useRef<() => void>();

  const handleBeforeUnload = React.useCallback(
    (event: BeforeUnloadEvent) => {
      if (when) {
        event.preventDefault();
        event.returnValue = ""; // Required for Chrome
      }
    },
    [when]
  );

  const continueNavigation = () => {
    unblockRef.current?.();
    history.push(currentPath);
    setShowPrompt(false);
  };

  const onCancel = () => {
    setShowPrompt(false);
    window.removeEventListener("beforeunload", handleBeforeUnload);
    onContinue?.();
  };

  React.useEffect(() => {
    if (when) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      unblockRef.current = history.block?.((location) => {
        setCurrentPath(location.location.pathname);
        setShowPrompt(true);
        return false;
      });
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unblockRef.current?.();
    };
  }, [when, history, handleBeforeUnload]);

  return (
    <Modal
      onClose={onCancel}
      isOpen={showPrompt}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxWidth="395px"
        width="395px"
        borderRadius="1rem"
        bg="#E5E5E5"
      >
        <ModalHeader
          padding="16px 28px 0"
          textAlign="center"
          fontSize="1.2625rem"
          fontWeight="600"
        >
          Warning!
        </ModalHeader>
        <ModalBody
          padding="16px 28px 10px"
          fontSize="1.0125rem"
          lineHeight="1.125rem"
        >
          <Box textAlign="center">
            There are still Orders in the Order Queue
          </Box>
        </ModalBody>
        <ModalFooter px="20px" pt="15px" pb="20px" justifyContent="center">
          <VStack width="100%" spacing={4}>
            <Button
              onClick={continueNavigation}
              backgroundColor="gray.600"
              {...DEFAULT_BTN_STYLE_PROPS}
            >
              Ignore and Continue
            </Button>
            <Button
              onClick={onCancel}
              backgroundColor="blue"
              isDisabled={isSaveLoading}
              {...DEFAULT_BTN_STYLE_PROPS}
            >
              Close
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export type { OrderPromptProps };
export { OrderPrompt };
