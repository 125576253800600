import { createIcon } from "@chakra-ui/react";

const UpArrowBold = createIcon({
  path: (
    <path d="M8.51967 20.7393H15.4694C17.0942 20.7393 17.9726 19.7841 17.9726 18.236V13.4602H22.2873C23.2644 13.4602 24 12.8783 24 11.9671C24 11.4071 23.7365 11.0229 23.2644 10.5398L13.4382 0.691674C12.9881 0.241537 12.516 0 12 0C11.484 0 11.0009 0.241537 10.5618 0.691674L0.73559 10.5398C0.241537 11.0339 0 11.4071 0 11.9671C0 12.8783 0.73559 13.4602 1.70174 13.4602H6.02745V18.236C6.02745 19.7841 6.90576 20.7393 8.51967 20.7393Z" />
  ),
  viewBox: "0 0 24 21",
});

export { UpArrowBold };
