import * as React from "react";
import { FormPrompt, FormPromptWithSaveProps } from "shared/prompt";
import {
  HTMLChakraProps,
  chakra,
  // Button,
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useChartTrackingContext } from "contexts";
import { useListChartElements } from "modules/charts-shared/hooks";
import { useLocation } from "react-router-dom";
// import { Brain } from "icons";
import { useChartSuggestions } from "modules/charts-shared/api";
// import html2canvas from "html2canvas";

type ChartFormProps = {
  onRouteChangeSave: FormPromptWithSaveProps["onSave"];
  isPromptSaveLoading: FormPromptWithSaveProps["isSaveLoading"];
  isDirty: boolean;
  disabled?: boolean;
  sectionId?: string;
  setChartElementsList?(chartElementList: Record<string, string>[]): void;
} & React.ComponentProps<"form"> &
  HTMLChakraProps<"form">;

function ChartForm(props: ChartFormProps) {
  const {
    onRouteChangeSave,
    isDirty,
    isPromptSaveLoading,
    sectionId,
    children,
    ...formProps
  } = props;

  const formRef = React.useRef<HTMLFormElement>(null);

  const secondaryText = React.useMemo(
    () =>
      ["SHPI", "SROS", "SPEX", "SCHC", "SASS"].includes(sectionId || "")
        ? `You must fill out every subsection
        for this section before the chart can be completed.`
        : "",
    [sectionId]
  );

  const { updateChartElements } = useChartTrackingContext();
  useListChartElements(children, updateChartElements);
  const location = useLocation();
  const fromMedicalRecords = location.pathname.includes("medical-records");
  const aiResultsDisclose = useDisclosure();
  const { mutateAsync: _generateSuggestions, isLoading } =
    useChartSuggestions();
  const [suggestions, _setSuggestions] = React.useState("");

  // const handleGenerateSuggestions = async () => {
  //   if (formRef.current) {
  //     const canvas = await html2canvas(formRef.current);
  //     const blob = await new Promise<Blob>((resolve) => {
  //       canvas.toBlob((x) => {
  //         if (x) resolve(x);
  //       }, "image/png");
  //     });

  //     const { data } = await generateSuggestions({ file: blob });
  //     setSuggestions(data.suggestions || "");
  //     aiResultsDisclose.onOpen();
  //   }
  // };

  return (
    <>
      <FormPrompt
        mainText="You have unsaved changes in this section!"
        onSave={onRouteChangeSave}
        title="Warning"
        when={isDirty}
        isSaveLoading={isPromptSaveLoading}
        secondaryText={secondaryText}
      />
      <Box position="relative" height="100%">
        <chakra.fieldset
          disabled={fromMedicalRecords}
          minW={fromMedicalRecords ? "100vw" : "unset"}
        >
          <chakra.form
            ref={formRef}
            padding="1.5rem"
            sx={{ "> :not(:last-child)": { marginBottom: "1rem" } }}
            height="100%"
            children={children}
            {...formProps}
          />
        </chakra.fieldset>
        {/* <Button
          variant="outline"
          leftIcon={<Brain color="cyan" />}
          position="fixed"
          bottom="2rem"
          right="2rem"
          color="cyan"
          isLoading={isLoading}
          onClick={handleGenerateSuggestions}
          boxShadow="2xl"
          zIndex={999}
          borderColor="cyan"
          sx={{
            svg: {
              transition: "0.2s ease-in-out",
            },
            "&:hover svg": {
              color: "blue",
            },
            "&:active svg": {
              color: "blue",
            },
          }}
        >
          IA
        </Button> */}
      </Box>
      <Modal
        isOpen={aiResultsDisclose.isOpen}
        onClose={aiResultsDisclose.onClose}
        motionPreset="slideInRight"
      >
        <ModalOverlay />
        <ModalContent
          containerProps={{ justifyContent: "flex-end", paddingRight: "2rem" }}
          background="gray.200"
          h="85%"
        >
          <ModalHeader
            display="flex"
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box />
            <chakra.span fontSize="16px">AI Suggestions</chakra.span>
            <chakra.span
              fontSize="16px"
              cursor="pointer"
              onClick={aiResultsDisclose.onClose}
              color="blue"
            >
              {isLoading ? <Spinner color="blue" /> : "Done"}
            </chakra.span>
          </ModalHeader>
          <ModalBody
            display="flex"
            flexDir="column"
            gap="1rem"
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "gray.200",
                borderRadius: "24px",
              },
            }}
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: suggestions
                  .replace(/\n/g, "<br />")
                  .replace(
                    /####/g,
                    '<h4 style="font-size: 1.1em; font-weight: bold; margin-top: 1em;">'
                  )
                  .replace(
                    /###/g,
                    '<h3 style="font-size: 1.2em; font-weight: bold; margin-top: 1.5em;">'
                  )
                  .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
                  .replace(/- /g, "• "),
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export { ChartForm };
