import { createIcon } from "@chakra-ui/react";

const Sun = createIcon({
  path: (
    <path d="M13.0733 1.09481C13.0733 0.504472 12.5689 0 11.9785 0C11.3882 0 10.8945 0.504472 10.8945 1.09481V3.2737C10.8945 3.86404 11.3882 4.35778 11.9785 4.35778C12.5689 4.35778 13.0733 3.86404 13.0733 3.2737V1.09481ZM17.3667 5.08766C16.9481 5.50626 16.9481 6.20394 17.3775 6.62254C17.7853 7.04114 18.4937 7.04114 18.9123 6.61181L20.458 5.06619C20.8766 4.65832 20.8766 3.93918 20.458 3.52057C20.0394 3.1127 19.3417 3.1127 18.9231 3.53131L17.3667 5.08766ZM5.04472 6.61181C5.45259 7.04114 6.161 7.04114 6.57961 6.62254C6.99821 6.21467 6.99821 5.49553 6.59034 5.08766L5.04472 3.53131C4.63685 3.12343 3.92844 3.1127 3.50984 3.52057C3.09123 3.93918 3.09123 4.65832 3.49911 5.05546L5.04472 6.61181ZM11.9785 6.34347C8.8873 6.34347 6.322 8.89803 6.322 12C6.322 15.102 8.8873 17.6565 11.9785 17.6565C15.0698 17.6565 17.6243 15.102 17.6243 12C17.6243 8.89803 15.0698 6.34347 11.9785 6.34347ZM22.873 13.0948C23.4633 13.0948 23.9571 12.5903 23.9571 12C23.9571 11.4097 23.4633 10.9159 22.873 10.9159H20.6941C20.1038 10.9159 19.61 11.4097 19.61 12C19.61 12.5903 20.1038 13.0948 20.6941 13.0948H22.873ZM1.09481 10.9159C0.493739 10.9159 0 11.4097 0 12C0 12.5903 0.493739 13.0948 1.09481 13.0948H3.26297C3.85331 13.0948 4.35778 12.5903 4.35778 12C4.35778 11.4097 3.85331 10.9159 3.26297 10.9159H1.09481ZM18.9016 17.3882C18.483 16.9696 17.7853 16.9803 17.3667 17.3989C16.9481 17.8068 16.9481 18.5045 17.3775 18.9338L18.9338 20.4794C19.3417 20.898 20.0501 20.8873 20.4687 20.4687C20.8766 20.0501 20.8766 19.3524 20.458 18.9338L18.9016 17.3882ZM3.49911 18.9231C3.0805 19.3417 3.06977 20.0501 3.47764 20.458C3.89624 20.8766 4.61538 20.8873 5.02326 20.4794L6.57961 18.9338C6.99821 18.5152 6.99821 17.8068 6.59034 17.3989C6.17174 16.9803 5.46333 16.9803 5.04472 17.3882L3.49911 18.9231ZM13.0733 20.7263C13.0733 20.136 12.5689 19.6422 11.9785 19.6422C11.3882 19.6422 10.8945 20.136 10.8945 20.7263V22.9052C10.8945 23.4955 11.3882 24 11.9785 24C12.5689 24 13.0733 23.4955 13.0733 22.9052V20.7263Z" />
  ),
  viewBox: "0 0 24 24",
});

export { Sun };
