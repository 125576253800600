import { AuthenticatedRoutes, UnauthenticatedRoutes } from "routes";
import { FullPageLoading, MaintenancePage } from "pages";
import { setGlobalResetTimer, useSetupAuth0, useToast } from "hooks";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useDisclosure,
  chakra,
  ModalFooter,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { usePermissions } from "contexts";
import { LockWarning } from "icons";
import { Button } from "shared";
import { config } from "config";

const INACTIVITY_LIMIT = 10 * 60 * 1000; // 10 minutes

type Settings = {
  allowed: boolean;
  mode: "maintenance" | "normal";
} | null;

function SessionExpiredModal(props: Omit<ModalProps, "children">) {
  const { logout } = useAuth0();

  const handleLogout = () => logout({ returnTo: config.auth0LogoutUrl });

  return (
    <Modal isCentered {...props} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent userSelect="none">
        <ModalHeader
          display="flex"
          justifyContent="center"
          flexDir="column"
          gap="35px"
          alignItems="center"
        >
          <LockWarning color="gray.700" fontSize="72px" />
          <chakra.span fontWeight="600">You’ve been logged out!</chakra.span>
        </ModalHeader>
        <ModalBody display="flex" flexDir="column" justifyContent="center">
          <chakra.span textAlign="center">
            We noticed you aren’t active in this session, so you have been
            logged out for your safety. <br /> <br /> Please click on the button
            to log in again.
          </chakra.span>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button minW="287px" minH="52px" onClick={handleLogout}>
            Log In Again
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function UnauthenticatedApp() {
  return (
    <Box height="100vh">
      <UnauthenticatedRoutes />
    </Box>
  );
}

function AuthenticatedApp() {
  const authReady = useSetupAuth0();
  const { isLoadingPermissions, claims } = usePermissions();
  const settings = claims?.settings as Settings;

  if (!authReady || isLoadingPermissions) {
    return <FullPageLoading />;
  }

  return (
    <Box height="100vh">
      <AuthenticatedRoutes />
      {settings?.mode === "maintenance" && !settings?.allowed && (
        <MaintenancePage />
      )}
    </Box>
  );
}

export const App = () => {
  const { isLoading, error, isAuthenticated } = useAuth0();
  const toast = useToast();

  const sessionExpiredDisclose = useDisclosure();

  useEffect(() => {
    if (!isAuthenticated) return;

    let inactivityTimeout: NodeJS.Timeout;

    const resetInactivityTimer = () => {
      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }
      inactivityTimeout = setTimeout(() => {
        sessionExpiredDisclose.onOpen();
      }, INACTIVITY_LIMIT);
    };

    const activityEvents = [
      "mousemove",
      "mousedown",
      "keypress",
      "scroll",
      "touchstart",
      "click",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "touchmove",
      "MSPointerMove",
    ];

    let debounceTimer: NodeJS.Timeout;
    const debouncedResetTimer = () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      debounceTimer = setTimeout(() => {
        resetInactivityTimer();
      }, 1000);
    };

    activityEvents.forEach((event) => {
      document.addEventListener(event, debouncedResetTimer, { passive: true });
    });

    setGlobalResetTimer(resetInactivityTimer);

    resetInactivityTimer();

    return () => {
      activityEvents.forEach((event) => {
        document.removeEventListener(event, debouncedResetTimer);
      });

      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      setGlobalResetTimer(() => {});
    };
  }, [isAuthenticated, sessionExpiredDisclose]);

  useEffect(() => {
    if (error) {
      toast({
        description: error?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  if (isLoading) {
    return <FullPageLoading />;
  }

  if (isAuthenticated) {
    return (
      <>
        <AuthenticatedApp />
        <SessionExpiredModal {...sessionExpiredDisclose} />
      </>
    );
  }

  return <UnauthenticatedApp />;
};

export default App;
