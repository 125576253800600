import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteUserSignature(userId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteUserSignature"],
    () => axiosIdentityInstance.delete(`/v1/user/signature/${userId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUserSignature");
        queryClient.invalidateQueries("searchEnabledUsers");
        queryClient.invalidateQueries("searchDisabledUsers");
        queryClient.invalidateQueries("permissionsCounters");
        queryClient.invalidateQueries(["getUser", { userId }]);
      },
    }
  );
}

export { useDeleteUserSignature };
