type MedicalSuggestionResult = {
  success: boolean;
  suggestions: string | null;
  errorMessage: string | null;
};

type ChartSuggestionInput = {
  file: Blob | File;
};

import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";

function useChartSuggestions() {
  return useAxiosMutation(
    ["chartSuggestions"],
    async ({ file }: ChartSuggestionInput) => {
      const formData = new FormData();
      formData.append("file", file);

      return axiosChartInstance.post<MedicalSuggestionResult>(
        "v1/chart/suggestion",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
  );
}

export { useChartSuggestions, type MedicalSuggestionResult };
