import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  BoxProps,
  Icon,
  chakra,
  Flex,
  VStack,
  Image,
  Text,
} from "@chakra-ui/react";
import { EmrSignature, SiDelete } from "@medstonetech/slate-icons";
import { ChartRowElement } from "modules/charts-shared";
import { useGetUserSignature } from "modules/onboarding/api";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Card, Input, StaffAvatar } from "shared";
import { USERID_CLAIMS } from "system-constants";

type IvStartsCardProps = {
  num: number;
};

const GRID_PROPS: BoxProps = {
  textAlign: "center",
  display: "grid",
  gridTemplateColumns: "1.2fr repeat(4, 0.5fr) 6fr 1fr",
  gridColumnGap: 3,
  alignItems: "center",
};

function IvStartsCardHeader() {
  return (
    <Card
      bg="gray.250"
      marginBottom="1rem"
      padding="1rem 1.5rem"
      boxShadow="none"
      {...GRID_PROPS}
    >
      <chakra.span>Date / Time</chakra.span>
      <chakra.span>#</chakra.span>
      <chakra.span>Site</chakra.span>
      <chakra.span>Ga</chakra.span>
      <chakra.span>Attempts</chakra.span>
      <chakra.span textAlign="start" ml={4}>
        Inserted By
      </chakra.span>
      <chakra.span>Delete IV</chakra.span>
    </Card>
  );
}

function IvStartsSignature(props: { index: number }) {
  const { index } = props;

  const { watch, setValue } = useFormContext();

  const insertedBy = watch(`ivStarts[${index}].insertedBy`);

  const { data: signature } = useGetUserSignature(insertedBy || "");

  const { user } = useAuth0();

  const handleSign = () =>
    setValue(`ivStarts[${index}].insertedBy`, user?.[USERID_CLAIMS], {
      shouldDirty: true,
    });

  const handleRemoveSign = () =>
    setValue(`ivStarts[${index}].insertedBy`, undefined, {
      shouldDirty: true,
    });

  return (
    <Box height="100px" p={6} width="100%">
      <Flex width="100%" justifyContent="end" alignItems="center">
        {insertedBy ? (
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Flex alignItems="start" gap={2}>
              <StaffAvatar
                w="40px"
                h="40px"
                size="xs"
                fontSize=".75rem"
                userName={signature?.data?.fullName}
                profileUrl={signature?.data?.pictureUrl}
              />
              <VStack spacing="0" alignItems="flex-start">
                <Box
                  fontSize="1.0625rem"
                  fontWeight="600"
                  lineHeight="1.5rem"
                  color="black"
                >
                  {signature?.data?.fullName}
                </Box>
                <Box
                  fontSize="0.875rem"
                  fontWeight="400"
                  lineHeight="1rem"
                  color="gray.700"
                >
                  {signature?.data?.teams}
                </Box>
              </VStack>
            </Flex>

            <Box minW={300} maxW={400} minH={50}>
              <Image
                src={signature?.data?.signature}
                height="100%"
                width="100%"
              />
            </Box>

            <Button variant="label" color="red" onClick={handleRemoveSign}>
              <Icon as={SiDelete} fontSize="1.5rem" color="red" m={2} />
              Remove Signature
            </Button>
          </Flex>
        ) : (
          <Button variant="label" color="blue" onClick={handleSign}>
            <Icon as={EmrSignature} fontSize="1.5rem" color="blue" m={2} />
            Add Signature
          </Button>
        )}
      </Flex>
    </Box>
  );
}

function IvStartsCard(props: IvStartsCardProps) {
  const { num } = props;
  const { register, control, getValues } = useFormContext();
  const { remove } = useFieldArray({ control, name: "ivStarts" });
  const { append } = useFieldArray({ control, name: "deletedIvStarts" });

  const handleRemoveIvStart = () => {
    append(getValues(`ivStarts[${num}].id`));
    remove(num);
  };

  return (
    <Card boxShadow="none" padding="1rem 1.5rem">
      <Box {...GRID_PROPS}>
        <ChartRowElement
          type="date"
          name={`ivStarts[${num}].date`}
          width="170px"
        />
        <Text>{num + 1}</Text>
        <Input {...register(`ivStarts[${num}].site`)} />
        <Input {...register(`ivStarts[${num}].ga`)} />
        <Input {...register(`ivStarts[${num}].attempts`)} />
        <IvStartsSignature index={num} />

        {!getValues(`ivStarts[${num}].isSelected`) && (
          <Button
            variant="action"
            onClick={handleRemoveIvStart}
            color="red"
            borderColor="red"
            maxH="36px"
          >
            Delete IV
          </Button>
        )}
      </Box>
      <Box display="grid" gridTemplateColumns="4fr 0.5fr">
        <Flex direction="row" gap="1rem" alignItems="center">
          <chakra.span>Comments:</chakra.span>
          <ChartRowElement
            type="input"
            {...register(`ivStarts[${num}].comments`)}
          />
        </Flex>
      </Box>
    </Card>
  );
}

export { IvStartsCard, IvStartsCardHeader };
export type { IvStartsCardProps };
