import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsectionContent,
} from "modules/charts-shared";
import { RADIOLOGY_GRID_COLUMNS } from "../radiology-chart/constants";
import {
  InterpretationChartItemProps,
  InterpretationChartList,
  InterpretationChartProps,
} from "../radiology-chart/types";

function T001MRIChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="MRI done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Interpretation:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml/NAD:"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A015`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A017`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A018`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T024MRIChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="MRI done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml/NAD:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T035MRIChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="MRI done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A014`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A017`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A023`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A024`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T039MRIChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="MRI done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A004`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A008`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A009`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A010`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A011`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A012`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A014`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T044MRIChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="MRI done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="chest"
            name={`${sectionDelimiter}A002`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T046MRIChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="MRI done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A003`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="interpretation:"
            name={`${sectionDelimiter}A004`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A005`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A006`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A007`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A008`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml / NAD"
            name={`${sectionDelimiter}A009`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A010`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A011`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A012`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A013`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function T073MRIChart(props: InterpretationChartItemProps) {
  const { sectionDelimiter } = props;

  return (
    <ChartSubsectionContent>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="MRI done:"
            name={`${sectionDelimiter}A001`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A014`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="Interpretation:"
            name={`${sectionDelimiter}A015`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            label="interpreted by me"
            name={`${sectionDelimiter}A016`}
            type="option"
          />
          <ChartRowElement
            label="viewed by me"
            name={`${sectionDelimiter}A017`}
            type="option"
          />
          <ChartRowElement
            label="discussed by radiologist"
            name={`${sectionDelimiter}A018`}
            type="option"
          />
          <ChartRowElement name={`${sectionDelimiter}A019`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="nml/NAD:"
            name={`${sectionDelimiter}A020`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A021`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement
            label="abnormal:"
            name={`${sectionDelimiter}A022`}
            type="option"
          />
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A023`} type="input" />
        </ChartRowGroup>
      </ChartRow>
      <ChartRow gridTemplateColumns={RADIOLOGY_GRID_COLUMNS}>
        <ChartRowGroup>
          <ChartRowElement type="empty" />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement name={`${sectionDelimiter}A024`} type="input" />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
}

function MRIChart(props: InterpretationChartProps) {
  const { chartCode, sectionId, sectionCode, subsectionId, index } = props;
  const sectionDelimiter = `rad.${index}.${chartCode}${sectionId}${sectionCode}${subsectionId}`;

  const chartItems: InterpretationChartList = {
    C001x: <T001MRIChart sectionDelimiter={sectionDelimiter} />,
    C024x: <T024MRIChart sectionDelimiter={sectionDelimiter} />,
    C035x: <T035MRIChart sectionDelimiter={sectionDelimiter} />,
    C039x: <T039MRIChart sectionDelimiter={sectionDelimiter} />,
    C044x: <T044MRIChart sectionDelimiter={sectionDelimiter} />,
    C046x: <T046MRIChart sectionDelimiter={sectionDelimiter} />,
    C073x: <T073MRIChart sectionDelimiter={sectionDelimiter} />,
  };

  return chartItems[chartCode] || null;
}

export { MRIChart };
