import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import {
  ChartSection,
  ChartSectionHeader,
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartRouteBaseParams,
  ChartSubsectionHeader,
} from "modules";
import { useTriageChartProceduresForm } from "../../hooks";
import {
  ACTIONS_COLUMNS_GAP,
  ACTIONS_ROW_TEMPLATE_COLUMNS_4,
  CONTAINER_PADDING_2,
} from "modules/triage/shared/actions/constants";
import { ActionsHeaderDescription } from "modules/triage/shared";
import { usePermissions } from "contexts";
import { useParams } from "react-router-dom";

const sectionId = "SPRO";
const chartCode = "T040x";

function N40Procedures() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartProceduresForm({
    chartCode: chartCode,
    sectionId,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:proc").isEditable;

  const { getValues } = useFormReturn;
  const sections = getValues("sections");

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection onSubmit={onSubmit} disabled={isReadOnly}>
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ChartSubsectionHeader
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          subsectionId="Q001"
          subsectionLabel=""
          showNotesBtn={false}
        />
        <ChartSubsection content={<ActionsHeaderDescription />} />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[0] && sections[0].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[0] && sections[0].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[0].${chartCode}${sectionId}Q001A001`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="portable"
                    name={`sections[0].${chartCode}${sectionId}Q001A002`}
                    type="option"
                  />
                  <ChartRowElement
                    label="to Xray"
                    name={`sections[0].${chartCode}${sectionId}Q001A003`}
                    type="option"
                  />
                  <ChartRowElement
                    label="w/:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[0].${chartCode}${sectionId}Q001A057`}
                  />
                  <ChartRowElement
                    label="monitor"
                    name={`sections[0].${chartCode}${sectionId}Q001A004`}
                    type="option"
                  />
                  <ChartRowElement
                    label="nurse"
                    name={`sections[0].${chartCode}${sectionId}Q001A005`}
                    type="option"
                  />
                  <ChartRowElement
                    label="O2"
                    name={`sections[0].${chartCode}${sectionId}Q001A006`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[0].${chartCode}${sectionId}Q001A007`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[0].${chartCode}${sectionId}Q001A008`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[1] && sections[1].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[1] && sections[1].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[1].${chartCode}${sectionId}Q001A009`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="return to room"
                    name={`sections[1].${chartCode}${sectionId}Q001A010`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[1].${chartCode}${sectionId}Q001A011`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[2] && sections[2].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[2] && sections[2].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[2].${chartCode}${sectionId}Q001A012`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="to CT"
                    name={`sections[2].${chartCode}${sectionId}Q001A013`}
                    type="option"
                  />
                  <ChartRowElement
                    label="w/:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[2].${chartCode}${sectionId}Q001A057`}
                  />
                  <ChartRowElement
                    label="contrast"
                    name={`sections[2].${chartCode}${sectionId}Q001A014`}
                    type="option"
                  />
                  <ChartRowElement
                    label="parenteral meds"
                    name={`sections[2].${chartCode}${sectionId}Q001A015`}
                    type="option"
                  />
                  <ChartRowElement
                    label="O2"
                    name={`sections[2].${chartCode}${sectionId}Q001A016`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[2].${chartCode}${sectionId}Q001A017`}
                    type="option"
                  />
                  <ChartRowElement
                    label="w/:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[2].${chartCode}${sectionId}Q001A058`}
                  />
                  <ChartRowElement
                    label="monitor"
                    name={`sections[2].${chartCode}${sectionId}Q001A018`}
                    type="option"
                  />
                  <ChartRowElement
                    label="nurse"
                    name={`sections[2].${chartCode}${sectionId}Q001A019`}
                    type="option"
                  />
                  <ChartRowElement
                    label="O2"
                    name={`sections[2].${chartCode}${sectionId}Q001A020`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[2].${chartCode}${sectionId}Q001A021`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${chartCode}${sectionId}Q001A022`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="return to room"
                    name={`sections[3].${chartCode}${sectionId}Q001A023`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${chartCode}${sectionId}Q001A024`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[4] && sections[4].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[4] && sections[4].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[4].${chartCode}${sectionId}Q001A025`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="labs drawn"
                    name={`sections[4].${chartCode}${sectionId}Q001A026`}
                    type="option"
                  />
                  <ChartRowElement
                    label="labs sent"
                    name={`sections[4].${chartCode}${sectionId}Q001A027`}
                    type="option"
                  />
                  <ChartRowElement
                    label="by:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[4].${chartCode}${sectionId}Q001A057`}
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[4].${chartCode}${sectionId}Q001A028`}
                    type="option"
                  />
                  <ChartRowElement
                    label="nurse"
                    name={`sections[4].${chartCode}${sectionId}Q001A029`}
                    type="option"
                  />
                  <ChartRowElement
                    label="lab"
                    name={`sections[4].${chartCode}${sectionId}Q001A030`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[4].${chartCode}${sectionId}Q001A031`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[5] && sections[5].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[5] && sections[5].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[5].${chartCode}${sectionId}Q001A032`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="12 lead EKG performed"
                    name={`sections[5].${chartCode}${sectionId}Q001A033`}
                    type="option"
                  />
                  <ChartRowElement
                    label="notified"
                    name={`sections[5].${chartCode}${sectionId}Q001A034`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[5].${chartCode}${sectionId}Q001A035`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[6] && sections[6].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[6] && sections[6].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[6].${chartCode}${sectionId}Q001A036`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="repeat EKG done x"
                    name={`sections[6].${chartCode}${sectionId}Q001A037`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[6].${chartCode}${sectionId}Q001A038`}
                    w="50px"
                    minW="50px"
                    type="input"
                  />
                  <ChartRowElement
                    name={`sections[6].${chartCode}${sectionId}Q001A039`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[8] && sections[8].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[8] && sections[8].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[8].${chartCode}${sectionId}Q001A040`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="LP tray set up"
                    name={`sections[8].${chartCode}${sectionId}Q001A041`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[8].${chartCode}${sectionId}Q001A042`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[10] && sections[10].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[10] && sections[10].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[10].${chartCode}${sectionId}Q001A043`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="consent signed"
                    name={`sections[10].${chartCode}${sectionId}Q001A044`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[10].${chartCode}${sectionId}Q001A045`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[11] && sections[11].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[11] && sections[11].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[11].${chartCode}${sectionId}Q001A046`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="assisted with LP"
                    name={`sections[11].${chartCode}${sectionId}Q001A047`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tolerated well"
                    name={`sections[11].${chartCode}${sectionId}Q001A048`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[11].${chartCode}${sectionId}Q001A049`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[12] && sections[12].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[12] && sections[12].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[12].${chartCode}${sectionId}Q001A050`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="spinal fluid to lab"
                    name={`sections[12].${chartCode}${sectionId}Q001A051`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[12].${chartCode}${sectionId}Q001A052`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[13] && sections[13].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[13] && sections[13].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[13].${chartCode}${sectionId}Q001A053`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="Foley"
                    name={`sections[13].${chartCode}${sectionId}Q001A054`}
                    type="option"
                  />
                  <ChartRowElement
                    label="straight cath"
                    name={`sections[13].${chartCode}${sectionId}Q001A055`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[13].${chartCode}${sectionId}Q001A056`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N40Procedures };
