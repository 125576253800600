import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteTeam() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteTeam"],
    (teamId: string) => axiosIdentityInstance.delete(`/v1/team/${teamId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("teamsList");
        queryClient.invalidateQueries("teamsMin");
        queryClient.invalidateQueries("permissionsCounters");
      },
    }
  );
}

export { useDeleteTeam };
