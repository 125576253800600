import * as React from "react";

import {
  ChartForm as ChartFormType,
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartRowElementImportedProps,
  ChartRowGroup,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
  mapFormToPayload,
  mapPayloadToFormObject,
  useChartApplication,
  useSaveAndNext,
  useUpdateChartApplication,
} from "modules";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Icon, useToast } from "@chakra-ui/react";
import { useChartTrackingContext, usePermissions } from "contexts";

import { ChartForm } from "modules/charts-shared-v2";
import { EmrPerson } from "@medstonetech/slate-icons";
import { FormPromptWithSaveProps } from "shared/prompt";
import { Loading } from "shared";
import { PHYSICAL_EXAM_GRID_COLUMNS } from "modules/provider/shared";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useParams } from "react-router-dom";

const sectionId = "SPEX";
const chartCode = "C036x";

function T36PhysicalExam() {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const useFormContext = useForm<ChartFormType>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
    watch,
  } = useFormContext;
  const { scope } = usePermissions();
  const isReadOnly = !scope("provider:pex").isEditable;

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: updateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const toast = useToast();
  const formObject = getValues();

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const onSubmit: SubmitHandler<ChartFormType> = React.useCallback(
    async (values) => {
      try {
        const payload = mapFormToPayload(values, ["Q001"]);
        await updateChartApplication({
          input: payload,
          chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
        });
        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );
        // Reset form with latest values to set isDirty to false
        reset(values);
        toast({ description: formMessages.updateSuccess("Physical Exam") });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      updateChartApplication,
      trackChartInteractions,
      data?.data,
      encounterId,
      reset,
      toast,
      getValues,
    ]
  );

  const handleSaveAndNext = useSaveAndNext(
    handleSubmit(onSubmit),
    "physical-exam",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      const payload = mapFormToPayload(formObject, ["Q001"]);
      await updateChartApplication({
        input: payload,
        chartFavoriteId: getValues("chartFavoriteId") as unknown as string,
      });
      await trackChartInteractions(
        data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
        payload,
        {
          encounterId,
          chartCode,
          sectionId,
          chartType: "application",
        }
      );
      toast({ description: formMessages.updateSuccess("Physical Exam") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  if (getIsLoading) {
    return <Loading />;
  }

  const q002AllNormal = Boolean(watch(`Q002.${chartCode}${sectionId}Q002A001`));
  const q003AllNormal = Boolean(watch(`Q003.${chartCode}${sectionId}Q003A001`));
  const q004AllNormal = Boolean(watch(`Q004.${chartCode}${sectionId}Q004A001`));
  const q005AllNormal = Boolean(watch(`Q005.${chartCode}${sectionId}Q005A001`));
  const q006AllNormal = Boolean(watch(`Q006.${chartCode}${sectionId}Q006A001`));
  const q007AllNormal = Boolean(watch(`Q007.${chartCode}${sectionId}Q007A001`));
  const q008AllNormal =
    Boolean(watch(`Q008.${chartCode}${sectionId}Q008A001`)) ||
    Boolean(watch(`Q008.${chartCode}${sectionId}Q008A020`));
  const q009AllNormal =
    Boolean(watch(`Q009.${chartCode}${sectionId}Q009A001`)) ||
    Boolean(watch(`Q009.${chartCode}${sectionId}Q009A011`));
  const q010AllNormal =
    Boolean(watch(`Q010.${chartCode}${sectionId}Q010A001`)) ||
    Boolean(watch(`Q010.${chartCode}${sectionId}Q010A016`));
  const q011AllNormal = Boolean(watch(`Q011.${chartCode}${sectionId}Q011A001`));
  const q012AllNormal = Boolean(watch(`Q012.${chartCode}${sectionId}Q012A001`));
  const q013AllNormal = Boolean(watch(`Q013.${chartCode}${sectionId}Q013A001`));
  const q014AllNormal = Boolean(watch(`Q014.${chartCode}${sectionId}Q014A001`));

  const chartFavoriteEditMode: Record<
    "imported",
    ChartRowElementImportedProps
  > | null = getValues("chartFavoriteEditMode")
    ? {
        imported: {
          type: "nursing",
        },
      }
    : null;

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={handleSubmit(onSubmit)}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading || isTrackingLoading}
        disabled={isReadOnly}
        sectionId={sectionId}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrPerson} />}
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
          showFavoritesBtn={!isReadOnly}
        >
          Physical Exam {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>
        <ChartSubsection
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A001`}
                  />
                  <ChartRowElement
                    label="Nursing Assessment reviewed"
                    pr="0px"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q001.${chartCode}${sectionId}Q001A003`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    type="checkbox"
                    name={`Q001.${chartCode}${sectionId}Q001A002`}
                  />
                  <ChartRowElement
                    label="Vitals Reviewed"
                    pr="0px"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q001.${chartCode}${sectionId}Q001A004`}
                    color="black"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                  <ChartRowElement
                    label="*"
                    type="label"
                    pl="0px"
                    marginInline="0px"
                    color="red"
                    fontWeight="600"
                    justifyContent="flex-start"
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q002"
              subsectionLabel="General Appearance"
            >
              General Appearance
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ambulatory"
                    name={`Q002.${chartCode}${sectionId}Q002A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="c-collar"
                    name={`Q002.${chartCode}${sectionId}Q002A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="backboard"
                    name={`Q002.${chartCode}${sectionId}Q002A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="PTA"
                    name={`Q002.${chartCode}${sectionId}Q002A005`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="in ED"
                    name={`Q002.${chartCode}${sectionId}Q002A006`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no acute distress"
                    name={`Q002.${chartCode}${sectionId}Q002A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mild distress"
                    name={`Q002.${chartCode}${sectionId}Q002A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="moderate distress"
                    name={`Q002.${chartCode}${sectionId}Q002A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="severe distress"
                    name={`Q002.${chartCode}${sectionId}Q002A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="appears normal"
                    name={`Q002.${chartCode}${sectionId}Q002A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ill"
                    name={`Q002.${chartCode}${sectionId}Q002A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="uncomfortable"
                    name={`Q002.${chartCode}${sectionId}Q002A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="crying"
                    name={`Q002.${chartCode}${sectionId}Q002A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="inconsolable"
                    name={`Q002.${chartCode}${sectionId}Q002A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A018`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="alert"
                    name={`Q002.${chartCode}${sectionId}Q002A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="anxious"
                    name={`Q002.${chartCode}${sectionId}Q002A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="lethargic"
                    name={`Q002.${chartCode}${sectionId}Q002A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="unconscious"
                    name={`Q002.${chartCode}${sectionId}Q002A022`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    label="unresponsive"
                    name={`Q002.${chartCode}${sectionId}Q002A023`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A024`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q002.${chartCode}${sectionId}Q002A026`}
                    isDisabled={q002AllNormal}
                  />
                  <ChartRowElement
                    name={`Q002.${chartCode}${sectionId}Q002A025`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q002AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q003"
              subsectionLabel="EENT"
            >
              EENT
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="eyes nml inspection"
                    name={`Q003.${chartCode}${sectionId}Q003A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="scleral icterus"
                    name={`Q003.${chartCode}${sectionId}Q003A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="pale conjunctivae"
                    name={`Q003.${chartCode}${sectionId}Q003A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A005`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="ENT nml inspection"
                    name={`Q003.${chartCode}${sectionId}Q003A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="EOM palsy"
                    name={`Q003.${chartCode}${sectionId}Q003A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="anisocoria"
                    name={`Q003.${chartCode}${sectionId}Q003A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharynx nml"
                    name={`Q003.${chartCode}${sectionId}Q003A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pharyngeal erythema"
                    name={`Q003.${chartCode}${sectionId}Q003A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnml TM"
                    name={`Q003.${chartCode}${sectionId}Q003A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="hearing deficit"
                    name={`Q003.${chartCode}${sectionId}Q003A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q003.${chartCode}${sectionId}Q003A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q003.${chartCode}${sectionId}Q003A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A019`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dry mucous membranes"
                    name={`Q003.${chartCode}${sectionId}Q003A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A021`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q003.${chartCode}${sectionId}Q003A023`}
                    isDisabled={q003AllNormal}
                  />
                  <ChartRowElement
                    name={`Q003.${chartCode}${sectionId}Q003A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q003AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q004"
              subsectionLabel="Neck"
            >
              Neck
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="supple"
                    name={`Q004.${chartCode}${sectionId}Q004A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="thyromegaly"
                    name={`Q004.${chartCode}${sectionId}Q004A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    label="lymphadenopathy"
                    name={`Q004.${chartCode}${sectionId}Q004A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A005`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q004.${chartCode}${sectionId}Q004A007`}
                    isDisabled={q004AllNormal}
                  />
                  <ChartRowElement
                    name={`Q004.${chartCode}${sectionId}Q004A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q004AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q005"
              subsectionLabel="Respiratory"
            >
              Respiratory
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no resp. distress"
                    name={`Q005.${chartCode}${sectionId}Q005A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="wheezes"
                    name={`Q005.${chartCode}${sectionId}Q005A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="rales"
                    name={`Q005.${chartCode}${sectionId}Q005A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="rhonchi"
                    name={`Q005.${chartCode}${sectionId}Q005A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q005.${chartCode}${sectionId}Q005A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q005.${chartCode}${sectionId}Q005A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="breath sounds nml"
                    name={`Q005.${chartCode}${sectionId}Q005A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A010`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q005.${chartCode}${sectionId}Q005A012`}
                    isDisabled={q005AllNormal}
                  />
                  <ChartRowElement
                    name={`Q005.${chartCode}${sectionId}Q005A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q005AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q006"
              subsectionLabel="CVS"
            >
              CVS
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="reg. rate & rhythm"
                    name={`Q006.${chartCode}${sectionId}Q006A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="irregularly irregular rhythm"
                    name={`Q006.${chartCode}${sectionId}Q006A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="tachycardia"
                    name={`Q006.${chartCode}${sectionId}Q006A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="bradycardia"
                    name={`Q006.${chartCode}${sectionId}Q006A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="JVD"
                    name={`Q006.${chartCode}${sectionId}Q006A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="extrasystoles"
                    name={`Q006.${chartCode}${sectionId}Q006A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="occasional"
                    name={`Q006.${chartCode}${sectionId}Q006A008`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="frequent"
                    name={`Q006.${chartCode}${sectionId}Q006A009`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A010`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heart sounds nml"
                    name={`Q006.${chartCode}${sectionId}Q006A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="murmur"
                    name={`Q006.${chartCode}${sectionId}Q006A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="grade"
                    name={`Q006.${chartCode}${sectionId}Q006A013`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A014`}
                    w="100px"
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="/6"
                    name={`Q006.${chartCode}${sectionId}Q006A015`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="sys"
                    name={`Q006.${chartCode}${sectionId}Q006A016`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="dias"
                    name={`Q006.${chartCode}${sectionId}Q006A017`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A018`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="gallop"
                    name={`Q006.${chartCode}${sectionId}Q006A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="S3"
                    name={`Q006.${chartCode}${sectionId}Q006A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="S4"
                    name={`Q006.${chartCode}${sectionId}Q006A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="decreased pulse"
                    name={`Q006.${chartCode}${sectionId}Q006A023`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q006.${chartCode}${sectionId}Q006A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q006.${chartCode}${sectionId}Q006A025`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="radial"
                    name={`Q006.${chartCode}${sectionId}Q006A026`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A027`}
                    w="249.6666717529297px"
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="fem"
                    name={`Q006.${chartCode}${sectionId}Q006A028`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A029`}
                    w="249.6666717529297px"
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    label="dors ped"
                    name={`Q006.${chartCode}${sectionId}Q006A030`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A031`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q006.${chartCode}${sectionId}Q006A033`}
                    isDisabled={q006AllNormal}
                  />
                  <ChartRowElement
                    name={`Q006.${chartCode}${sectionId}Q006A032`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q006AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q007"
              subsectionLabel="Abdomen / GU"
            >
              Abdomen / GU
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="soft / non-tender"
                    name={`Q007.${chartCode}${sectionId}Q007A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q007.${chartCode}${sectionId}Q007A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="guarding"
                    name={`Q007.${chartCode}${sectionId}Q007A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="rebound"
                    name={`Q007.${chartCode}${sectionId}Q007A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="generalized"
                    name={`Q007.${chartCode}${sectionId}Q007A006`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="LUQ"
                    name={`Q007.${chartCode}${sectionId}Q007A007`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="RUQ"
                    name={`Q007.${chartCode}${sectionId}Q007A008`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="LLQ"
                    name={`Q007.${chartCode}${sectionId}Q007A009`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="RLQ"
                    name={`Q007.${chartCode}${sectionId}Q007A010`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="rigid"
                    name={`Q007.${chartCode}${sectionId}Q007A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="distended"
                    name={`Q007.${chartCode}${sectionId}Q007A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no organomegaly"
                    name={`Q007.${chartCode}${sectionId}Q007A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="hepatomegaly"
                    name={`Q007.${chartCode}${sectionId}Q007A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="splenomegaly"
                    name={`Q007.${chartCode}${sectionId}Q007A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="mass"
                    name={`Q007.${chartCode}${sectionId}Q007A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A019`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml bowel sounds"
                    name={`Q007.${chartCode}${sectionId}Q007A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnormal bowel sounds:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q007.${chartCode}${sectionId}Q007A039`}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="increased"
                    name={`Q007.${chartCode}${sectionId}Q007A021`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="decreased"
                    name={`Q007.${chartCode}${sectionId}Q007A022`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="absent"
                    name={`Q007.${chartCode}${sectionId}Q007A023`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="tympanic"
                    name={`Q007.${chartCode}${sectionId}Q007A024`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A025`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="McBurney's point tenderness"
                    name={`Q007.${chartCode}${sectionId}Q007A026`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A027`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="psoas"
                    name={`Q007.${chartCode}${sectionId}Q007A028`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="Rovsing's sign"
                    name={`Q007.${chartCode}${sectionId}Q007A029`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    label="Obturator sign"
                    name={`Q007.${chartCode}${sectionId}Q007A030`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A031`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no abdominal bruit"
                    name={`Q007.${chartCode}${sectionId}Q007A032`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="prominent aortic pulsations"
                    name={`Q007.${chartCode}${sectionId}Q007A033`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A034`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no pulsatile mass"
                    name={`Q007.${chartCode}${sectionId}Q007A035`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="catheter present"
                    name={`Q007.${chartCode}${sectionId}Q007A036`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A037`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q007.${chartCode}${sectionId}Q007A040`}
                    isDisabled={q007AllNormal}
                  />
                  <ChartRowElement
                    name={`Q007.${chartCode}${sectionId}Q007A038`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q007AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q008"
              isRequired={false}
              subsectionLabel="Pelvic"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q008.${chartCode}${sectionId}Q008A020`}
                    p="0.5rem"
                    _checked={{ borderColor: "red", color: "red" }}
                  >
                    patient refused / deferred
                  </ChartRowElement>
                </>
              }
            >
              Pelvic
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="external exam nml"
                    name={`Q008.${chartCode}${sectionId}Q008A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="vaginal bleeding"
                    name={`Q008.${chartCode}${sectionId}Q008A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="discharge"
                    name={`Q008.${chartCode}${sectionId}Q008A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A005`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="speculum exam nml"
                    name={`Q008.${chartCode}${sectionId}Q008A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cervical motion tenderness"
                    name={`Q008.${chartCode}${sectionId}Q008A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="bimanual exam nml"
                    name={`Q008.${chartCode}${sectionId}Q008A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="adnexal tenderness"
                    name={`Q008.${chartCode}${sectionId}Q008A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="mass"
                    name={`Q008.${chartCode}${sectionId}Q008A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q008.${chartCode}${sectionId}Q008A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q008.${chartCode}${sectionId}Q008A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="enlarged"
                    name={`Q008.${chartCode}${sectionId}Q008A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    label="tender uterus"
                    name={`Q008.${chartCode}${sectionId}Q008A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A017`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q008.${chartCode}${sectionId}Q008A019`}
                    isDisabled={q008AllNormal}
                  />
                  <ChartRowElement
                    name={`Q008.${chartCode}${sectionId}Q008A018`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q008AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q009"
              subsectionLabel="Genital"
              isRequired={false}
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q009.${chartCode}${sectionId}Q009A011`}
                    p="0.5rem"
                    _checked={{ borderColor: "red", color: "red" }}
                  >
                    patient refused / deferred
                  </ChartRowElement>
                </>
              }
            >
              Genital
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q009.${chartCode}${sectionId}Q009A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="swelling"
                    name={`Q009.${chartCode}${sectionId}Q009A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="testicular"
                    name={`Q009.${chartCode}${sectionId}Q009A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="inguinal"
                    name={`Q009.${chartCode}${sectionId}Q009A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q009.${chartCode}${sectionId}Q009A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q009.${chartCode}${sectionId}Q009A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A008`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q009.${chartCode}${sectionId}Q009A010`}
                    isDisabled={q009AllNormal}
                  />
                  <ChartRowElement
                    name={`Q009.${chartCode}${sectionId}Q009A009`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q009AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q010"
              subsectionLabel="Rectal"
              isRequired={false}
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q010.${chartCode}${sectionId}Q010A016`}
                    p="0.5rem"
                    _checked={{ borderColor: "red", color: "red" }}
                  >
                    patient refused / deferred
                  </ChartRowElement>
                </>
              }
            >
              Rectal
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q010.${chartCode}${sectionId}Q010A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="tenderness"
                    name={`Q010.${chartCode}${sectionId}Q010A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A004`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="heme neg stool"
                    name={`Q010.${chartCode}${sectionId}Q010A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no stool"
                    name={`Q010.${chartCode}${sectionId}Q010A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="black stool"
                    name={`Q010.${chartCode}${sectionId}Q010A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="bloody stool"
                    name={`Q010.${chartCode}${sectionId}Q010A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="heme pos. stool"
                    name={`Q010.${chartCode}${sectionId}Q010A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    label="trace"
                    name={`Q010.${chartCode}${sectionId}Q010A010`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A011`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="fecal impaction"
                    name={`Q010.${chartCode}${sectionId}Q010A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A013`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q010.${chartCode}${sectionId}Q010A015`}
                    isDisabled={q010AllNormal}
                  />
                  <ChartRowElement
                    name={`Q010.${chartCode}${sectionId}Q010A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q010AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q011"
              subsectionLabel="Back"
            >
              Back
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="CVA tenderness"
                    name={`Q011.${chartCode}${sectionId}Q011A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q011.${chartCode}${sectionId}Q011A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q011.${chartCode}${sectionId}Q011A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A005`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q011.${chartCode}${sectionId}Q011A007`}
                    isDisabled={q011AllNormal}
                  />
                  <ChartRowElement
                    name={`Q011.${chartCode}${sectionId}Q011A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q011AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q012"
              subsectionLabel="Skin"
            >
              Skin
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml color, warm, dry"
                    name={`Q012.${chartCode}${sectionId}Q012A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cyanosis"
                    name={`Q012.${chartCode}${sectionId}Q012A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="diaphoresis"
                    name={`Q012.${chartCode}${sectionId}Q012A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="pallor"
                    name={`Q012.${chartCode}${sectionId}Q012A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="warmth"
                    name={`Q012.${chartCode}${sectionId}Q012A006`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="erythema"
                    name={`Q012.${chartCode}${sectionId}Q012A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="icterus"
                    name={`Q012.${chartCode}${sectionId}Q012A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="ecchymosis"
                    name={`Q012.${chartCode}${sectionId}Q012A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A010`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no rash"
                    name={`Q012.${chartCode}${sectionId}Q012A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="skin rash"
                    name={`Q012.${chartCode}${sectionId}Q012A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="zoster-like"
                    name={`Q012.${chartCode}${sectionId}Q012A013`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="petechiae"
                    name={`Q012.${chartCode}${sectionId}Q012A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="icterus"
                    name={`Q012.${chartCode}${sectionId}Q012A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="lymphangitis"
                    name={`Q012.${chartCode}${sectionId}Q012A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A017`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="embolic lesions"
                    name={`Q012.${chartCode}${sectionId}Q012A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="signs of IVDA"
                    name={`Q012.${chartCode}${sectionId}Q012A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A020`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="intact"
                    name={`Q012.${chartCode}${sectionId}Q012A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pressure ucler"
                    name={`Q012.${chartCode}${sectionId}Q012A022`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="location:"
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q012.${chartCode}${sectionId}Q012A031`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A023`}
                    w="673px"
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="depth / stage:"
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q012.${chartCode}${sectionId}Q012A032`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="1"
                    width="2.5rem"
                    name={`Q012.${chartCode}${sectionId}Q012A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="2"
                    width="2.5rem"
                    name={`Q012.${chartCode}${sectionId}Q012A025`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="3"
                    width="2.5rem"
                    name={`Q012.${chartCode}${sectionId}Q012A026`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    label="4"
                    width="2.5rem"
                    name={`Q012.${chartCode}${sectionId}Q012A027`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="poor turgor"
                    name={`Q012.${chartCode}${sectionId}Q012A028`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A029`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q012.${chartCode}${sectionId}Q012A033`}
                    isDisabled={q012AllNormal}
                  />
                  <ChartRowElement
                    name={`Q012.${chartCode}${sectionId}Q012A030`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q012AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q013"
              subsectionLabel="Extremities"
            >
              Extremities
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="non-tender"
                    name={`Q013.${chartCode}${sectionId}Q013A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="calf tenderness"
                    name={`Q013.${chartCode}${sectionId}Q013A003`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A004`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A005`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A006`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="nml ROM"
                    name={`Q013.${chartCode}${sectionId}Q013A007`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="Homan's sign"
                    name={`Q013.${chartCode}${sectionId}Q013A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="cords"
                    name={`Q013.${chartCode}${sectionId}Q013A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A012`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="abnml joints"
                    name={`Q013.${chartCode}${sectionId}Q013A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A014`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A016`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="no pedal edema"
                    name={`Q013.${chartCode}${sectionId}Q013A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="pedal edema"
                    name={`Q013.${chartCode}${sectionId}Q013A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q013.${chartCode}${sectionId}Q013A019`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q013.${chartCode}${sectionId}Q013A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A021`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q013.${chartCode}${sectionId}Q013A023`}
                    isDisabled={q013AllNormal}
                  />
                  <ChartRowElement
                    name={`Q013.${chartCode}${sectionId}Q013A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q013AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q014"
              subsectionLabel="Neuro / Psych"
            >
              Neuro / Psych
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="oriented x4"
                    name={`Q014.${chartCode}${sectionId}Q014A002`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="disoriented to:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q014.${chartCode}${sectionId}Q014A027`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="person"
                    name={`Q014.${chartCode}${sectionId}Q014A003`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="place"
                    name={`Q014.${chartCode}${sectionId}Q014A004`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="time"
                    name={`Q014.${chartCode}${sectionId}Q014A005`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="situation"
                    name={`Q014.${chartCode}${sectionId}Q014A006`}
                    fontStyle="italic"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A007`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor / sensation nml"
                    name={`Q014.${chartCode}${sectionId}Q014A008`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="motor deficit"
                    name={`Q014.${chartCode}${sectionId}Q014A009`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="sensory deficit"
                    name={`Q014.${chartCode}${sectionId}Q014A010`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="weakness"
                    name={`Q014.${chartCode}${sectionId}Q014A011`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A012`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A013`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A014`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="CN's nml (2-12)"
                    name={`Q014.${chartCode}${sectionId}Q014A015`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="facial droop"
                    name={`Q014.${chartCode}${sectionId}Q014A016`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="L"
                    name={`Q014.${chartCode}${sectionId}Q014A017`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="R"
                    name={`Q014.${chartCode}${sectionId}Q014A018`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A019`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="speech abnormalities"
                    name={`Q014.${chartCode}${sectionId}Q014A020`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    label="cognition abnormalities"
                    name={`Q014.${chartCode}${sectionId}Q014A021`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A022`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement
                    label="mood / affect nml"
                    name={`Q014.${chartCode}${sectionId}Q014A023`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="depressed mood / affect"
                    name={`Q014.${chartCode}${sectionId}Q014A024`}
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A025`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow gridTemplateColumns={PHYSICAL_EXAM_GRID_COLUMNS}>
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="other:"
                    type="cross-option"
                    {...chartFavoriteEditMode}
                    name={`Q014.${chartCode}${sectionId}Q014A028`}
                    isDisabled={q014AllNormal}
                  />
                  <ChartRowElement
                    name={`Q014.${chartCode}${sectionId}Q014A026`}
                    type="input"
                    {...chartFavoriteEditMode}
                    isDisabled={q014AllNormal}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          header={
            <ChartSubsectionHeader
              showNotesBtn
              chartId={chartCode}
              encounterId={encounterId}
              sectionId={sectionId}
              subsectionId="Q015"
              subsectionLabel="Additional Findings"
              subsectionOptions={
                <>
                  <ChartRowElement
                    type="checkbox-button"
                    {...chartFavoriteEditMode}
                    name={`Q015.${chartCode}${sectionId}Q015A001`}
                    w="60px"
                  >
                    none
                  </ChartRowElement>
                </>
              }
              isRequired={false}
            >
              Additional Findings
            </ChartSubsectionHeader>
          }
          content={
            <ChartSubsectionContent>
              <ChartRow>
                <ChartRowElement
                  name={`Q015.${chartCode}${sectionId}Q015A002`}
                  type="input"
                  {...chartFavoriteEditMode}
                />
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartForm>
    </FormProvider>
  );
}

export { T36PhysicalExam };
