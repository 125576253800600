import { useToast } from "@chakra-ui/react";
import { Infusion } from "icons";
import { formMessages } from "messages";
import {
  ChartSection,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "modules";
import { ChartRouteBaseParams, IvStart } from "modules/charts-shared";
import {
  useDeleteIvStarts,
  useIvStarts,
  useUpdateIvStarts,
} from "modules/triage/api";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { IvStartsCard, IvStartsCardHeader } from "./IvStartsCard";

const SECTION_CODE = "SIVM";

type IvStartsForm = {
  ivStarts: IvStart[];
  deletedIvStarts: string[];
};

function IVStarts(props: { isReadOnly?: boolean; chartCode: string }) {
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const toast = useToast();
  const navigate = useNavigate();

  const { isReadOnly, chartCode } = props;

  const useFormContext = useForm<IvStartsForm>({
    defaultValues: {
      ivStarts: [],
      deletedIvStarts: [],
    },
  });

  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
    control,
  } = useFormContext;

  const { append } = useFieldArray({ control, name: "ivStarts" });

  const { data: ivStartsData, isLoading: getIsLoading } =
    useIvStarts(encounterId);
  const { mutateAsync: updateIvStarts, isLoading: updateIsLoading } =
    useUpdateIvStarts(encounterId);
  const { mutateAsync: deleteIvStarts, isLoading: deleteIsLoading } =
    useDeleteIvStarts(encounterId);

  const [nextRoute, setNextRoute] = useState<string | null>(null);

  useEffect(() => {
    if (ivStartsData?.data) {
      reset({ ivStarts: ivStartsData.data });
    }
  }, [ivStartsData, reset]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [nextRoute, navigate, isDirty]);

  if (getIsLoading) {
    return <Loading />;
  }
  const ivStarts = watch("ivStarts");

  const onSubmit = async (formData: IvStartsForm) => {
    try {
      await deleteIvStarts(formData.deletedIvStarts);
      await updateIvStarts(formData.ivStarts);
      toast({ description: formMessages.updateSuccess("IV Starts") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const handleCreateNewIVStart = async () => {
    append({
      date: new Date(),
      number: "",
      site: "",
      ga: "",
      attempts: "",
      comments: "",
      chartCode,
    });
  };

  return (
    <FormProvider {...useFormContext}>
      <ChartSection
        onSubmit={handleSubmit(onSubmit)}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          handleSubmit(onSubmit)();
          onComplete();
        }}
      >
        <SharedChartSectionHeader
          icon={<Infusion />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading || deleteIsLoading}
        >
          IV Starts {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <SharedChartSubsectionHeader
          chartCode=""
          encounterId={encounterId}
          sectionCode={SECTION_CODE}
          subsectionCode="Q002"
          subsectionLabel="IV Starts"
          margin="1rem 0"
          showNotesBtn={false}
          showAuditsBtn={false}
          showInteractionsBtn={false}
          endButton={
            <Button
              variant="action"
              maxH="36px"
              onClick={handleCreateNewIVStart}
            >
              Add New IV
            </Button>
          }
        >
          IV Starts
        </SharedChartSubsectionHeader>
        <IvStartsCardHeader />
        {ivStarts.map((_, index) => (
          <IvStartsCard num={index} />
        ))}
      </ChartSection>
    </FormProvider>
  );
}

export { IVStarts };
