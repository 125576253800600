import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseCreateTeamPayload = {
  name: string;
  permissions: string[];
};

function useCreateTeam() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["createTeam"],
    (input: UseCreateTeamPayload) =>
      axiosIdentityInstance.post("/v1/team", input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("teamsList");
        queryClient.invalidateQueries("teamsMin");
        queryClient.invalidateQueries("permissionsCounters");
      },
    }
  );
}

export { useCreateTeam };
