import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import {
  ChartSection,
  ChartSectionHeader,
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  ChartRouteBaseParams,
  ChartSubsectionHeader,
} from "modules";
import { useTriageChartProceduresForm } from "../../hooks";
import {
  ACTIONS_COLUMNS_GAP,
  ACTIONS_ROW_TEMPLATE_COLUMNS_4,
  CONTAINER_PADDING_2,
} from "modules/triage/shared/actions/constants";
import { ActionsHeaderDescription } from "modules/triage/shared";
import { usePermissions } from "contexts";
import { useParams } from "react-router-dom";
import { TriageChartCode } from "enums";

const sectionId = "SPRO";
const triageChartCode: TriageChartCode = "T006x";

function N06Procedures() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartProceduresForm({
    chartCode: triageChartCode,
    sectionId,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:proc").isEditable;

  const { getValues } = useFormReturn;
  const sections = getValues("sections");

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ChartSubsectionHeader
          chartId={triageChartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          subsectionId="Q001"
          subsectionLabel=""
          showNotesBtn={false}
        />
        <ChartSubsection content={<ActionsHeaderDescription />} />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[0] && sections[0].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[0] && sections[0].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[0].${triageChartCode}${sectionId}Q001A001`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="portable"
                    name={`sections[0].${triageChartCode}${sectionId}Q001A002`}
                    type="option"
                  />
                  <ChartRowElement
                    label="to Xray"
                    name={`sections[0].${triageChartCode}${sectionId}Q001A003`}
                    type="option"
                  />
                  <ChartRowElement
                    label="w/:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[0].${triageChartCode}${sectionId}Q001A092`}
                  />
                  <ChartRowElement
                    label="monitor"
                    name={`sections[0].${triageChartCode}${sectionId}Q001A004`}
                    type="option"
                  />
                  <ChartRowElement
                    label="nurse"
                    name={`sections[0].${triageChartCode}${sectionId}Q001A005`}
                    type="option"
                  />
                  <ChartRowElement
                    label="O2"
                    name={`sections[0].${triageChartCode}${sectionId}Q001A006`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[0].${triageChartCode}${sectionId}Q001A007`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[0].${triageChartCode}${sectionId}Q001A008`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[1] && sections[1].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[1] && sections[1].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[1].${triageChartCode}${sectionId}Q001A009`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="return to room"
                    name={`sections[1].${triageChartCode}${sectionId}Q001A010`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[1].${triageChartCode}${sectionId}Q001A011`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[2] && sections[2].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[2] && sections[2].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[2].${triageChartCode}${sectionId}Q001A012`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="to CT"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A013`}
                    type="option"
                  />
                  <ChartRowElement
                    label="w/:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A092`}
                  />
                  <ChartRowElement
                    label="contrast"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A014`}
                    type="option"
                  />
                  <ChartRowElement
                    label="parenteral meds"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A015`}
                    type="option"
                  />
                  <ChartRowElement
                    label="O2"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A016`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A017`}
                    type="option"
                  />
                  <ChartRowElement
                    label="w/:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A093`}
                  />
                  <ChartRowElement
                    label="monitor"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A018`}
                    type="option"
                  />
                  <ChartRowElement
                    label="nurse"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A019`}
                    type="option"
                  />
                  <ChartRowElement
                    label="O2"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A020`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A021`}
                    type="option"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A022`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="return to room"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A023`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A024`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[6] && sections[6].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[6] && sections[6].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[6].${triageChartCode}${sectionId}Q001A035`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="labs drawn"
                    name={`sections[6].${triageChartCode}${sectionId}Q001A036`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[6].${triageChartCode}${sectionId}Q001A041`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[2] && sections[2].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[2] && sections[2].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[2].${triageChartCode}${sectionId}Q001A093`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="laceration repair"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A094`}
                    type="option"
                  />
                  <ChartRowElement
                    label="to:"
                    fontStyle="italic"
                    type="option"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A095`}
                  />
                  <ChartRowElement
                    label="scalp"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A096`}
                    type="option"
                  />
                  <ChartRowElement
                    label="face"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A097`}
                    type="option"
                  />

                  <ChartRowElement
                    name={`sections[2].${triageChartCode}${sectionId}Q001A098`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A099`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="assisted by:"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A100`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="nurse"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A101`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A102`}
                    type="option"
                  />
                  <ChartRowElement
                    label="site:"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A103`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A104`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[2] && sections[2].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[2] && sections[2].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[2].${triageChartCode}${sectionId}Q001A105`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="dislocation"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A106`}
                    type="option"
                  />
                  <ChartRowElement
                    label="fx reduction"
                    type="option"
                    name={`sections[2].${triageChartCode}${sectionId}Q001A107`}
                  />
                  <ChartRowElement
                    name={`sections[2].${triageChartCode}${sectionId}Q001A108`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A109`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="assisted by:"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A110`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="nurse"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A111`}
                    type="option"
                  />
                  <ChartRowElement
                    label="tech"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A122`}
                    type="option"
                  />
                  <ChartRowElement
                    label="site:"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A113`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A114`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A115`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="splint applied"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A116`}
                    type="option"
                  />
                  <ChartRowElement
                    label="sling applied"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A117`}
                    type="option"
                  />
                  <ChartRowElement
                    label="arm"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A118`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="leg"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A119`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="short"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A120`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="long"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A121`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="type:"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A122`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A123`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A124`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="post splint assessment:"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A125`}
                    type="option"
                  />
                  <ChartRowElement
                    label="nml"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A126`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="color"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A127`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="sensation"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A128`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="movement"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A129`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A130`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A131`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="cleaned wound"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A132`}
                    type="option"
                  />
                  <ChartRowElement
                    label="applied abx ointment"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A133`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A134`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A135`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="applied:"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A136`}
                    fontStyle="italic"
                    type="option"
                  />
                  <ChartRowElement
                    label="dressing"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A137`}
                    type="option"
                  />
                  <ChartRowElement
                    label="Band-Aid"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A138`}
                    type="option"
                  />
                  <ChartRowElement
                    label="elastic wrap"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A139`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A140`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A141`}
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    label="crutch traing"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A142`}
                    type="option"
                  />
                  <ChartRowElement
                    label="w/ proper return demonstration"
                    name={`sections[3].${triageChartCode}${sectionId}Q001A143`}
                    type="option"
                  />
                  <ChartRowElement
                    name={`sections[3].${triageChartCode}${sectionId}Q001A144`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[13] && sections[13].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[13] && sections[13].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[13].${triageChartCode}${sectionId}Q001A082`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[13].${triageChartCode}${sectionId}Q001A083`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[15] && sections[15].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[15] && sections[15].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[15].${triageChartCode}${sectionId}Q001A084`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[15].${triageChartCode}${sectionId}Q001A085`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[16] && sections[16].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[16] && sections[16].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[16].${triageChartCode}${sectionId}Q001A086`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[16].${triageChartCode}${sectionId}Q001A087`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[17] && sections[17].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[17] && sections[17].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[17].${triageChartCode}${sectionId}Q001A088`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[17].${triageChartCode}${sectionId}Q001A089`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={CONTAINER_PADDING_2}
              whiteSpace="nowrap"
            >
              <ChartRow
                gridTemplateColumns={ACTIONS_ROW_TEMPLATE_COLUMNS_4}
                columnGap={ACTIONS_COLUMNS_GAP}
              >
                <ChartRowGroup justifyContent="center">
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[18] && sections[18].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[18] && sections[18].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[18].${triageChartCode}${sectionId}Q001A090`}
                    w="220px"
                    minW="220px"
                    type="date"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowGroup>
                  <ChartRowElement
                    name={`sections[18].${triageChartCode}${sectionId}Q001A091`}
                    type="input"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N06Procedures };
