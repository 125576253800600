import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type DisableUsersPayload = string[];

function useDisableUsers() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["disableUsers"],
    (input: DisableUsersPayload) =>
      axiosIdentityInstance.post("/v1/user/disable-users", input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("searchEnabledUsers");
        queryClient.invalidateQueries("searchDisabledUsers");
        queryClient.invalidateQueries("permissionsCounters");
      },
    }
  );
}

export { useDisableUsers };
