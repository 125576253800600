import { useToast } from "@chakra-ui/react";
import { OrderStatus } from "enums";
import { MedicalCross } from "icons";
import { formMessages } from "messages";
import {
  ChartSection,
  SharedChartSectionHeader,
  SharedChartSubsectionHeader,
} from "modules";
import {
  ChartRouteBaseParams,
  TriageIVMedicationForm,
  nextRoutesNurse,
  nextRoutesProvider,
} from "modules/charts-shared";
import {
  useIvAndMedication,
  useUpdateIvAndMedication,
} from "modules/triage/api";
import {
  formToPayload,
  responseToForm,
} from "modules/triage/utils/iv-medications";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import { TreatmentsCounters } from "./TreatmentsCounters";
import { TreatmentsCard, TreatmentsCardHeader } from "./TreatmentsCard";

const SECTION_CODE = "SIVM";

function Treatments(props: { chartCode: string; isReadOnly?: boolean }) {
  const { encounterId = "", "*": chartName = "" } =
    useParams<ChartRouteBaseParams>();
  const toast = useToast();
  const navigate = useNavigate();
  const useFormContext = useForm<TriageIVMedicationForm>({
    defaultValues: {
      ivMedications: [],
      ivStarts: [],
      medications: [],
      treatments: [],
      treatmentsIvStarts: [],
    },
  });
  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext;
  const { data: ivMedicationData, isLoading: getIsLoading } =
    useIvAndMedication(encounterId);
  const { mutateAsync: updateIvMedication, isLoading: updateIsLoading } =
    useUpdateIvAndMedication(encounterId);
  const { chartCode, isReadOnly } = props;

  const [filter, setFilter] = useState<OrderStatus | null>(null);
  const [nextRoute, setNextRoute] = useState<string | null>(null);

  useEffect(() => {
    if (ivMedicationData) {
      reset(responseToForm(ivMedicationData.data));
    }
  }, [ivMedicationData, reset]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [nextRoute, navigate, isDirty]);

  if (getIsLoading) {
    return <Loading />;
  }
  const treatments = watch("treatments");
  const onSubmit = async (formData: TriageIVMedicationForm) => {
    const payload = formToPayload(formData);
    try {
      await updateIvMedication(payload);
      toast({ description: formMessages.updateSuccess("Treatments") });
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  const handleSaveAndNext = () => {
    const nextRoutes =
      chartCode[0] === "T" ? nextRoutesNurse : nextRoutesProvider;
    const route = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];
    handleSubmit(onSubmit)().then(() => {
      setNextRoute(`./../${route}`);
    });
  };

  return (
    <FormProvider {...useFormContext}>
      <ChartSection
        onSubmit={handleSubmit(onSubmit)}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          handleSubmit(onSubmit)();
          onComplete();
        }}
        isDirty={isDirty}
      >
        <SharedChartSectionHeader
          icon={<MedicalCross />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Treatments {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <TreatmentsCounters
          filter={filter}
          setFilter={(newFilter) => setFilter(newFilter)}
        />
        <SharedChartSubsectionHeader
          chartCode={chartCode}
          encounterId={encounterId}
          sectionCode={SECTION_CODE}
          subsectionCode="Q002"
          subsectionLabel="Treatments"
          margin="1rem 0"
          showNotesBtn={false}
          showAuditsBtn={false}
        >
          Treatments
        </SharedChartSubsectionHeader>
        <TreatmentsCardHeader />
        {treatments
          ?.filter((med) => med?.status === filter || filter === null)
          .filter((med) => {
            return (
              !med.order?.isScheduled ||
              (med.order?.isScheduled &&
                new Date(med.order?.scheduledTime || "") < new Date())
            );
          })
          .map((_, index) => (
            <TreatmentsCard index={index} pictureUrl="" />
          ))}
      </ChartSection>
    </FormProvider>
  );
}

export { Treatments };
