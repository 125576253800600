import * as React from "react";
import { format } from "date-fns";
import { Card, StaffAvatar, WarningDialog } from "shared";
import {
  HStack,
  Box,
  chakra,
  Flex,
  useDisclosure,
  IconButton,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { STPVipNote as STPVipNoteType } from "modules/stp-vip/types";
import { useUserRoles } from "contexts/UserRoles";
import {
  useDeleteStpVipNote,
  usePatchEncounterField,
} from "modules/reception/api";
import { formMessages } from "messages";
import { extractApiErrorMessage } from "utils";
import { EmrTrashcan } from "@medstonetech/slate-icons";
import { useStpVipNotes } from "modules/stp-vip/api";
import { usePatientBasicInfoContext } from "modules/reception/contexts";

type STPVipProps = {
  note: STPVipNoteType;
  encounterId: string;
  type: "Stp" | "Vip" | "Other";
};

type CommonNoteProps = {
  id: string;
  encounterId: string;
  type: "Stp" | "Vip" | "Other";
  note: string;
};

type STPVipHeaderProps = {
  createdByName: string;
  createdAt: Date | string;
};

function STPVipHeader({ createdByName, createdAt }: STPVipHeaderProps) {
  return (
    <HStack
      spacing="5px"
      alignItems="center"
      color="gray.750"
      fontSize="0.75rem"
      marginLeft="10px"
    >
      <chakra.span>{createdByName}</chakra.span>
      <chakra.span>&#183;</chakra.span>
      <chakra.span>
        {createdAt && format(new Date(createdAt), "hh:mm a L/d/yyyy")}
      </chakra.span>
    </HStack>
  );
}

function CommonNote({ id, note, encounterId, type }: CommonNoteProps) {
  const { roles } = useUserRoles();
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { painProtocolPatientFormContext, vipPatientFormContext } =
    usePatientBasicInfoContext();

  const { mutateAsync: deleteEncounterNote, isLoading: isDeleteLoading } =
    useDeleteStpVipNote(id, encounterId, type);

  const { mutateAsync } = usePatchEncounterField({
    encounterId,
  });

  const { data: notes } = useStpVipNotes({
    patientId: encounterId,
    type,
  });

  const onDelete = async () => {
    try {
      await deleteEncounterNote(null);

      if (notes?.data?.length === 1) {
        if (type === "Stp") {
          await mutateAsync([{ path: "painProtocol", value: false }]);
          painProtocolPatientFormContext.setValue("painProtocol", false);
          toast({ description: "Patient unmarked as STP successfully" });
        }
        if (type === "Vip") {
          await mutateAsync([{ path: "isVip", value: false }]);
          vipPatientFormContext.setValue("isVip", false);
          toast({ description: "Patient unmarked as VIP successfully" });
        }
      } else {
        toast({ description: formMessages.deleteSuccess("Note") });
      }
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    } finally {
      onClose();
    }
  };

  return (
    <Card
      padding="10px"
      borderRadius="0.625rem"
      bg="gray.50"
      position="relative"
      boxShadow="none"
    >
      <Flex justifyContent="space-between" alignItems="center">
        {note}
        <Box>
          <IconButton
            variant="ghost"
            aria-label="delete"
            onClick={onOpen}
            width="24px"
            height="24px"
            minWidth="unset"
            color="red"
            isDisabled={!roles.includes("Administrator")}
            isLoading={isDeleteLoading}
          >
            <Icon as={EmrTrashcan} />
          </IconButton>
          <WarningDialog
            isOpen={isOpen}
            mainText="Removing this note cannot be undone!"
            secondaryText={
              notes?.data?.length
                ? `Removing this last note will remove the ${type} validation`
                : ""
            }
            onAction={onDelete}
            onCancel={onClose}
            onClose={onClose}
            title="Warning!"
            actionLabel="Delete"
            isActionLoading={isDeleteLoading}
          />
        </Box>
      </Flex>
    </Card>
  );
}

function STPVipNote({ note, encounterId, type }: STPVipProps) {
  const { createdAt, user } = note;

  return (
    <HStack alignItems="flex-end" width="100%">
      <StaffAvatar
        width="30px"
        height="30px"
        bottom="10px"
        size="xs"
        position="relative"
        userName={user?.fullName || ""}
        profileUrl={user?.pictureUrl || ""}
      />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        position="relative"
      >
        <STPVipHeader
          createdByName={user?.fullName || ""}
          createdAt={createdAt}
        />
        <CommonNote
          note={note.note || ""}
          id={note.id}
          type={type}
          encounterId={encounterId}
        />
      </Box>
    </HStack>
  );
}

export type { STPVipProps };
export { STPVipNote };
