import * as React from "react";
import { Box, chakra, Divider } from "@chakra-ui/react";
import { TriageIVMedicationSection } from "modules/charts-shared";

import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  IvMedicationsTypeContent,
  IvStartsTypeContent,
  MedicationTypeContent,
  TriageItem,
} from "./MedsAndIvModals";
import { MedsAndIvTypes } from "./IvAndMedications";

type MedsAndIvSummaryProps = {
  item: TriageIVMedicationSection;
};

const MedsAndIvSummary = ({ item }: MedsAndIvSummaryProps) => {
  const { getValues } = useFormContext(); // Main form

  const type: MedsAndIvTypes =
    item.route === "IV"
      ? "ivMedications"
      : item.route === "IVP" || item.route === "IVPB"
      ? "ivStarts"
      : "medications";

  const title =
    item.route === "IV"
      ? "IV/Medication Infusion"
      : item.route === "IVP" || item.route === "IVPB"
      ? "IV Push"
      : "Medication";

  const formItem = React.useMemo(
    () => getValues(`${type}[${item.index}]`),
    [getValues, type, item.index]
  );

  const modalForm = useForm<TriageItem>({
    defaultValues: formItem,
  });

  useEffect(() => {
    if (formItem) {
      modalForm.reset(formItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem]);

  return (
    <Box
      justifySelf="center"
      alignItems="center"
      display="flex"
      flexDir="column"
      color="blue"
    >
      <chakra.span fontWeight="600" mb="1.5rem">
        {title}
      </chakra.span>

      <FormProvider {...modalForm}>
        <form>
          {type === "ivStarts" && (
            <IvStartsTypeContent item={formItem} type={type} />
          )}
          {type === "ivMedications" && (
            <IvMedicationsTypeContent item={formItem} type={type} />
          )}
          {type === "medications" && (
            <MedicationTypeContent item={formItem} type={type} />
          )}
        </form>
      </FormProvider>
      <Divider my="1.5rem" />
    </Box>
  );
};

export { MedsAndIvSummary };
