import { PaginationPayload, PaginationResponse } from "types";
import { useQuery } from "react-query";
import { axiosIdentityInstance } from "config";

type SearchDisabledUsersElement = {
  id: string;
  name: string;
  username: string;
  imageUrl: string;
  title: string;
  email: string;
  phone: string;
  teams: number;
  lastLogin: Date;
};

type SearchDisabledUsersResponse =
  PaginationResponse<SearchDisabledUsersElement>;

type DisabledUsersFilter = Partial<{
  team: string;
  role: string;
  search: string;
  firstName: string;
  lastName: string;
  username: string;
}>;

type UseSearchDisabledUserProps = DisabledUsersFilter & PaginationPayload;

function useSearchDisabledUsers(
  props: UseSearchDisabledUserProps,
  enabled = true
) {
  const { sort, descending, page = 1, size = 10, ...filters } = props;
  const validFilters = Object.entries(filters).reduce((curr, [k, v]) => {
    if (v) {
      return { ...curr, [k]: v };
    }
    return curr;
  }, {});
  const { search } = filters;
  return useQuery(
    ["searchDisabledUsers", { page, ...filters }],
    () =>
      axiosIdentityInstance.get<SearchDisabledUsersResponse>(
        "v1/user/disabled-users",
        {
          params: {
            page,
            pageSize: size,
            sortDesc: descending || false,
            orderBy: sort,
            ...validFilters,
          },
        }
      ),
    { keepPreviousData: true, staleTime: search ? 0 : 1 * 60 * 1000, enabled }
  );
}

export { useSearchDisabledUsers };
export type {
  UseSearchDisabledUserProps,
  DisabledUsersFilter,
  SearchDisabledUsersElement,
};
