import { axiosOrderInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteIvStarts(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteIvStart", { encounterId }],
    (input: string[]) =>
      axiosOrderInstance.delete(`v1/iv-starts/${encounterId}`, { data: input }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["ivStarts", { encounterId }]),
    }
  );
}

export { useDeleteIvStarts };
