import { createIcon } from "@chakra-ui/react";

const Pediatric = createIcon({
  path: (
    <>
      <path d="M13.0091 4.05876C13.0091 6.30051 11.1921 8.11752 8.95036 8.11752C6.70861 8.11752 4.8916 6.30051 4.8916 4.05876C4.8916 1.81701 6.70861 0 8.95036 0C11.1921 0 13.0091 1.81701 13.0091 4.05876Z" />
      <path d="M7.89545 22.021C8.23437 22.5256 8.16725 23.1999 7.73663 23.627C7.24243 24.1247 6.4379 24.1247 5.94019 23.627L2.72849 20.4153C2.24493 19.9317 2.32269 19.2265 2.69328 18.654C4.71546 15.5293 4.71546 15.5293 4.71546 15.5293L8.2448 17.9998L6.12715 19.7646C6.13069 19.7682 7.89194 22.0174 7.89551 22.021H7.89545Z" />
      <path d="M10.0058 22.021C9.66669 22.5256 9.73405 23.1999 10.1644 23.627C10.6586 24.1247 11.4637 24.1247 11.9609 23.627L15.1731 20.4153C15.6566 19.9317 15.5786 19.2265 15.2087 18.654L13.1858 15.5293L9.65649 17.9998L11.7742 19.7646C11.7706 19.7682 10.0094 22.0174 10.0058 22.021H10.0058Z" />
      <path d="M17.4424 9.25656H17.4353C16.2458 9.99417 14.9542 10.5872 13.5883 10.9965C13.14 11.1306 12.833 11.5437 12.833 12.0095V14.8237H5.06785V12.0093C5.06785 11.5434 4.76084 11.1306 4.31251 10.9963C2.94668 10.587 1.65479 9.99399 0.465517 9.25632H0.458427C0.0526236 9.00225 -0.109746 8.49384 0.0772007 8.04931C0.274784 7.59057 0.783166 7.36107 1.25256 7.49863C1.28423 7.51281 1.31968 7.52344 1.35135 7.53739C1.35844 7.54093 1.36553 7.54448 1.36908 7.54802C3.6985 8.53618 6.26099 9.08332 8.95029 9.08332C11.6396 9.08332 14.2021 8.53618 16.5315 7.54802C16.535 7.54448 16.5417 7.54093 16.5492 7.53739C16.5809 7.52321 16.6159 7.51257 16.6478 7.49863C17.1172 7.36107 17.6258 7.59033 17.8231 8.04931C18.0103 8.49388 17.848 9.00226 17.4421 9.25656H17.4424Z" />
    </>
  ),
  viewBox: "0 0 18 24",
});

export { Pediatric };
