import { Box, Icon, Text } from "@chakra-ui/react";
import * as EmrIcons from "@medstonetech/slate-icons";
import * as Icons from "icons";

function IconsPage() {
  const iconsNames = Object.keys(Icons);
  const emrIconsNames = Object.keys(EmrIcons);

  return (
    <Box w="100%" minH="100vh" p={4} textAlign="center">
      <Text fontSize="2rem" fontWeight="600" pb={3}>
        Icons
      </Text>
      <Box display="flex" justifyContent="start" flexWrap="wrap" gap={6}>
        {Object.values(Icons).map((CurrentIcon, i) => (
          <Box
            key={i}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CurrentIcon sx={{ color: "blue", fontSize: "5rem" }} />
            {iconsNames[i]}
          </Box>
        ))}
      </Box>

      <Text fontSize="2rem" fontWeight="600" py={3}>
        Slate Icons
      </Text>
      <Box display="flex" justifyContent="start" flexWrap="wrap" gap={6}>
        {Object.values(EmrIcons).map((CurrentIcon, i) => {
          if (typeof CurrentIcon === "function") {
            return null;
          }
          return (
            <Box
              key={i}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                as={
                  CurrentIcon as React.ForwardRefExoticComponent<
                    Omit<
                      React.SVGProps<SVGSVGElement>,
                      "ref" | "height" | "width"
                    > & {
                      size?: (number | "sm" | "md" | "lg") | undefined;
                    } & React.RefAttributes<Omit<SVGSVGElement, "ref">>
                  >
                }
                fontSize="5rem"
                color="blue"
              />
              {emrIconsNames[i]}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export { IconsPage };
