import { axiosChartInstance } from "config";
import { OrderFormElementType } from "enums";
import { useAxiosQuery } from "hooks";
import { OrderElementResponse } from "modules/order-list/types/order-element-response";

type OrderFormElementTypeGroup = OrderFormElementType | "EKG";

function useOrderListElements(
  elementType: OrderFormElementTypeGroup,
  enabled = true
) {
  return useAxiosQuery(
    ["orderListElements", elementType],
    () =>
      axiosChartInstance.get<OrderElementResponse[]>(
        `v1/order-form/elements/filter?formElementType=${elementType}`,
        {}
      ),
    { enabled }
  );
}
export { useOrderListElements };
export type { OrderFormElementTypeGroup };
