import { SelectOption } from "types";

const UNITS_ARRAY: SelectOption<string>[] = [
  { label: "mcg/kg", value: "mcg/kg" },
  { label: "mg/kg", value: "mg/kg" },
  { label: "g/kg", value: "g/kg" },
  { label: "mg", value: "mg" },
  { label: "g", value: "g" },
  { label: "mcg", value: "mcg" },
  { label: "mL", value: "mL" },
  { label: "L", value: "L" },
  { label: "units", value: "units" },
  { label: "mEq", value: "mEq" },
  { label: "iu", value: "iu" },
  { label: "oz", value: "oz" },
  { label: "drops", value: "drops" },
  { label: "spray", value: "spray" },
  { label: "package", value: "package" },
];

const CONCENTRATION_DOSE_UNITS: SelectOption<string>[] = [
  { label: "mg", value: "mg" },
  { label: "g", value: "g" },
  { label: "mcg", value: "mcg" },
  { label: "units", value: "units" },
  { label: "mEq", value: "mEq" },
  { label: "iu", value: "iu" },
  { label: "L", value: "L" },
];

const VOLUME_UNITS: SelectOption<string>[] = [
  { label: "mL", value: "mL" },
  { label: "L", value: "L" },
  { label: "mcg", value: "mcg" },
  { label: "mg", value: "mg" },
  { label: "g", value: "g" },
  { label: "units", value: "units" },
  { label: "mEq", value: "mEq" },
  { label: "iu", value: "iu" },
  { label: "minutes", value: "minutes" },
];

export { UNITS_ARRAY, CONCENTRATION_DOSE_UNITS, VOLUME_UNITS };
