import { Icon } from "@chakra-ui/react";
import { EmrProcedures } from "@medstonetech/slate-icons";
import { usePermissions } from "contexts";
import { TriageChartCode } from "enums";
import {
  ChartRouteBaseParams,
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSection,
  ChartSectionHeader,
  ChartSubsection,
  ChartSubsectionContent,
  ChartSubsectionHeader,
} from "modules";
import { FormProvider } from "react-hook-form";
import { Loading, StaffAvatar } from "shared";
import { useTriageChartProceduresForm } from "../../hooks";
import { ProceduresHeader, proceduresConstants } from "../../shared";
import { useParams } from "react-router-dom";

const sectionId = "SPRO";
const chartCode: TriageChartCode = "T007x";

function N07Procedures() {
  const {
    getIsLoading,
    onSubmit,
    updateIsLoading,
    useFormReturn,
    onSaveAndNext,
  } = useTriageChartProceduresForm({
    chartCode: chartCode,
    sectionId: sectionId,
  });
  const { scope } = usePermissions();
  const isReadOnly = !scope("nurse:proc").isEditable;

  const { getValues } = useFormReturn;
  const sections = getValues("sections");

  const { encounterId = "" } = useParams<ChartRouteBaseParams>();

  if (getIsLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormReturn}>
      <ChartSection
        onSubmit={onSubmit}
        disabled={isReadOnly}
        onRouteChangeSave={(onComplete) => {
          onSubmit();
          onComplete();
        }}
      >
        <ChartSectionHeader
          icon={<Icon as={EmrProcedures} />}
          showNotesBtn={false}
          showActionsBtns
          isLoading={updateIsLoading}
          onSaveAndNext={onSaveAndNext}
        >
          Procedures {isReadOnly ? "(Read Only)" : ""}
        </ChartSectionHeader>

        <ChartSubsectionHeader
          chartId={chartCode}
          encounterId={encounterId}
          sectionId={sectionId}
          subsectionId="Q001"
          subsectionLabel=""
          showNotesBtn={false}
        />
        <ProceduresHeader />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[0] && sections[0].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[0] && sections[0].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[0].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[0].${chartCode}${sectionId}Q001A001`}
                    label="portable"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[0].${chartCode}${sectionId}Q001A002`}
                    label="to Xray"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[0].${chartCode}${sectionId}Q001A041`}
                    label="w/:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[0].${chartCode}${sectionId}Q001A003`}
                    label="monitor"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[0].${chartCode}${sectionId}Q001A004`}
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[0].${chartCode}${sectionId}Q001A005`}
                    label="O2"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[0].${chartCode}${sectionId}Q001A006`}
                    label="tech"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[0].${chartCode}${sectionId}Q001A050`}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[1] && sections[1].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[1] && sections[1].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[1].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[1].${chartCode}${sectionId}Q001A007`}
                    label="return to room"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[1].${chartCode}${sectionId}Q001A008`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[2] && sections[2].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[2] && sections[2].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[2].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A009`}
                    label="to CT"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A044`}
                    label="w/:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A010`}
                    label="contrast"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A011`}
                    label="parenteral meds"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A051`}
                    label="O2"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A052`}
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A042`}
                    label="w/:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A012`}
                    label="monitor"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A013`}
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A014`}
                    label="O2"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[2].${chartCode}${sectionId}Q001A015`}
                    label="tech"
                  />
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[3] && sections[3].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[3] && sections[3].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[3].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[3].${chartCode}${sectionId}Q001A016`}
                    label="return to room"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[3].${chartCode}${sectionId}Q001A017`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[4] && sections[4].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[4] && sections[4].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[4].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[4].${chartCode}${sectionId}Q001A053`}
                    label="labs drawn"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[4].${chartCode}${sectionId}Q001A054`}
                    label="labs sent"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[4].${chartCode}${sectionId}Q001A055`}
                    label="by:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[4].${chartCode}${sectionId}Q001A056`}
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[4].${chartCode}${sectionId}Q001A057`}
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[4].${chartCode}${sectionId}Q001A058`}
                    label="lab"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[4].${chartCode}${sectionId}Q001A059`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[5] && sections[5].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[5] && sections[5].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[5].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[5].${chartCode}${sectionId}Q001A018`}
                    label="lasceration repair"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[5].${chartCode}${sectionId}Q001A045`}
                    label="to:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[5].${chartCode}${sectionId}Q001A019`}
                    label="scalp"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[5].${chartCode}${sectionId}Q001A020`}
                    label="face"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[5].${chartCode}${sectionId}Q001A060`}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[6] && sections[6].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[6] && sections[6].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[6].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[6].${chartCode}${sectionId}Q001A048`}
                    label="assisted by"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[6].${chartCode}${sectionId}Q001A022`}
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[6].${chartCode}${sectionId}Q001A023`}
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[6].${chartCode}${sectionId}Q001A043`}
                    label="site:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[6].${chartCode}${sectionId}Q001A024`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[7] && sections[7].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[7] && sections[7].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[7].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[7].${chartCode}${sectionId}Q001A025`}
                    label="foreign body removed"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[7].${chartCode}${sectionId}Q001A026`}
                    label="see additional notes"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[7].${chartCode}${sectionId}Q001A061`}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[8] && sections[8].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[8] && sections[8].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[8].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[8].${chartCode}${sectionId}Q001A046`}
                    label="assisted by:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[8].${chartCode}${sectionId}Q001A027`}
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[8].${chartCode}${sectionId}Q001A028`}
                    label="tech"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[8].${chartCode}${sectionId}Q001A062`}
                    size="xl"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[8].${chartCode}${sectionId}Q001A049`}
                    label="site:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[8].${chartCode}${sectionId}Q001A029`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[9] && sections[9].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[9] && sections[9].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[9].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[9].${chartCode}${sectionId}Q001A030`}
                    label="eye irrigation"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[9].${chartCode}${sectionId}Q001A063`}
                    label="L"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[9].${chartCode}${sectionId}Q001A064`}
                    label="R"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[9].${chartCode}${sectionId}Q001A031`}
                    label="flourescein"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[9].${chartCode}${sectionId}Q001A065`}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[13] && sections[13].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[13] && sections[13].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[13].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[13].${chartCode}${sectionId}Q001A066`}
                    label="irrigation by:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[13].${chartCode}${sectionId}Q001A067`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[10] && sections[10].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[10] && sections[10].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[10].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[10].${chartCode}${sectionId}Q001A032`}
                    label="Morgan lens"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[10].${chartCode}${sectionId}Q001A068`}
                    size="xl"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[10].${chartCode}${sectionId}Q001A033`}
                    label="sol"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[10].${chartCode}${sectionId}Q001A069`}
                    size="xl"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[10].${chartCode}${sectionId}Q001A034`}
                    label="amt"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[10].${chartCode}${sectionId}Q001A070`}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[14] && sections[14].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[14] && sections[14].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[14].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[14].${chartCode}${sectionId}Q001A071`}
                    label="assisted by:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[14].${chartCode}${sectionId}Q001A072`}
                    label="burse"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[14].${chartCode}${sectionId}Q001A073`}
                    label="tech"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[14].${chartCode}${sectionId}Q001A074`}
                    size="xl"
                  />
                  <ChartRowElement
                    type="option"
                    label="site:"
                    fontStyle="italic"
                    name={`
                      sections[14].${chartCode}${sectionId}Q001A075`}
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[14].${chartCode}${sectionId}Q001A076`}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[15] && sections[15].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[15] && sections[15].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[15].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[15].${chartCode}${sectionId}Q001A077`}
                    label="results:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[15].${chartCode}${sectionId}Q001A078`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[12] && sections[12].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[12] && sections[12].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[12].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[12].${chartCode}${sectionId}Q001A047`}
                    label="applied:"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[12].${chartCode}${sectionId}Q001A037`}
                    label="eye patch"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[12].${chartCode}${sectionId}Q001A038`}
                    label="dressing"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[12].${chartCode}${sectionId}Q001A039`}
                    label="Band-Aid"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[12].${chartCode}${sectionId}Q001A040`}
                    label="elastic wrap"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[12].${chartCode}${sectionId}Q001A079`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[16] && sections[16].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[16] && sections[16].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[16].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[16].${chartCode}${sectionId}Q001A080`}
                    label="Foley"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[16].${chartCode}${sectionId}Q001A081`}
                    label="straight cath"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[16].${chartCode}${sectionId}Q001A082`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[17] && sections[17].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[17] && sections[17].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[17].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[17].${chartCode}${sectionId}Q001A083`}
                    label="dislocation"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[17].${chartCode}${sectionId}Q001A084`}
                    label="fx reduction"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[17].${chartCode}${sectionId}Q001A085`}
                  />
                </ChartRowGroup>
              </ChartRow>
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <ChartRowElement type="empty" />
                </ChartRowGroup>
                <ChartRowElement type="empty" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[17].${chartCode}${sectionId}Q001A086`}
                    label="assisted by:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[17].${chartCode}${sectionId}Q001A087`}
                    label="nurse"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[17].${chartCode}${sectionId}Q001A088`}
                    label="tech"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[17].${chartCode}${sectionId}Q001A089`}
                    label="site:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[17].${chartCode}${sectionId}Q001A090`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[18] && sections[18].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[18] && sections[18].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[18].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[18].${chartCode}${sectionId}Q001A091`}
                    label="splint applied"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[18].${chartCode}${sectionId}Q001A092`}
                    label="sling applied"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[18].${chartCode}${sectionId}Q001A093`}
                    label="arm"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[18].${chartCode}${sectionId}Q001A094`}
                    label="leg"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[18].${chartCode}${sectionId}Q001A095`}
                    label="short"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[18].${chartCode}${sectionId}Q001A096`}
                    label="long"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[18].${chartCode}${sectionId}Q001A097`}
                    label="type:"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[18].${chartCode}${sectionId}Q001A098`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[19] && sections[19].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[19] && sections[19].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[19].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[19].${chartCode}${sectionId}Q001A099`}
                    label="post splint assessment:"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[19].${chartCode}${sectionId}Q001A100`}
                    label="nml"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[19].${chartCode}${sectionId}Q001A101`}
                    label="color"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[19].${chartCode}${sectionId}Q001A102`}
                    label="sensation"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[19].${chartCode}${sectionId}Q001A103`}
                    label="movement"
                    fontStyle="italic"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[19].${chartCode}${sectionId}Q001A104`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />

        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[11] && sections[11].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[11] && sections[11].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[11].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[11].${chartCode}${sectionId}Q001A035`}
                    label="cleaned wound"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[11].${chartCode}${sectionId}Q001A036`}
                    label="applied abx ointment"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[11].${chartCode}${sectionId}Q001A105`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[20] && sections[20].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[20] && sections[20].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[20].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[20].${chartCode}${sectionId}Q001A106`}
                    label="crutch training"
                  />
                  <ChartRowElement
                    type="option"
                    name={`
                      sections[20].${chartCode}${sectionId}Q001A107`}
                    label="w/ proper return demonstration"
                  />
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[20].${chartCode}${sectionId}Q001A108`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[21] && sections[21].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[21] && sections[21].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[21].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[21].${chartCode}${sectionId}Q001A109`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[22] && sections[22].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[22] && sections[22].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[22].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[22].${chartCode}${sectionId}Q001A110`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[23] && sections[23].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[23] && sections[23].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[23].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[23].${chartCode}${sectionId}Q001A111`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[24] && sections[24].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[24] && sections[24].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[24].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[24].${chartCode}${sectionId}Q001A112`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
        <ChartSubsection
          content={
            <ChartSubsectionContent
              padding={proceduresConstants.PROCEDURES_CONTAINER_PADDING}
            >
              <ChartRow
                templateColumns={
                  proceduresConstants.PROCEDURES_ROW_TEMPLATE_COLUMNS_2
                }
              >
                <ChartRowGroup>
                  <StaffAvatar
                    maxWidth="32px"
                    size="xs"
                    fontSize=".25rem"
                    w="32px"
                    h="32px"
                    userName={
                      (sections[25] && sections[25].createdByFullName) || ""
                    }
                    profileUrl={
                      (sections[25] && sections[25].createdByPictureUrl) || ""
                    }
                    justifySelf="center"
                  />
                </ChartRowGroup>
                <ChartRowElement type="date" name="sections[25].date" />
                <ChartRowGroup>
                  <ChartRowElement
                    type="input"
                    name={`
                      sections[25].${chartCode}${sectionId}Q001A113`}
                  />
                </ChartRowGroup>
              </ChartRow>
            </ChartSubsectionContent>
          }
        />
      </ChartSection>
    </FormProvider>
  );
}

export { N07Procedures };
