import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const ChartMedicalRecords = createIcon({
  path: (
    <path
      d="M5.46341 5.17073C4.85854 5.17073 4.4878 4.79024 4.4878 4.14634V3.1122C4.4878 2.46829 4.85854 2.08781 5.46341 2.08781H6.23415C6.31219 0.936585 7.26829 0 8.45854 0C9.64878 0 10.6049 0.936585 10.6829 2.08781H11.4537C12.0585 2.08781 12.4293 2.46829 12.4293 3.1122V4.14634C12.4293 4.79024 12.0585 5.17073 11.4537 5.17073H5.46341ZM8.45854 3.0439C8.93658 3.0439 9.32683 2.6439 9.32683 2.17561C9.32683 1.69756 8.93658 1.30732 8.45854 1.30732C7.98049 1.30732 7.59024 1.69756 7.59024 2.17561C7.59024 2.6439 7.98049 3.0439 8.45854 3.0439ZM3.2878 24C1.17073 24 0 22.8195 0 20.6732V5.92195C0 3.78537 1.17073 2.59512 3.21951 2.59512H3.40488C3.34634 2.72195 3.32683 2.87805 3.32683 3.02439V4.14634C3.32683 5.42439 4.18537 6.34146 5.46341 6.34146H11.4537C12.7317 6.34146 13.5902 5.42439 13.5902 4.14634V3.02439C13.5902 2.87805 13.5707 2.72195 13.5122 2.59512H13.6976C15.7463 2.59512 16.9171 3.78537 16.9171 5.92195V20.6732C16.9171 22.8195 15.7463 24 13.6293 24H3.2878ZM6.16585 13.522C6.2439 13.522 6.34146 13.4634 6.42927 13.4049C7.20976 12.9366 8.71219 11.7756 8.71219 10.3902C8.71219 9.52195 8.11707 8.89756 7.30732 8.89756C6.79024 8.89756 6.38049 9.21951 6.1561 9.62927C5.93171 9.21951 5.53171 8.89756 4.98537 8.89756C4.20488 8.89756 3.6 9.52195 3.6 10.3902C3.6 11.7756 5.12195 12.8878 5.89268 13.4049C5.98049 13.4732 6.0878 13.522 6.16585 13.522ZM4.14634 16.6146H12.7805C13.161 16.6146 13.4537 16.322 13.4537 15.9415C13.4537 15.5805 13.161 15.2878 12.7805 15.2878H4.14634C3.76585 15.2878 3.47317 15.5805 3.47317 15.9415C3.47317 16.322 3.76585 16.6146 4.14634 16.6146ZM4.14634 20.4195H9.18049C9.55122 20.4195 9.85366 20.1171 9.85366 19.7463C9.85366 19.3756 9.56098 19.0829 9.18049 19.0829H4.14634C3.76585 19.0829 3.47317 19.3756 3.47317 19.7463C3.47317 20.1171 3.76585 20.4195 4.14634 20.4195Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 17 24",
});

export { ChartMedicalRecords };
