import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";
import { UserType } from "modules/identity";

type UseTeamDetail = {
  id: string;
  code: string;
  name: string;
  type: UserType;
  other: string;
  permissions: {
    id: string;
    name: string;
  }[];
};

function useTeamDetail(teamId: string, options?: { enabled: boolean }) {
  return useAxiosQuery(
    ["teamDetail", { teamId }],
    () => axiosIdentityInstance.get<UseTeamDetail>(`/v1/team/${teamId}`),
    options
  );
}

export { useTeamDetail };
