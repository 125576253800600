import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseTeamsListResponse = {
  id: string;
  name: string;
  users: number;
};

function useTeamsList() {
  return useAxiosQuery(
    ["teamsList"],
    () =>
      axiosIdentityInstance.get<UseTeamsListResponse[]>(`/v1/team/teams-list`),
    { staleTime: 1000 * 60 * 4 }
  );
}

export type { UseTeamsListResponse };
export { useTeamsList };
