import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartApplicationStatus } from "modules/charts-shared/types";
import { UserType } from "modules/identity";
import { CreatedBy } from "types";

type DispositionInfoUser = {
  type: string;
  title: string | null;
  teams: {
    id: string;
    code: string;
    name: string;
    type: UserType;
    other: string;
    permissions: [
      {
        id: "string";
        name: string;
      }
    ];
  }[];
} & CreatedBy;

type UseChartDispositionInfoResponse = {
  chartCompletedAt: Date;
  createdBy: DispositionInfoUser;
  chartStatus: ChartApplicationStatus;
  chartCompletedBy: DispositionInfoUser;
};

type UseChartDispositionInfoParams = {
  encounterId: string;
  chartCode: string;
  enabled: boolean;
};

function useChartDispositionInfo(params: UseChartDispositionInfoParams) {
  const { encounterId, chartCode, enabled } = params;
  return useAxiosQuery(
    ["chartDispositionInfo", { encounterId, chartCode }],
    () =>
      axiosChartInstance.get<UseChartDispositionInfoResponse>(
        `v1/chart-application/${encounterId}/${chartCode}/disposition-info`
      ),
    { enabled }
  );
}

export type { UseChartDispositionInfoParams, UseChartDispositionInfoResponse };
export { useChartDispositionInfo };
