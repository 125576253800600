import { createIcon } from "@chakra-ui/react";
import * as React from "react";

const LockWarning = createIcon({
  path: (
    <path
      d="M47.2905 69.6898C47.6629 68.9548 47.8863 68.0447 47.8863 67.1697C47.8863 65.5246 47.365 63.8094 46.4713 62.3393L30.3851 35.2124C28.4488 31.9222 24.9485 30.2071 21.2993 30.2071C20.778 30.2071 20.294 30.2421 19.8099 30.3471C20.927 28.982 22.5654 28.142 24.7624 27.9319V19.7064C24.7624 6.40544 34.146 0 44.0137 0C53.8814 0 63.265 6.40544 63.265 19.7064V27.9319C67.7334 28.457 70.0421 31.2922 70.0421 36.3325V61.1142C70.0421 66.8546 67.1004 69.6898 61.4404 69.6898H47.2905ZM31.7628 27.7919H56.3018V19.0063C56.3018 10.7807 50.679 6.26544 44.0137 6.26544C37.3483 6.26544 31.7628 10.7807 31.7628 19.0063V27.7919ZM5.21313 72C2.04801 72 0 69.8298 0 67.0997C0 66.2596 0.22342 65.4545 0.707496 64.6845L16.7937 37.5576C17.7991 35.9125 19.5492 35.0724 21.3366 35.0724C23.0867 35.0724 24.7996 35.8775 25.8422 37.5576L41.9657 64.6845C42.4498 65.4545 42.6732 66.2596 42.6732 67.0997C42.6732 69.8298 40.6252 72 37.4228 72H5.21313ZM21.3366 58.139C22.7516 58.139 23.7942 57.229 23.8314 55.8989L24.1293 46.1682C24.2038 44.6281 23.0495 43.543 21.3366 43.543C19.6237 43.543 18.4694 44.6281 18.5438 46.1682L18.8417 55.8989C18.879 57.229 19.8844 58.139 21.3366 58.139ZM21.3366 66.6796C23.2357 66.6796 24.6879 65.3145 24.6879 63.5644C24.6879 61.8843 23.1984 60.4842 21.3366 60.4842C19.4748 60.4842 17.9853 61.8493 17.9853 63.5644C17.9853 65.3145 19.4748 66.6796 21.3366 66.6796Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 71 72",
});

export { LockWarning };
