import {
  Box,
  Icon,
  useDisclosure,
  Text,
  IconButton,
  HStack,
  VStack,
  Image,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Button, Input, StaffAvatar } from "shared";
import {
  DispositionForm,
  DispositionSignatureForm,
} from "modules/charts-shared/types";
import {
  ChartRow,
  ChartRowElement,
  ChartRowGroup,
  ChartSubsection,
  ChartSubsectionContent,
  SharedChartSubsectionHeader,
} from "../chart";
import { EmrRemove, EmrSignature } from "@medstonetech/slate-icons";
import { ProviderChartCode, SignatureType, TriageChartCode } from "enums";
import { Control, useFieldArray } from "react-hook-form";
import { enumMapper } from "utils";
import { AddRounded } from "icons";
import { SignatureScribedInPresenseOfModal } from "./SignatureScribedInPresenseOfModal";
import { useAuth0 } from "@auth0/auth0-react";
import { USERID_CLAIMS } from "system-constants";
import { useAddUserSignature } from "modules/onboarding/api";
import { useToast } from "hooks";
import { formMessages, genericErrors } from "messages";
import React from "react";
import SignatureModal from "modules/onboarding/components/signature/SignatureModal";
import { useUserRoles } from "contexts/UserRoles";

type DispositionSignatureItemProps = {
  signature: DispositionSignatureForm;
  onDelete: (uuid?: string) => void;
  signatures: DispositionSignatureForm[];
  control: Control<DispositionForm>;
  useDelete?: boolean;
};

const DispositionSignatureItem = ({
  signature,
  signatures,
  control,
  onDelete,
  useDelete,
}: DispositionSignatureItemProps) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSignatureOpen,
    onClose: onSignatureClose,
    onOpen: onSignatureOpen,
  } = useDisclosure();
  const { user: currentUser } = useAuth0();
  const userId = currentUser ? currentUser[USERID_CLAIMS] : "";

  const { roles } = useUserRoles();
  const isAllowedToRemoveSignature = React.useMemo(() => {
    const rolesAllowedToRemoveSignature = roles.includes("Administrator");
    const usersAllowedToDeleteSignature =
      signature.userId === userId && signature.signed;

    return rolesAllowedToRemoveSignature || usersAllowedToDeleteSignature;
  }, [roles, signature, userId]);

  const { update } = useFieldArray({
    control,
    name: "signatures",
  });

  const handleRemoveScribed = () => {
    const index = signatures.findIndex(
      (item) => item.userId === signature.userId
    );

    const signatureUpdated = signatures.find(
      (item) => item.userId === signature.userId
    );

    if (index >= 0 && !!signatureUpdated) {
      update(index, {
        ...signatures[index],
        scribedInPresenseOf: undefined,
        scribedInPresenseOfUser: { id: "", fullName: "" },
      });
      onClose();
    }
  };

  const handleUpdateSignature = (value: boolean) => {
    const index = signatures.findIndex(
      (item) => item.userId === signature.userId
    );

    const signatureUpdated = signatures.find(
      (item) => item.userId === signature.userId
    );

    if (index >= 0 && !!signatureUpdated) {
      update(index, {
        signed: value,
      });
    }
  };

  const { mutateAsync: addSignature, isLoading: isAddSignatureLoading } =
    useAddUserSignature(userId);

  const handleOnSign = async (newSignature: string) => {
    try {
      await addSignature({ signature: newSignature });
      const index = signatures.findIndex(
        (item) => item.userId === signature.userId
      );

      const signatureUpdated = signatures.find(
        (item) => item.userId === signature.userId
      );

      if (index >= 0 && !!signatureUpdated && signature.user) {
        update(index, {
          signed: true,
          user: { ...signature.user, signature: newSignature },
        });
      }

      toast({
        status: "success",
        description: formMessages.createSuccess("Signature"),
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          description: error.message || genericErrors.unknownError,
        });
      }
    }
  };

  return (
    <ChartSubsectionContent>
      {signature.type === "Scribe" && (
        <ChartRow>
          <Text pl={4}>
            I personally scribed the information in this document as dictated to
            me and in the presence of
          </Text>
          <InputGroup size="lg" w="300px">
            <Input
              pr="4.5rem"
              isReadOnly
              value={signature.scribedInPresenseOfUser?.fullName}
            />
            <InputRightElement>
              {!signature.scribedInPresenseOf ? (
                <IconButton
                  aria-label="add-scribe"
                  icon={
                    <Icon as={AddRounded} fontSize="1.25rem" color={"blue"} />
                  }
                  variant="ghost"
                  onClick={onOpen}
                />
              ) : (
                <IconButton
                  aria-label="remove-scribe"
                  icon={
                    <Icon
                      as={EmrRemove}
                      fontSize="1.25rem"
                      color={"lightgray"}
                    />
                  }
                  variant="ghost"
                  onClick={handleRemoveScribed}
                />
              )}
            </InputRightElement>
          </InputGroup>
          <SignatureScribedInPresenseOfModal
            isOpen={isOpen}
            onClose={onClose}
            userId={signature.userId}
            signatures={signatures}
            control={control}
          />
        </ChartRow>
      )}
      {signature.type === "SupervisingProvider" && (
        <ChartRow>
          <Text pl={4}>
            I have personally seen, spoken with, and examined the labs and
            imaging. I agree with the findings and plan of care with the overall
            addendum.
          </Text>
        </ChartRow>
      )}
      <ChartRow
        sx={{
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        <ChartRowElement
          type="container"
          content={
            <>
              <HStack>
                <StaffAvatar
                  w="32px"
                  h="32px"
                  size="xs"
                  fontSize=".75rem"
                  userName={signature.user?.fullName || ""}
                  profileUrl={signature.user?.pictureUrl || ""}
                />
                <VStack spacing="0" alignItems="flex-start">
                  <Box
                    fontSize="1.0625rem"
                    fontWeight="600"
                    lineHeight="1.5rem"
                    color="black"
                  >
                    {signature.user?.fullName}
                  </Box>
                  <Box fontSize="0.875rem" fontWeight="400" lineHeight="1rem">
                    {signature.user?.teams}
                  </Box>
                </VStack>
              </HStack>
            </>
          }
        />
        <ChartRowGroup>
          <ChartRowElement
            type="container"
            content={
              <>
                <HStack>
                  <Text>E-signed by:</Text>
                  <Text
                    color="black"
                    fontSize="1.0625rem"
                    fontWeight="600"
                    lineHeight="1.5rem"
                  >
                    {signature.user?.fullName}
                  </Text>
                </HStack>
              </>
            }
          />
        </ChartRowGroup>
        <ChartRowGroup>
          <ChartRowElement
            type="container"
            minW={300}
            maxW={400}
            minH={50}
            content={
              <Box>
                {signature.signed ? (
                  <Image
                    src={signature.user?.signature}
                    height="100%"
                    width="100%"
                  />
                ) : (
                  <>
                    <Button
                      variant="label"
                      color="blue"
                      disabled={signature.userId !== userId}
                      onClick={() => {
                        if (signature.user?.signature) {
                          handleUpdateSignature(true);
                        } else {
                          onSignatureOpen();
                        }
                      }}
                      isLoading={isAddSignatureLoading}
                    >
                      <Icon
                        as={EmrSignature}
                        fontSize="1.5rem"
                        color="blue"
                        m={2}
                      />
                      Add Signature
                    </Button>
                  </>
                )}
              </Box>
            }
          />
          <React.Suspense fallback={null}>
            <SignatureModal
              isOpen={isSignatureOpen}
              onClose={onSignatureClose}
              onSign={handleOnSign}
              isLoading={isAddSignatureLoading}
            />
          </React.Suspense>
        </ChartRowGroup>
        <ChartRowGroup justifyContent="end" pr={4}>
          <IconButton
            aria-label="sign"
            size="iconSm"
            icon={<Icon as={EmrRemove} fontSize="1.25rem" color={"red"} />}
            maxWidth="24px"
            variant="ghost"
            disabled={!isAllowedToRemoveSignature}
            onClick={() =>
              useDelete
                ? onDelete(signature.uuid)
                : handleUpdateSignature(false)
            }
          />
        </ChartRowGroup>
      </ChartRow>
    </ChartSubsectionContent>
  );
};

type DispositionSignaturesProps = DispositionSignatureSubsectionProps & {
  title: string;
  type: SignatureType;
};

const DispositionSignatures = ({
  title,
  chartCode,
  encounterId,
  sectionCode,
  chartType,
  signatures,
  type,
  control,
}: DispositionSignaturesProps) => {
  const signaturesFiltered =
    signatures?.filter((signature) => signature.type === type) ?? [];

  const { remove } = useFieldArray({
    control,
    name: "signatures",
  });

  const { insert } = useFieldArray({
    control,
    name: "signaturesToDelete",
  });

  const handleDelete = (uuid?: string) => {
    const index = signatures?.findIndex((signature) => signature.uuid === uuid);

    if (index !== undefined) {
      remove(index);
    }

    const signature = signatures?.find((item) => item.uuid === uuid);

    if (signature && signature.id) {
      insert(0, signature);
    }
  };

  return !!signaturesFiltered?.length ? (
    <ChartSubsection
      header={
        <SharedChartSubsectionHeader
          chartCode={chartCode}
          encounterId={encounterId}
          sectionCode={sectionCode}
          subsectionCode="Q006"
          subsectionLabel={`${title} Signature`}
          showNotesBtn={chartType === "Triage" ? false : undefined}
          showAuditsBtn={chartType === "Chart" || chartType === "Triage"}
          showInteractionsBtn={false}
        >
          {title} Signature
        </SharedChartSubsectionHeader>
      }
      content={
        <>
          {signaturesFiltered.map((signature) => {
            return (
              <DispositionSignatureItem
                key={`${signature.userId}`}
                signature={signature}
                onDelete={handleDelete}
                control={control}
                signatures={signatures}
                useDelete
              />
            );
          })}
        </>
      }
    />
  ) : (
    <></>
  );
};

type DispositionSignatureSubsectionProps = {
  chartCode: TriageChartCode | ProviderChartCode;
  encounterId: string;
  sectionCode: string;
  chartType: string;
  signatures: DispositionSignatureForm[];
  control: Control<DispositionForm>;
};

const DispositionSignatureSubsection = (
  props: DispositionSignatureSubsectionProps
) => {
  const sigantureTypes = enumMapper.getOptions("signatureType");
  return (
    <>
      {sigantureTypes.map((type) => {
        return (
          <DispositionSignatures
            key={type.value}
            title={type.label}
            type={type.value}
            {...props}
          />
        );
      })}
    </>
  );
};

export { DispositionSignatureSubsection, DispositionSignatureItem };
