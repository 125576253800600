import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type RemoveUsersPayload = string[];

function useRemoveUsers() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["disableUsers"],
    (input: RemoveUsersPayload) =>
      axiosIdentityInstance.delete("/v1/user/remove-users", { data: input }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("searchEnabledUsers");
        queryClient.invalidateQueries("searchDisabledUsers");
        queryClient.invalidateQueries("permissionsCounters");
      },
    }
  );
}

export { useRemoveUsers };
