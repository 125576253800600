import { axiosChartInstance } from "config";
import { SignedSection } from "./useCreateSignedSection";
import { useQuery } from "react-query";

function useGetSignedSection(encounterId: string, type: SignedSection) {
  return useQuery(
    ["getSignedSection", { encounterId, type }],
    () =>
      axiosChartInstance.get<Blob>(
        `v1/sectionSummary/${encounterId}/signed-section/file/${type}`,
        { responseType: "blob" }
      ),
    { staleTime: 60000 }
  );
}

export { useGetSignedSection };
