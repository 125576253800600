import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type EditUserPayload = FormData;

function useEditUser(userId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["editUser", { userId }],
    (input: EditUserPayload) =>
      axiosIdentityInstance.put(`/v1/user/${userId}`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getUser", { userId }]);
        queryClient.invalidateQueries(["getUserProfile", { userId }]);
        queryClient.invalidateQueries(["staffProfilePicture", { userId }]);
        queryClient.invalidateQueries("searchTeamMembers");
        queryClient.invalidateQueries("searchEnabledUsers");
        queryClient.invalidateQueries("permissionsCounters");
      },
    }
  );
}

export { useEditUser };
