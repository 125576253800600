import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import {
  ProviderHardStopsResponse,
  TriageHardStopsResponse,
} from "modules/charts-shared/components/disposition/types";
import { ChartType } from "types";

type UseChartHardStopsResponse = {
  encounterId: string;
  chartCode: string;
  triageHardStops?: TriageHardStopsResponse;
  providerHardStops?: ProviderHardStopsResponse;
};

type UseChartHardStopsParams = {
  encounterId: string;
  chartCode: string;
  chartType: ChartType;
  enabled: boolean;
};

function useChartHardStops(params: UseChartHardStopsParams) {
  const { encounterId, chartCode, chartType, enabled } = params;
  return useAxiosQuery(
    ["chartApplicationHardStops", { encounterId, chartCode, chartType }],
    () =>
      axiosChartInstance.get<UseChartHardStopsResponse>(
        `v1/chart-application/${encounterId}/${chartCode}/hard-stops/${chartType}`
      ),
    { enabled }
  );
}

export type { UseChartHardStopsParams, UseChartHardStopsResponse };
export { useChartHardStops };
