import { createIcon } from "@chakra-ui/react";

const Bag = createIcon({
  path: (
    <path d="M3.68689 24H17.5068C19.6086 24 20.8415 22.7789 20.8415 20.3601V8.20744C20.8415 5.78865 19.5969 4.56751 17.1546 4.56751H15.2759C15.2172 2.10176 13.0685 0 10.4149 0C7.76125 0 5.61252 2.10176 5.55382 4.56751H3.68689C1.23288 4.56751 0 5.77691 0 8.20744V20.3601C0 22.7906 1.23288 24 3.68689 24ZM10.4149 1.78474C12.0704 1.78474 13.3268 3.02935 13.3855 4.56751H7.44423C7.50293 3.02935 8.75929 1.78474 10.4149 1.78474Z" />
  ),
  viewBox: "0 0 21 24",
});

export { Bag };
