import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const GiftCard = createIcon({
  path: (
    <path
      d="M12.674 4.95319V0H20.7932C22.9379 0 24 1.06213 24 3.16596V8.58893H16.3813C17.76 7.97617 18.6996 6.84255 18.6996 5.47404C18.6996 3.89106 17.5353 2.7166 15.9626 2.7166C14.6043 2.7166 13.3889 3.56425 12.674 4.95319ZM10.8153 8.42553C8.68085 8.42553 6.85277 6.96511 6.85277 5.59659C6.85277 4.77957 7.35319 4.27915 8.12936 4.27915C9.74298 4.27915 11.1421 6.06638 11.1421 8.11915V8.42553H10.8153ZM13.1949 8.42553H12.8681V8.11915C12.8681 6.06638 14.2672 4.27915 15.8809 4.27915C16.6672 4.27915 17.1574 4.77957 17.1574 5.59659C17.1574 6.96511 15.3396 8.42553 13.1949 8.42553ZM0 8.58893V3.16596C0 1.06213 1.07234 0 3.20681 0H11.3362V4.95319C10.6315 3.56425 9.41617 2.7166 8.04766 2.7166C6.48511 2.7166 5.31064 3.89106 5.31064 5.47404C5.31064 6.84255 6.26043 7.97617 7.62894 8.58893H0ZM17.9438 13.2153C16.4426 13.0621 14.3591 11.1013 13.7362 9.80425H24V14.2877C24 16.3915 22.9379 17.4434 20.7932 17.4434H12.674V10.56H12.7557C13.4604 12.7864 16.1464 14.7574 17.8213 15.0026C18.4443 15.0843 18.8323 14.6349 18.8323 14.1549C18.8323 13.6136 18.5157 13.2664 17.9438 13.2153ZM3.20681 17.4434C1.07234 17.4434 0 16.3915 0 14.2877V9.80425H10.2843C9.65106 11.1013 7.57787 13.0417 6.06638 13.2153C5.50468 13.2766 5.18809 13.6136 5.18809 14.1549C5.18809 14.6349 5.57617 15.0843 6.19915 15.0026C7.87404 14.7574 10.56 12.7864 11.2647 10.56H11.3362V17.4434H3.20681Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 24 18",
});

export { GiftCard };
