import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartApplicationStatus } from "modules/charts-shared/types";
import { ChartType } from "types";

type UseActiveChartsResponseItem = {
  chartApplicationId: string;
  chartCode: string;
  chartTitle: string;
  chartType: ChartType;
  chartStatus: ChartApplicationStatus;
};

function useActiveCharts(encounterId: string) {
  return useAxiosQuery(["activeCharts", { encounterId }], () =>
    axiosChartInstance.get<UseActiveChartsResponseItem[]>(
      `v1/chart-application/${encounterId}`
    )
  );
}

export type { UseActiveChartsResponseItem };
export { useActiveCharts };
