import * as React from "react";

import {
  Box,
  BoxProps,
  Button,
  Text,
  useMergeRefs,
  useToast,
} from "@chakra-ui/react";
import { CalendarInput, UserAvatar } from "shared";
import { useDeleteVitalCheck, usePatchVitalCheck } from "../api";
import { VITALS_COLUMN_VALUE_KEYS } from "../constants/constants";
import { onValidateAcuityLevel, onValidatePainLevel } from "../utils";
import { getCellDisplay, getEditCellDisplay } from "../utils/utils";
import { EdgeVitalsCell, VitalsCell } from "./VitalsCell";
import {
  BaseVitalsEditCell,
  SlashVitalsEditCell,
  SpO2TypeVitalsEditCell,
  TemperatureVitalsEditCell,
} from "./VitalsEditCell";

import { format } from "date-fns";
import { formMessages } from "messages";
import { useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import { extractApiErrorMessage } from "utils";
import { useVitalsContext } from "../contexts";
import { VitalsColumn } from "../types";
import { FormPrompt } from "shared/prompt";

type VitalsInfiniteGridProps = BoxProps;

type VitalsAddColumnProps = {
  index: number;
};

type VitalsEditColumnProps = VitalsAddColumnProps & {
  record: VitalsColumn;
  encounterId: string;
};

function VitalsEditColumn(props: VitalsEditColumnProps) {
  const { index, encounterId } = props;
  const { isEditMode, editVitalsForm } = useVitalsContext();
  const toast = useToast();

  const { mutateAsync: deleteVitalCheck, isLoading: isDeleteLoading } =
    useDeleteVitalCheck(encounterId);

  const { isLoading } = usePatchVitalCheck({
    encounterId,
  });

  const { control } = editVitalsForm;
  const { fields, update } = useFieldArray({ control, name: "vitals" });

  const removeVitalCheck = async () => {
    try {
      await deleteVitalCheck(fields[index - 1].id);
      toast({ description: formMessages.deleteSuccess("Vital check") });
    } catch (err) {
      toast({ description: extractApiErrorMessage(err) });
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="240px"
      gridTemplateRows="repeat(12, 45px)"
      gridGap="1px"
    >
      <EdgeVitalsCell cellPosition="top">
        {isEditMode ? (
          <CalendarInput
            name={"takenAt"}
            dateFormat={"LL/dd/yyyy HH:mm"}
            showIcon={false}
            showTimeInput={true}
            shouldCloseOnSelect={false}
            onChange={(value) =>
              update(index - 1, {
                ...fields[index - 1],
                takenAt: value ?? new Date(),
              })
            }
            selected={
              fields[index - 1]?.takenAt
                ? new Date(fields[index - 1]?.takenAt)
                : null
            }
          />
        ) : (
          `${format(new Date(fields[index - 1]?.takenAt), "HH:mm")} (${index})`
        )}
      </EdgeVitalsCell>

      <BaseVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], acuity: Number(value) })
        }
        value={getEditCellDisplay("acuity", fields[index - 1].acuity)}
        title={
          fields[index - 1].acuity
            ? getEditCellDisplay("acuity", fields[index - 1].acuity)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Acuity"
        onValidate={onValidateAcuityLevel}
        inputProps={{ type: "number", min: 1, max: 5, width: "3rem" }}
        name="acuity"
      />

      <BaseVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], painLevel: Number(value) })
        }
        value={getEditCellDisplay("painLevel", fields[index - 1].painLevel)}
        title={
          fields[index - 1].painLevel
            ? getEditCellDisplay("painLevel", fields[index - 1].painLevel)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pain"
        onValidate={onValidatePainLevel}
        inputProps={{ type: "number", min: 0, max: 10, width: "3rem" }}
        name="painLevel"
      />

      <SlashVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], bp: value })
        }
        value={getEditCellDisplay("bp", fields[index - 1].bp)}
        title={
          fields[index - 1].bp
            ? getEditCellDisplay("bp", fields[index - 1].bp)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="BP"
        inputProps={{ width: "4.5rem" }}
      />

      <BaseVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], pulse: Number(value) })
        }
        value={getEditCellDisplay("pulse", fields[index - 1].pulse)}
        title={
          fields[index - 1].pulse
            ? getEditCellDisplay("pulse", fields[index - 1].pulse)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pulse"
        suffix="bpm"
        inputProps={{ width: "4rem" }}
        columnGap="0.5rem"
        name="pulse"
      />

      <BaseVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], rr: Number(value) })
        }
        value={getEditCellDisplay("rr", fields[index - 1].rr)}
        title={
          fields[index - 1].rr
            ? getEditCellDisplay("rr", fields[index - 1].rr)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="RR"
        suffix="brpm"
        inputProps={{ width: "4rem" }}
        columnGap="0.5rem"
        name="rr"
      />

      <TemperatureVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], temp: value })
        }
        value={getEditCellDisplay("temp", fields[index - 1].temp)}
        title={
          fields[index - 1].temp
            ? getEditCellDisplay("temp", fields[index - 1].temp)
            : undefined
        }
        disabledBtn={isLoading}
      />
      <SlashVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], spO2: value })
        }
        value={getEditCellDisplay("spO2", fields[index - 1].spO2)}
        title={
          fields[index - 1].spO2
            ? getEditCellDisplay("spO2", fields[index - 1].spO2)
            : undefined
        }
        disabledBtn={isLoading}
        leftSuffix="%"
        rightSuffix="L"
        inputProps={{ width: "4.5rem" }}
        modalTitle="SpO2"
      />

      <SpO2TypeVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], spO2Type: value })
        }
        title={
          fields[index - 1].spO2
            ? getEditCellDisplay("spO2Type", fields[index - 1].spO2Type)
            : undefined
        }
        value={fields[index - 1].spO2Type}
        modalTitle="SpO2 Type"
        disabledBtn={isLoading}
      />

      <BaseVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], gcs: Number(value) })
        }
        value={getEditCellDisplay("gcs", fields[index - 1].gcs)}
        title={
          fields[index - 1].gcs
            ? getEditCellDisplay("gcs", fields[index - 1].gcs)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="GCS"
        inputProps={{ width: "3rem" }}
        name="gcs"
      />

      <SlashVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], pupils: value })
        }
        value={getEditCellDisplay("pupils", fields[index - 1].pupils)}
        title={
          fields[index - 1].pupils
            ? getEditCellDisplay("pupils", fields[index - 1].pupils)
            : undefined
        }
        disabledBtn={isLoading}
        leftSuffix="mm"
        rightSuffix="mm"
        inputProps={{ width: "3rem" }}
        modalTitle="Pupils"
      />

      <BaseVitalsEditCell
        onSave={(value) =>
          update(index - 1, { ...fields[index - 1], fetalHt: value })
        }
        value={getEditCellDisplay("fetalHt", fields[index - 1].fetalHt)}
        title={
          fields[index - 1].fetalHt
            ? getEditCellDisplay("fetalHt", fields[index - 1].fetalHt)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Fetal HT"
        suffix="brpm"
        columnGap="0.5rem"
        inputProps={{ width: "4rem" }}
        name="fetalHt"
      />

      <EdgeVitalsCell cellPosition="bottom" height="56px">
        <Button
          colorScheme="red"
          height="2rem"
          px="1.25rem"
          minW="unset"
          onClick={removeVitalCheck}
          isLoading={isDeleteLoading}
          disabled={isDeleteLoading}
        >
          Remove
        </Button>
      </EdgeVitalsCell>
    </Box>
  );
}

function VitalsAddColumn(props: VitalsAddColumnProps) {
  const { index } = props;

  const {
    setRecord,
    record,
    isCreateLoading: isLoading,
    onSave,
  } = useVitalsContext();

  return (
    <Box
      display="grid"
      gridTemplateColumns="240px"
      gridTemplateRows="repeat(12, 45px)"
      gridGap="1px"
    >
      <EdgeVitalsCell cellPosition="top">
        {`${format(new Date(record.takenAt), "HH:mm")} (${index})`}
      </EdgeVitalsCell>

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, acuity: Number(x) }))}
        value={getEditCellDisplay("acuity", record.acuity)}
        title={
          record.acuity
            ? getEditCellDisplay("acuity", record.acuity)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Acuity"
        onValidate={onValidateAcuityLevel}
        inputProps={{ type: "number", min: 1, max: 5, width: "3rem" }}
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, painLevel: Number(x) }))}
        value={getEditCellDisplay("painLevel", record.painLevel)}
        title={
          record.painLevel
            ? getEditCellDisplay("painLevel", record.painLevel)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pain"
        inputProps={{ type: "number", min: 0, max: 10, width: "3rem" }}
        onValidate={onValidatePainLevel}
      />

      <SlashVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, bp: x }))}
        value={getEditCellDisplay("bp", record.bp)}
        title={record.bp ? getEditCellDisplay("bp", record.bp) : undefined}
        disabledBtn={isLoading}
        modalTitle="BP"
        inputProps={{ width: "4rem" }}
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, pulse: Number(x) }))}
        value={getEditCellDisplay("pulse", record.pulse)}
        title={
          record.pulse ? getEditCellDisplay("pulse", record.pulse) : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pulse"
        suffix="bpm"
        inputProps={{ width: "4rem" }}
        columnGap="0.5rem"
        name="pulse"
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, rr: Number(x) }))}
        value={getEditCellDisplay("rr", record.rr)}
        title={record.rr ? getEditCellDisplay("rr", record.rr) : undefined}
        disabledBtn={isLoading}
        modalTitle="RR"
        suffix="brpm"
        inputProps={{ width: "4rem" }}
        columnGap="0.5rem"
        name="rr"
      />

      <TemperatureVitalsEditCell
        onSave={(value) =>
          setRecord((prev) => ({
            ...prev,
            temp: value,
          }))
        }
        value={getEditCellDisplay("temp", record.temp)}
        title={
          record.temp ? getEditCellDisplay("temp", record.temp) : undefined
        }
        disabledBtn={isLoading}
      />

      <SlashVitalsEditCell
        onSave={(val) => setRecord((prev) => ({ ...prev, spO2: val }))}
        value={getEditCellDisplay("spO2", record.spO2)}
        title={
          record.spO2 ? getEditCellDisplay("spO2", record.spO2) : undefined
        }
        disabledBtn={isLoading}
        leftSuffix="%"
        rightSuffix="L"
        inputProps={{ width: "4rem" }}
        modalTitle="SpO2"
      />

      <SpO2TypeVitalsEditCell
        onSave={(val) => setRecord((prev) => ({ ...prev, spO2Type: val }))}
        value={record.spO2Type}
        title={
          record.spO2Type
            ? getEditCellDisplay("spO2Type", record.spO2Type)
            : undefined
        }
        modalTitle="SpO2 Type"
        disabledBtn={isLoading}
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, gcs: Number(x) }))}
        value={getEditCellDisplay("gcs", record.gcs)}
        title={record.gcs ? getEditCellDisplay("gcs", record.gcs) : undefined}
        disabledBtn={isLoading}
        modalTitle="GCS"
        inputProps={{ width: "3rem" }}
        name="gcs"
      />

      <SlashVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, pupils: x }))}
        value={getEditCellDisplay("pupils", record.pupils)}
        title={
          record.pupils
            ? getEditCellDisplay("pupils", record.pupils)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Pupils"
        leftSuffix="mm"
        rightSuffix="mm"
        inputProps={{ width: "3rem" }}
      />

      <BaseVitalsEditCell
        onSave={(x) => setRecord((prev) => ({ ...prev, fetalHt: x }))}
        value={getEditCellDisplay("fetalHt", record.fetalHt)}
        title={
          record.fetalHt
            ? getEditCellDisplay("fetalHt", record.fetalHt)
            : undefined
        }
        disabledBtn={isLoading}
        modalTitle="Fetal HT"
        suffix="brpm"
        columnGap="0.5rem"
        inputProps={{ width: "4rem" }}
        name="fetalHt"
      />

      <EdgeVitalsCell cellPosition="bottom">
        <Button
          colorScheme="green"
          height="2rem"
          px="1.25rem"
          minW="100px"
          onClick={onSave}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Done
        </Button>
      </EdgeVitalsCell>
    </Box>
  );
}

function genDummyColumns(n: number) {
  return Array(n)
    .fill(0)
    .map((_, index) => (
      <Box
        key={index}
        display="grid"
        gridTemplateColumns="240px"
        gridTemplateRows="repeat(13, 45px)"
        gridGap="1px"
      >
        <EdgeVitalsCell paddingTop="12px" cellPosition="top" />
        {VITALS_COLUMN_VALUE_KEYS.map((key) => (
          <VitalsCell key={key} />
        ))}
        <EdgeVitalsCell cellPosition="bottom" />
      </Box>
    ));
}

const VitalsInfiniteGrid = React.forwardRef<
  HTMLDivElement,
  VitalsInfiniteGridProps
>((props, ref) => {
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const { ...boxProps } = props;
  const {
    isEditMode,
    isAddMode,
    vitalsData,
    handleSave,
    isCreateLoading,
    isUpdateLoading,
  } = useVitalsContext();
  const [dummyColumns, setDummyColumns] = React.useState(0);
  const gridRef = React.useRef<HTMLDivElement>(null);
  const mergedRefs = useMergeRefs(ref, gridRef);

  const records = vitalsData?.vitalChecks;
  const recordsLength = records?.length || 0;

  React.useEffect(() => {
    const f = () => {
      if (gridRef.current) {
        const containerWidth = gridRef.current.clientWidth;
        const dc = Math.ceil(containerWidth / 240) - recordsLength;
        setDummyColumns(dc < 0 ? 0 : dc);
      }
    };
    f();

    window.addEventListener("resize", f);

    return () => {
      window.removeEventListener("resize", f);
    };
  }, [recordsLength]);

  return (
    <Box ref={mergedRefs} overflow="overlay" borderRadius="10px" {...boxProps}>
      <Box
        display="grid"
        gridTemplateColumns={
          isAddMode
            ? `repeat(${recordsLength + 1 + dummyColumns}, 240px)`
            : `repeat(${recordsLength + dummyColumns}, 240px)`
        }
        gridGap="1px"
        bg="#D3D3D6"
        border="1px solid #D1D1D6"
        borderRadius="10px"
        width="max-content"
      >
        <FormPrompt
          mainText="You have unsaved changes in this section!"
          onSave={handleSave}
          title="Warning"
          when={isAddMode || isEditMode}
          isSaveLoading={isEditMode ? isUpdateLoading : isCreateLoading}
          secondaryText=""
        />
        {isAddMode && <VitalsAddColumn index={recordsLength + 1} />}
        {records?.map((record, index) =>
          isEditMode ? (
            <VitalsEditColumn
              key={index}
              index={recordsLength - index}
              record={record}
              encounterId={encounterId}
            />
          ) : (
            <Box
              key={index}
              display="grid"
              gridTemplateColumns="240px"
              gridTemplateRows="repeat(13, 45px)"
              gridGap="1px"
            >
              <EdgeVitalsCell cellPosition="top">
                {`${format(new Date(record.takenAt), "HH:mm")} (${
                  recordsLength - index
                })`}
              </EdgeVitalsCell>
              {VITALS_COLUMN_VALUE_KEYS.map((x, i) => (
                <VitalsCell
                  key={i}
                  color={
                    record[x] !== undefined &&
                    record[x] !== null &&
                    record[x] !== ""
                      ? "gray.650"
                      : "gray.450"
                  }
                  title={record[x] ? getCellDisplay(x, record[x]) : undefined}
                >
                  {getCellDisplay(x, record[x])}
                </VitalsCell>
              ))}
              <EdgeVitalsCell cellPosition="bottom">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  flex={1}
                  paddingLeft="4px"
                >
                  <UserAvatar
                    size="xs"
                    fontSize="1rem"
                    picture={
                      record.takenBy && record.takenBy.pictureUrl
                        ? record.takenBy.pictureUrl
                        : ""
                    }
                    title={record.takenBy ? `${record.takenBy.fullName}` : ""}
                  />
                  <Box display="flex" flexDirection="column" marginLeft="10px">
                    <Text fontSize="15px">
                      {record.takenBy ? `${record.takenBy.fullName}` : ""}
                    </Text>
                    <Text fontSize="12px">
                      {record.takenBy ? record.takenBy.teams : ""}
                    </Text>
                  </Box>
                </Box>
              </EdgeVitalsCell>
            </Box>
          )
        )}
        {genDummyColumns(dummyColumns)}
      </Box>
    </Box>
  );
});

VitalsInfiniteGrid.displayName = "VitalsInfiniteGrid";

export { VitalsInfiniteGrid };
