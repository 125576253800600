import { Box, chakra, Flex, Icon, useDisclosure } from "@chakra-ui/react";
import { SystemRequirements } from "icons";
import { Button } from "shared";
import { SystemRequirementsModal } from "./SytemRequirementsModal";

function SystemRequirementsCard() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button
        borderRadius="1.375rem"
        transition="all 200ms ease-in-out"
        outline="none"
        _focus={{
          boxShadow: "var(--chakra-shadows-outline)",
        }}
        _hover={{
          cursor: "pointer",
          textDecoration: "none",
        }}
        variant="card"
        bgColor="gray.350"
        width="225px"
        height="66px"
        onClick={onOpen}
      >
        <Flex
          borderRadius="50%"
          alignItems="center"
          justifyContent="space-around"
          columnGap="0.75rem"
        >
          <Icon
            as={SystemRequirements}
            color="gray.650"
            w="32px"
            h="32px"
            position="relative"
          />
          <Box textAlign="left">
            <chakra.span fontSize="1.125rem" fontWeight="500" color="gray.650">
              System <br /> Requirements
            </chakra.span>
          </Box>
        </Flex>
      </Button>
      <SystemRequirementsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export { SystemRequirementsCard };
