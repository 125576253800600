import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type ChartSectionIndicatorDto = {
  hasDataSaved: boolean;
};

type ChartSidebarIndicatorsProps = {
  encounterId: string;
  chartCode: string;
  sectionCode: string;
  enabled: boolean;
};

function useChartSidebarSectionIndicator({
  encounterId,
  chartCode,
  sectionCode,
  enabled,
}: ChartSidebarIndicatorsProps) {
  return useAxiosQuery(
    ["chartSectionIndicator", { encounterId, chartCode, sectionCode }],
    () =>
      axiosChartInstance.get<ChartSectionIndicatorDto>(
        `v1/chart-application/${encounterId}/${chartCode}/${sectionCode}/indicator`
      ),
    { enabled }
  );
}

export { useChartSidebarSectionIndicator };
export type { ChartSectionIndicatorDto };
