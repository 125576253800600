import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { usePermissions } from "contexts";
import { useToast } from "hooks";
import { formMessages, genericErrors } from "messages";
import { useUpdateApptNotes } from "modules/in-process/api/mutations";
import { useEncounterApptNotes } from "modules/in-process/api/queries";
import { EncounterListItem } from "modules/in-process/types";
import { EncounterAppointmentNotesForm } from "modules/reception/types";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Loading, PatientHeaderModal, Textarea, ToolbarHeader } from "shared";

type PatientNotesModalProps = {
  isReadOnly?: boolean;
  patient?: Pick<EncounterListItem, "encounterId" | "fullName">;
  isOpen: boolean;
  onClose(): void;
};

type PatientNotesModalContentProps = {
  isReadOnly?: boolean;
  patient: Pick<EncounterListItem, "encounterId" | "fullName">;
  onModalClose: () => void;
};

function PatientNotesModalContent(props: PatientNotesModalContentProps) {
  const {
    patient,
    patient: { encounterId },
    onModalClose,
    isReadOnly: isReadOnlyProp,
  } = props;
  const toast = useToast();

  const { data, isLoading } = useEncounterApptNotes({ encounterId });

  const { scope } = usePermissions();
  const isReadOnly = !scope("inprocess").isEditable;

  const { mutateAsync: updateApptNotes, isLoading: isUpdateLoading } =
    useUpdateApptNotes(encounterId);

  const { register, setValue, handleSubmit } =
    useForm<EncounterAppointmentNotesForm>({
      defaultValues: {
        appointmentNotes: "",
      },
    });

  const onSubmit: SubmitHandler<EncounterAppointmentNotesForm> = async (
    dataForm
  ) => {
    try {
      await updateApptNotes(dataForm);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Appointment Notes"),
      });
      onModalClose();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: e.message || genericErrors.unknownError,
        });
      }
    }
  };

  React.useEffect(() => {
    if (data?.data.appointmentNotes) {
      setValue("appointmentNotes", data?.data?.appointmentNotes);
    }
  }, [data, setValue]);

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText="Appointment Notes"
          leftButtons={[
            <Button key="cancelBtn" onClick={onModalClose}>
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button
              disabled={isReadOnly || isReadOnlyProp}
              key="doneBtn"
              onClick={handleSubmit(onSubmit)}
              isLoading={isUpdateLoading}
            >
              Done
            </Button>,
          ]}
        />
        <PatientHeaderModal
          fullName={patient.fullName || ""}
          encounterId={encounterId}
        />
      </ModalHeader>
      <ModalBody padding="16px">
        <form>
          {isLoading ? (
            <Loading py="48px" />
          ) : (
            <Textarea
              bg="white"
              isDisabled={isReadOnly || isReadOnlyProp}
              {...register("appointmentNotes")}
            />
          )}
        </form>
      </ModalBody>
    </>
  );
}

function PatientNotesModal(props: PatientNotesModalProps) {
  const { patient, isOpen, onClose, isReadOnly } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="gray.100" maxWidth="542px">
        {patient && isOpen && (
          <PatientNotesModalContent
            patient={patient}
            onModalClose={onClose}
            isReadOnly={isReadOnly}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { PatientNotesModal };
