import { createIcon } from "@chakra-ui/react";

const BeginCallback = createIcon({
  path: (
    <path
      d="M0.000415506 9C-0.0224656 5.78902 0.900405 2.99753 2.58598 1.31195C3.48597 0.404337 4.46986 -0.00752308 5.74357 0.000103948C7.31474 0.00773098 8.26049 0.861958 8.00117 2.44075L7.50541 5.28564C7.35287 6.17037 6.78085 6.78816 5.8656 6.78816H5.14866C4.70629 6.78816 4.51562 6.99409 4.46223 7.54324C4.43172 7.81018 4.42409 8.35933 4.42409 9C4.42409 9.64067 4.43172 10.1898 4.46223 10.4568C4.51562 11.0059 4.70629 11.2118 5.14866 11.2118H5.8656C6.78085 11.2118 7.35287 11.8296 7.50541 12.7144L8.00117 15.5592C8.26049 17.1533 7.29948 17.9923 5.74357 17.9999C4.46986 18.0075 3.48597 17.5957 2.58598 16.688C0.900405 15.0025 -0.0224656 12.211 0.000415506 9ZM1.42667 9C1.41142 11.9745 2.21225 14.4152 3.60037 15.7804C4.13427 16.3067 4.80544 16.6041 5.53764 16.6041C6.33085 16.6041 6.7961 16.2991 6.64356 15.5592L6.17831 13.1949C6.11729 12.8593 5.9495 12.6457 5.56052 12.6457H4.81307C3.90546 12.6457 3.46309 12.1195 3.28004 11.311C3.15801 10.7466 3.11224 9.91524 3.11224 9C3.11224 8.08476 3.15801 7.25341 3.28004 6.68901C3.46309 5.88054 3.90546 5.35428 4.81307 5.35428H5.56052C5.9495 5.35428 6.11729 5.14072 6.17831 4.80513L6.64356 2.44075C6.80373 1.6933 6.32322 1.39585 5.53764 1.39585C4.80544 1.39585 4.13427 1.6933 3.60037 2.2272C2.21225 3.58481 1.41142 6.02546 1.42667 9ZM7.70372 9.71694C7.2766 9.71694 6.98678 9.38135 6.98678 9C6.98678 8.61102 7.2766 8.28306 7.70372 8.28306H11.5706L12.9359 8.35933L11.563 7.15426L10.8079 6.39918C10.6783 6.2619 10.602 6.07885 10.602 5.88054C10.602 5.48394 10.8918 5.20937 11.2808 5.20937C11.4791 5.20937 11.6545 5.27801 11.7842 5.40767L14.774 8.38984C14.9647 8.58051 15.0638 8.79407 15.0638 9C15.0638 9.20593 14.9647 9.41949 14.774 9.61016L11.7842 12.5923C11.6545 12.722 11.4791 12.7906 11.2808 12.7906C10.8918 12.7906 10.602 12.5084 10.602 12.1195C10.602 11.9212 10.6783 11.7305 10.8079 11.6008L11.563 10.8457L12.9359 9.64067L11.5706 9.71694H7.70372Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 16 18",
});

export { BeginCallback };
