import {
  Badge,
  BadgeProps,
  BoxProps,
  chakra,
  HStack,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { ContrastTriangle } from "icons";
import { OrdersListItemResponse } from "modules/orders/api";
import { ORDER_CONTRAST_STATUS_COLOR } from "modules/radiology/constants";
import React from "react";
import { useCountdown } from "hooks";
import { EncounterAvatar, Tooltip } from "shared";
import { msToTimer } from "utils";
import { addSeconds } from "date-fns";
import { OrderTypeIconElement, ORDER_STATUS_COLOR } from "../constants";

type OrdersListItemProps = {
  colorIcon?: string;
  order: OrdersListItemResponse;
  showObservation?: boolean;
  orderGroup?: string;
} & Omit<BoxProps, "order">;

type OrderContrastCountdownProps = {
  secondsLeft: number;
};

const BADGE_PROPS: BadgeProps = {
  height: "1.25rem",
  fontSize: "0.8125rem",
  color: "white",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.125rem 0.5rem",
};

function OrderContrastCountdown(props: OrderContrastCountdownProps) {
  const { secondsLeft } = props;
  const dateCountdown = React.useMemo(() => {
    return addSeconds(new Date(), secondsLeft || 0);
  }, [secondsLeft]);
  const countdown = useCountdown(dateCountdown);

  const timer = React.useMemo(() => msToTimer(countdown), [countdown]);

  return <>{timer}</>;
}

function OrderCard(props: OrdersListItemProps) {
  const {
    colorIcon = "orange",
    orderGroup,
    order,
    showObservation,
    ...rest
  } = props;
  const { encounterId } = order;
  const showContrast = orderGroup === "Radiology" && order?.contrast;

  return (
    <HStack
      border="none"
      height="80px"
      padding="0px 15px"
      bg="white"
      borderRadius="10px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
      fontWeight="600"
      alignSelf="center"
      justifyContent="space-evenly"
      spacing="25px"
      {...rest}
    >
      <EncounterAvatar
        encounterId={encounterId}
        showObservation={showObservation && order.isObservation}
      />
      <VStack
        flex={1}
        alignItems="start"
        alignSelf="flex-start"
        spacing="0"
        paddingTop="7px"
      >
        <HStack flex={1} alignSelf="flex-start">
          <chakra.span mb="2px" color="black" fontSize="1.125rem">
            {order?.patientFullName}
          </chakra.span>
          <chakra.span
            color="gray.600"
            fontSize="1.0625rem"
            lineHeight="1rem"
            fontWeight="600"
          >
            {`${order?.age} ${order?.gender}`}
          </chakra.span>
          {order.bedCode && (
            <Tooltip label="Room #">
              <Badge
                {...BADGE_PROPS}
                color="gray.600"
                borderWidth="1px"
                borderColor="gray.600"
                bg="white"
                borderRadius="0px"
                minWidth="44px"
              >
                {order.bedCode}
              </Badge>
            </Tooltip>
          )}
        </HStack>
        <chakra.span fontSize="0.875rem" color="blue" maxW="360px">
          {order?.description}
        </chakra.span>
        <chakra.span fontSize="0.875rem" color="gray.650" maxW="360px">
          {order?.orderId}
        </chakra.span>
      </VStack>
      {showContrast && (
        <VStack alignItems="center" w="40px">
          <Icon
            as={ContrastTriangle}
            color={
              ORDER_CONTRAST_STATUS_COLOR[order.contrast?.status || "InProcess"]
            }
            w="1.7rem"
            h="1.7rem"
          />
          <chakra.span fontSize="0.9375rem" color="black">
            {order.contrast?.isStarted ? (
              <OrderContrastCountdown
                secondsLeft={order?.contrast?.secondsLeft || 0}
              />
            ) : (
              <>{msToTimer((order?.contrast?.secondsLeft || 0) * 1000)}</>
            )}
          </chakra.span>
        </VStack>
      )}
      <VStack alignItems="center" w="40px">
        {OrderTypeIconElement(
          orderGroup === "Radiology"
            ? ORDER_STATUS_COLOR[order.status]
            : colorIcon,
          order?.elementCategory
        )}
        <chakra.span fontSize="0.9375rem" color="black">
          {order?.timer}
        </chakra.span>
      </VStack>
    </HStack>
  );
}

export { OrderCard };
