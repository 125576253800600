import { Box, BoxProps, HStack, Icon, Text } from "@chakra-ui/react";
import { DownArrowFilled, UpArrowFilled } from "icons";
import * as React from "react";

type OrderHeaderCardProps = {
  counter: number;
  title: string;
  icon: React.ReactNode;
  sortDesc?: boolean;
  showSortDesc?: boolean;
  onSortChange?: (value: boolean) => void;
} & Omit<BoxProps, "children">;

type DashboardOrderHeaderCardProps = Omit<
  OrderHeaderCardProps,
  "title" | "icon"
>;

function OrderHeaderCard(props: OrderHeaderCardProps) {
  const {
    counter,
    icon,
    title,
    color,
    sortDesc,
    showSortDesc,
    onSortChange,
    ...restProps
  } = props;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="18px 20px 18px 28px"
      height="65px"
      width="100%"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
      borderRadius="10px"
      {...restProps}
    >
      <HStack spacing="23px">
        {React.isValidElement(icon) &&
          React.cloneElement(icon, {
            fontSize: "1.5rem",
            color: color ?? "white",
          })}
        <Box fontSize="1.25rem" fontWeight="600" color={color ?? "white"}>
          {title}
        </Box>

        {showSortDesc && (
          <Box
            border="2px solid white"
            borderRadius="15px"
            padding="2px 12px"
            display="flex"
            alignItems="center"
            color="white"
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation();
              onSortChange?.(!sortDesc);
            }}
          >
            <Icon
              as={sortDesc ? DownArrowFilled : UpArrowFilled}
              fontSize="0.725rem"
              color="white"
              marginLeft="7px"
              marginRight="14px"
              marginTop={sortDesc ? "1px" : "0"}
            />
            <Text fontSize="1rem" fontWeight="500">
              {sortDesc ? "NEWEST" : "OLDEST"}
            </Text>
          </Box>
        )}
      </HStack>
      <Box
        fontSize="1.875rem"
        fontWeight="600"
        color={color ?? "white"}
        lineHeight="1.375rem"
      >
        {counter}
      </Box>
    </Box>
  );
}

export type { OrderHeaderCardProps, DashboardOrderHeaderCardProps };
export { OrderHeaderCard };
