import {
  chakra,
  Box,
  Flex,
  Icon,
  Input,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { EmrBed, EmrRemove } from "@medstonetech/slate-icons";
import { OrderElementResponse } from "modules/order-list/types";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, CalendarInput, Label } from "shared";

const fieldLabelStyle = {
  mt: "10px",
  fontSize: "11pt",
};

const repetitionHoursOptions = [2, 4, 6, 8, 10, 12, 24];
const repetitionsOptions = [2, 3, 4, 5, 6, 7];

type SchedulerProps = {
  repetitions: number;
  repetitionHours: number;
  repetitionMinutes: number;
  scheduledTime: Date | null;
  isScheduled: boolean;
  isPRN: boolean;
  prnReason: string;
};

const initialSchedulerProps: SchedulerProps = {
  prnReason: "",
  repetitions: 0,
  repetitionHours: 0,
  repetitionMinutes: 0,
  scheduledTime: null,
  isScheduled: false,
  isPRN: false,
};

type AddOrderItemSchedulerModalBodyProps = {
  item: OrderElementResponse;
  scheduleProps: SchedulerProps;
  setShowScheduler: (value: boolean) => void;
  setSchedulerProps: (value: SchedulerProps) => void;
};

const schedulerSchema = yup.object().shape({
  scheduledTime: yup.date().nullable().required("Start time is required"),
  notRepeat: yup.boolean(),
  repetitions: yup.number().when("notRepeat", {
    is: false,
    then: yup.number().required("Number of repetitions is required"),
    otherwise: yup.number().nullable(),
  }),
  repetitionHours: yup.number().when("notRepeat", {
    is: false,
    then: yup.number().required("Repetition hours is required"),
    otherwise: yup.number().nullable(),
  }),
  repetitionMinutes: yup.number().when("notRepeat", {
    is: false,
    then: yup.number().min(0).max(59),
    otherwise: yup.number().nullable(),
  }),
  isPRN: yup.boolean(),
  prnReason: yup.string().when("isPRN", {
    is: true,
    then: yup.string().required("PRN reason is required"),
    otherwise: yup.string(),
  }),
  repetitionsCustom: yup.string(),
  repetitionHoursCustom: yup.string(),
  repetitionMinutesCustom: yup.string(),
});

type FormValues = {
  scheduledTime: Date | null;
  notRepeat: boolean;
  repetitions: number;
  repetitionHours: number;
  repetitionMinutes: number;
  isPRN: boolean;
  prnReason: string;
  repetitionsCustom: string;
  repetitionHoursCustom: string;
  repetitionMinutesCustom: string;
};

function AddOrderItemSchedulerModalBody(
  props: AddOrderItemSchedulerModalBodyProps
) {
  const { item, scheduleProps, setShowScheduler, setSchedulerProps } = props;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schedulerSchema),
    defaultValues: {
      scheduledTime: scheduleProps.scheduledTime,
      notRepeat: false,
      repetitions: scheduleProps.repetitions,
      repetitionHours: scheduleProps.repetitionHours,
      repetitionMinutes: scheduleProps.repetitionMinutes,
      isPRN: scheduleProps.isPRN,
      prnReason: scheduleProps.prnReason,
      repetitionsCustom: "",
      repetitionHoursCustom: "",
      repetitionMinutesCustom: "",
    },
  });

  const notRepeat = watch("notRepeat");
  const isPRN = watch("isPRN");
  const repetitions = watch("repetitions");
  const repetitionHours = watch("repetitionHours");

  const cleanFields = () => {
    reset({
      scheduledTime: null,
      notRepeat: false,
      repetitions: 0,
      repetitionHours: 0,
      repetitionMinutes: 0,
      isPRN: false,
      prnReason: "",
      repetitionsCustom: "",
      repetitionHoursCustom: "",
      repetitionMinutesCustom: "",
    });
  };

  const onSubmit = (data: FormValues) => {
    setShowScheduler(false);
    setSchedulerProps({
      repetitions: notRepeat
        ? 1
        : data.repetitions || Number(data.repetitionsCustom),
      repetitionHours: notRepeat
        ? 0
        : data.repetitionHours || Number(data.repetitionHoursCustom),
      repetitionMinutes: notRepeat ? 0 : Number(data.repetitionMinutesCustom),
      scheduledTime: data.scheduledTime,
      isScheduled: true,
      isPRN: data.isPRN,
      prnReason: data.prnReason,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody
        display="flex"
        flexDirection="column"
        px="20px"
        py="20px"
        maxW="inherit"
      >
        <Box flex={1}>
          <Flex columnGap="1rem">
            <Box flex={1}>
              <Label {...fieldLabelStyle}>Start Time *</Label>
              <Controller
                name="scheduledTime"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <CalendarInput
                    {...field}
                    selected={value}
                    onChange={(date: Date | null) => onChange(date)}
                    dateFormat={"LL/dd/yyyy HH:mm"}
                    showIcon={false}
                    showTimeInput={true}
                    shouldCloseOnSelect={false}
                    onInputClick={() => {
                      if (!value) {
                        onChange(new Date());
                      }
                    }}
                  />
                )}
              />
            </Box>

            <Flex alignItems="flex-end" flex={1}>
              <Controller
                name="notRepeat"
                control={control}
                render={({ field }) => (
                  <Button
                    sx={{
                      gap: "16px",
                      width: "100%",
                      borderRadius: "8px",
                      maxH: "2.5rem",
                    }}
                    variant="outlineSquared"
                    colorScheme={notRepeat ? "blue" : "gray"}
                    isActive={field.value}
                    onClick={() => {
                      const currentScheduledTime = watch("scheduledTime");
                      if (!field.value) {
                        reset({
                          scheduledTime: currentScheduledTime || new Date(),
                          notRepeat: true,
                          repetitions: 0,
                          repetitionHours: 0,
                          repetitionMinutes: 0,
                          isPRN: false,
                          prnReason: "",
                          repetitionsCustom: "",
                          repetitionHoursCustom: "",
                          repetitionMinutesCustom: "",
                        });
                      } else {
                        reset({
                          scheduledTime: currentScheduledTime,
                          notRepeat: false,
                          repetitions: 0,
                          repetitionHours: 0,
                          repetitionMinutes: 0,
                          isPRN: false,
                          prnReason: "",
                          repetitionsCustom: "",
                          repetitionHoursCustom: "",
                          repetitionMinutesCustom: "",
                        });
                      }
                    }}
                  >
                    Do Not Repeat
                  </Button>
                )}
              />
            </Flex>
          </Flex>

          <chakra.fieldset
            disabled={notRepeat}
            transition="opacity 0.3s ease-in-out"
            _disabled={{
              opacity: 0.5,
              pointerEvents: "none",
            }}
          >
            <Flex alignItems="flex-end" columnGap="0.5rem" mt="20px">
              <Box flex={1}>
                <Label {...fieldLabelStyle}>Repeat Every (hours)</Label>
                <Flex
                  sx={{
                    gap: "8px",
                    marginTop: "4px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                  wrap="wrap"
                >
                  {repetitionHoursOptions.map((value) => (
                    <Box
                      key={value}
                      as="button"
                      type="button"
                      width="35px"
                      height="35px"
                      borderRadius="50%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      fontWeight="500"
                      color={repetitionHours === value ? "#0000ff" : "black"}
                      border={
                        repetitionHours === value
                          ? "2px solid blue"
                          : "1px solid gray"
                      }
                      onClick={() => {
                        setValue("repetitionHours", value);
                        setValue("repetitionHoursCustom", "");
                        setValue("repetitionMinutesCustom", "");
                      }}
                      _hover={{ bgColor: "blue.100", cursor: "pointer" }}
                    >
                      {value}
                    </Box>
                  ))}
                </Flex>
              </Box>

              <chakra.span
                paddingBottom="0.5rem"
                marginRight="0.5rem"
                fontWeight="semibold"
                color="gray.750"
              >
                Other
              </chakra.span>

              <Box>
                <Label {...fieldLabelStyle}>Hrs</Label>
                <Controller
                  name="repetitionHoursCustom"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="number"
                      {...field}
                      onChange={(e) => {
                        setValue("repetitionHours", 0);
                        field.onChange(e.target.value);
                      }}
                      sx={{
                        width: "70px",
                        height: "35px",
                        borderRadius: "8px",
                      }}
                    />
                  )}
                />
              </Box>

              <chakra.span paddingBottom="0.5rem">:</chakra.span>

              <Box>
                <Label {...fieldLabelStyle}>Min</Label>
                <Controller
                  name="repetitionMinutesCustom"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="number"
                      {...field}
                      min={0}
                      max={59}
                      sx={{
                        width: "70px",
                        height: "35px",
                        borderRadius: "8px",
                      }}
                    />
                  )}
                />
              </Box>
            </Flex>

            <Flex
              marginTop="20px"
              alignItems="flex-end"
              columnGap="0.5rem"
              mt="20px"
            >
              <Box flex={1}>
                <Label {...fieldLabelStyle}>Max # Of Times</Label>
                <Flex
                  wrap="wrap"
                  sx={{
                    gap: "8px",
                    marginTop: "4px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  {repetitionsOptions.map((value) => (
                    <Box
                      key={value}
                      as="button"
                      type="button"
                      width="35px"
                      height="35px"
                      borderRadius="50%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      fontWeight="500"
                      color={repetitions === value ? "#0000ff" : "black"}
                      border={
                        repetitions === value
                          ? "2px solid blue"
                          : "1px solid gray"
                      }
                      onClick={() => {
                        setValue("repetitions", value);
                        setValue("repetitionsCustom", "");
                      }}
                      _hover={{ bgColor: "blue.100", cursor: "pointer" }}
                    >
                      {value}
                    </Box>
                  ))}
                </Flex>
              </Box>

              <chakra.span
                paddingBottom="0.5rem"
                marginRight="0.5rem"
                fontWeight="semibold"
                color="gray.750"
              >
                Other
              </chakra.span>

              <Controller
                name="repetitionsCustom"
                control={control}
                render={({ field }) => (
                  <Input
                    type="number"
                    {...field}
                    onChange={(e) => {
                      setValue("repetitions", 0);
                      field.onChange(e.target.value);
                    }}
                    sx={{
                      width: "70px",
                      height: "35px",
                      borderRadius: "8px",
                    }}
                  />
                )}
              />

              <Box minW="85px" />
            </Flex>
          </chakra.fieldset>

          {item.orderFormElementType === "Medication" && (
            <Box
              sx={{
                paddingLeft: "10px",
                marginTop: "40px",
                marginBottom: "15px",
              }}
            >
              <Controller
                name="isPRN"
                control={control}
                render={({ field }) => (
                  <Button
                    sx={{
                      gap: "16px",
                      width: "50%",
                      borderRadius: "8px",
                    }}
                    variant="outlineSquared"
                    colorScheme={field.value ? "blue" : "gray"}
                    onClick={() => field.onChange(!field.value)}
                  >
                    <Icon as={EmrBed} w="24px" h="24px" /> PRN
                  </Button>
                )}
              />

              {isPRN && (
                <Box marginTop="20px">
                  <Label {...fieldLabelStyle}>PRN Reason</Label>
                  <Controller
                    name="prnReason"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        sx={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
          )}

          <Button
            margin="2rem 0.5rem"
            alignSelf="flex-start"
            color="red"
            variant="label"
            gap="10px"
            onClick={() => {
              cleanFields();
              setShowScheduler(false);
              setSchedulerProps(initialSchedulerProps);
            }}
          >
            <Icon as={EmrRemove} fontSize="1.525rem" />
            <chakra.span fontSize="15px" fontWeight="600" color="red">
              Remove Schedule
            </chakra.span>
          </Button>
        </Box>
      </ModalBody>

      <ModalFooter
        justifyContent="space-around"
        bgColor="white"
        borderBottomRadius="md"
        px={2}
      >
        <Button
          variant="outlineSquared"
          colorScheme="gray"
          onClick={() => {
            cleanFields();
            setShowScheduler(false);
          }}
          width="240px"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant="outlineSquared"
          bgColor="blue"
          color="white"
          width="240px"
          disabled={!isValid}
        >
          Add Schedule
        </Button>
      </ModalFooter>
    </form>
  );
}

export type { SchedulerProps };
export { AddOrderItemSchedulerModalBody, initialSchedulerProps };
