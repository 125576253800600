import { Box, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { format, isValid } from "date-fns";

import { Card } from "shared";
import {
  EmrBed,
  EmrClose,
  EmrHeartBolt,
  EmrLabs,
  EmrRadiology,
} from "@medstonetech/slate-icons";
import { ORDER_STATUS_COLOR } from "modules/orders";
import { OrderItem } from "modules/charts-shared/types";
import { OrderStatus } from "enums";
import { toDisplay } from "utils/enum-mapper";
import { useMemo } from "react";
import { MedicalCross, PillsBottle } from "icons";

type OrderFormCartItemProps = {
  item: OrderItem;
  onDelete?: () => void;
  isFromHistory?: boolean;
  orderStatus?: OrderStatus;
};

// "Lab" | "Radiology" | "Medication" | "Treatment";
const ORDER_ELEMENT_TYPE_ICONS = {
  Lab: EmrLabs,
  Radiology: EmrRadiology,
  Medication: PillsBottle,
  Treatment: MedicalCross,
  EKG: EmrHeartBolt,
};

function OrderFormCartItem({
  item: {
    orderId,
    orderElementType,
    description,
    notes,
    date,
    location,
    orderElementCategory,
    administrationTime,
    scheduledTime,
    isObservation,
    isPRN,
    prnReason,
  },
  onDelete,
  orderStatus,
  isFromHistory = false,
}: OrderFormCartItemProps) {
  const borderColor = orderStatus
    ? ORDER_STATUS_COLOR[orderStatus]
    : "gray.700";

  const displayDate = useMemo(() => {
    if (isFromHistory) {
      return scheduledTime && isValid(new Date(scheduledTime))
        ? new Date(scheduledTime)
        : date;
    }
    return date;
  }, [date, isFromHistory, scheduledTime]);

  return (
    <Card
      w="100%"
      p="10px 20px"
      variant="basic"
      borderLeft="8px solid"
      borderColor={borderColor}
    >
      <Flex justifyContent="space-between">
        <Box flex={1}>
          <Flex>
            <Box
              flex={2}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text
                color="gray"
                sx={{
                  flex: 1.5,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Icon
                  color={"gray.450"}
                  as={ORDER_ELEMENT_TYPE_ICONS[orderElementType]}
                  fontSize="1.25rem"
                />
                <Text color={isFromHistory ? "gray" : "red"} ml="10px">
                  {isFromHistory ? orderId : "Order Not Sent"}
                </Text>
              </Text>
              <Text
                sx={{
                  flex: 0.5,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {isObservation && (
                  <Icon color={"purple"} as={EmrBed} fontSize="1.25rem" />
                )}
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              flex={2}
              columnGap="10px"
            >
              {isFromHistory && (
                <Text color="gray">
                  Ordered: {format(displayDate, "MM-dd-yyyy HH:mm")}
                </Text>
              )}
            </Box>
          </Flex>
          <Box display="flex" flexDir="column" flex={1}>
            <Text fontWeight="500">{description}</Text>
            {orderElementCategory && (
              <>
                <Text color="gray">
                  {toDisplay("orderFormElementCategory", orderElementCategory)}
                </Text>
                <Text fontWeight="500">
                  {!!location?.length ? location : ""}
                </Text>
              </>
            )}
          </Box>
          <Flex>
            {administrationTime && isValid(new Date(administrationTime))
              ? format(new Date(administrationTime), "MM/dd/yyyy HH:mm a")
              : ""}
          </Flex>
          {!isFromHistory && (
            <Flex>
              <Text color="gray">
                {scheduledTime && isValid(new Date(scheduledTime))
                  ? `Scheduled: ${format(
                      new Date(scheduledTime),
                      "MM/dd/yyyy HH:mm a"
                    )}`
                  : ""}
              </Text>
            </Flex>
          )}
          <Flex>
            <Text color="gray">{isPRN && `PRN: ${prnReason}`}</Text>
          </Flex>
          <Flex>
            <Text fontWeight="500">{notes}</Text>
          </Flex>
        </Box>
        {onDelete && (
          <Flex alignItems="flex-start" justifyContent="center">
            <IconButton
              aria-label="Delete Item"
              variant="icon"
              color="red"
              onClick={onDelete}
            >
              <Icon as={EmrClose} />
            </IconButton>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}

export { OrderFormCartItem };
