import * as React from "react";
import { createIcon } from "@chakra-ui/react";

const ClinicalSupport = createIcon({
  path: (
    <path
      d="M10 20C4.48706 20 0 15.5225 0 10C0 4.48706 4.48706 0 10 0C15.5225 0 20 4.48706 20 10C20 15.5225 15.5225 20 10 20ZM4.52541 10.8917C4.52541 11.5916 5.00479 12.0805 5.71429 12.0805H7.90988V14.2665C7.90988 14.9856 8.38926 15.465 9.09875 15.465H10.8917C11.6012 15.465 12.0709 14.9856 12.0709 14.2665V12.0805H14.2761C14.9856 12.0805 15.465 11.5916 15.465 10.8917V9.08917C15.465 8.38926 14.9856 7.90988 14.2761 7.90988H12.0709V5.72387C12.0709 5.00479 11.6012 4.535 10.8917 4.535H9.09875C8.38926 4.535 7.90988 5.00479 7.90988 5.72387V7.90988H5.71429C5.00479 7.90988 4.52541 8.38926 4.52541 9.08917V10.8917Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 20 20",
});

export { ClinicalSupport };
