import { axiosIdentityInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseAddMembersToTeamsPayload = {
  teamIds: string[];
  userIds: string[];
};

function useAddMembersToTeams() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["addMembersToTeams"],
    (payload: UseAddMembersToTeamsPayload) =>
      axiosIdentityInstance.post(`/v1/team/members/add-range`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("searchTeamMembers");
        queryClient.invalidateQueries("teamsList");
      },
    }
  );
}

export { useAddMembersToTeams };
